import * as React from 'react';
// ***********************************
interface IProps {
  color: string;
  height: string;
  width: string;
}
// ***********************************
export default class MenuVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.height,
      width: this.props.width,
      fill:this.props.color
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" style={style} height={this.props.height} width={this.props.width}>
        <path 
          d="M22.373 76.068h205.832c12.356 0 22.374-10.017 22.374-22.373 0-12.356-10.017-22.373-22.374-22.373H22.373C10.017 31.323 0 41.339 0 53.696c0 12.356 10.017 22.372 22.373 22.372zm205.832 26.848H22.373C10.017 102.916 0 112.933 0 125.289c0 12.357 10.017 22.373 22.373 22.373h205.832c12.356 0 22.374-10.016 22.374-22.373 0-12.356-10.018-22.373-22.374-22.373zm0 71.594H22.373C10.017 174.51 0 184.526 0 196.883c0 12.356 10.017 22.373 22.373 22.373h205.832c12.356 0 22.374-10.017 22.374-22.373 0-12.357-10.018-22.373-22.374-22.373z" 
        />
      </svg>
    );
  }
  //***********************
}