// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject,IChannel} from '../../../../../../Types/interfaces';
import {getContrast} from '../../../../../../Scripts/common';
import ChannelLinks from './ChannelLinks/channellinks';
// ***********************************
interface IProps extends IStoresInject {channel: IChannel; index: number;}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Channel extends React.Component<IProps,{}>  {
  //***********************
  @observable private showImage: boolean;
  cardWidth: number;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.showImage=true;
    this.preloadImage(this.props.channel.LogoUri);
  }
  //***********************
  preloadImage(URL: string) {
    if(!this.props.siteSettings!.showChannelImages) {return;}
    var tester=new Image();
    tester.onload=this.imageFound;
    tester.onerror=this.imageNotFound;
    tester.src=URL;
  }
  //***********************
  imageFound=(): void => {this.setShowImage(true);};
  imageNotFound=(): void => {this.setShowImage(false);};
  imageError=(): void => {this.setShowImage(false);};
  @action setShowImage(showImage: boolean): void {this.showImage=showImage;}
  //***********************
  divineChannel(): string {
    const {channel}=this.props;
    if(channel.MajorChannel>0&&channel.MinorChannel>0) {return channel.MajorChannel+'.'+channel.MinorChannel;}
    if(channel.RFChannel===-1) {return '';}
    return channel.RFChannel.toString();
  }
  //***********************
  getColorMatch(channel: string): string {
    const {siteSettings}=this.props;
    for(let i=0;i<siteSettings!.colorMatch.length;i++) {
      let data=siteSettings!.colorMatch[i];
      if(data.channel===Math.trunc(+channel)){
        return data.color;
      }
    }
    return '#FFFFFF';
  }
  //***********************
  render() {
    const {channel,proto,viewState,masterStyles,siteSettings,as}=this.props;

    let fontSize='10px';
    switch(viewState!.mediaState) {
      case 0: case 1: case 2: break;
      case 3: case 4: fontSize='12px'; break;
      case 5: case 6: case 7: fontSize='14px'; break;
      case 8: fontSize='18px'; break;
      default: break;
    }

    const baseStyle={fontSize: fontSize,display: 'flex',borderColor: proto!.colorBorder};

    let chanImgStyle={width: '90%'};

    let w1=viewState!.colWidthDate;
    const borderStyle={borderColor: proto!.colorBorder,borderStyle: 'none solid none none',borderWidth: '1px'};
    const widthStyle={minWidth: `${w1}px`,maxWidth: `${w1}px`};
    const flexStyle={...masterStyles!.flexCenter,flexDirection: as!.column};
    let detailStyle={...widthStyle,...borderStyle,...flexStyle,padding: '5px 0 5px 0'};

    let w2=viewState!.colWidthChannel;

    let channelText=this.divineChannel();
    let colorMatch=this.getColorMatch(channelText);

    const style2={
      ...borderStyle,...masterStyles!.flexCenterInline,minWidth: `${w2}px`,maxWidth: `${w2}px`,
      borderColor: proto!.colorBorder,paddingBottom: '2px',
      backgroundColor: colorMatch,
      color: getContrast(colorMatch)
    };
    const h1={...masterStyles!.h1};

    let channelImgStyle={...flexStyle,flexGrow: 5,flexShrink: 5,flexBasis: 'auto'};
    let channelStyle={fontSize: '1.2em',fontWeight: as!.bold,textAlign: as!.center};
    let networkStyle={fontSize: '.9em',textAlign: as!.center};

    let title=channel.Network+' • '+channel.CallSign;
    return (
      <div style={baseStyle}>
        <div style={detailStyle}>
          {siteSettings!.showChannelImages&&this.showImage?
            <div style={channelImgStyle}>
              <img style={chanImgStyle} src={channel.LogoUri} onError={this.imageError} title={title} alt="" />
            </div>
            :
            ''
          }
          {!siteSettings!.showChannelImages||!this.showImage?
            <div style={channelImgStyle}>
              <div style={channelStyle}>{channel.CallSign}</div>
              <div style={networkStyle}>{channel.Network}</div>
            </div>
            :
            ''
          }
          {siteSettings!.enableViews? <ChannelLinks index={this.props.index} />:siteSettings!.showChannelNumbers? <div style={h1}>{this.divineChannel()}</div>:''}
        </div>
        {siteSettings!.showChannelNumbers&&siteSettings!.enableViews?
          <div style={style2}>
            <div style={h1}>{channelText}</div>
          </div>
          :
          ''
        }
      </div>
    );
  }
  //***********************
}
// ***********************************;