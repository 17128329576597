// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';

// tslint:disable-next-line
declare global {interface Window {googletag?: any;}}
//declare var googletag: any;

// ***********************************
interface IProps extends IStoresInject {adIndex: number, pageIndex: number, height: number, width: number}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export class GPT extends React.Component<IProps,{}> {
  //***********************
  buildSlotId():string {
    const {adIndex,pageIndex,siteSettings}=this.props;
    let result: string=siteSettings!.bannerMode.key
      .concat('-')
      .concat(pageIndex.toString())
      .concat('-')
      .concat(adIndex.toString());
    return result;
  }
  //***********************
  buildComponentKey(width: number,height:number): string {
    const {adIndex,pageIndex}=this.props;
    let result: string=pageIndex.toString()
      .concat('-')
      .concat(adIndex.toString())
      .concat('-')
      .concat(width.toString())
      .concat('-')
      .concat(height.toString())
    return result;
  }
  //***********************
  componentDidMount() {
    this.initGPT();
  }
  //***********************
  componentDidUpdate() { }
  //***********************
  initGPT(): void {
    const {width,height,siteSettings}=this.props;
    const {googletag}=window;
    const slotId: string=this.buildSlotId();
    googletag.defineSlot(siteSettings!.bannerMode.slot,[width,height],slotId).addService(googletag.pubads());
    googletag.pubads().set("page_url", "titantvguide.com");
    googletag.pubads().enableSingleRequest();
    googletag.enableServices();
    googletag.cmd.push(function() { googletag.display(slotId); });
  }
  //***********************
  componentWillUnmount() {
    // Clean up the slot?
  }
  //***********************
  render() {
    const {width,height,proto,viewState,siteSettings}=this.props;
    let esb: boolean=siteSettings!.enableScrollingBody;
    const {isMobile}=viewState!;
    let style={
      padding: '20px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: proto!.colorBorder,
      borderStyle: `solid none ${esb? 'solid':'none'} none`,
      borderWidth: isMobile? '0':'1px',
    }
    return (
      <div style={style} key={this.buildComponentKey(width,height)}>
        <div id={this.buildSlotId()}  />
      </div>
    );
  }
  //***********************
}