// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../Types/interfaces';
import {swipedetect} from '../../../Scripts/swipe';
import TimeLine from '../../../Scripts/timeline';
import LeftArrowVector from '../../../Application/SVG/leftarrow';
import RightArrowVector from '../../../Application/SVG/rightarrow';
// ***********************************
interface IProps extends IStoresInject {listener: boolean;}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class DayNav extends React.Component<IProps,{}> {
  //***********************
  @observable leftHover: boolean;
  @observable rightHover: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.leftHover=false;
    this.rightHover=false;
  }
  //***********************
  componentDidMount() {
    if(this.props.listener) {
      var el=document.getElementById('defaultView');
      const {frame}=this.props;
      swipedetect(el,(swipedir: string) => {
        switch(swipedir) {
          case 'left':
            frame!.incrementDay();
            break;
          case 'right':
            frame!.decrementDay();
            break;
          default: break;
        }
      });
    }
  }
  //***********************
  @action handleLeftMouseEnter=(): void => {this.leftHover=true;};
  @action handleLeftMouseLeave=(): void => {this.leftHover=false;};
  @action handleRightMouseEnter=(): void => {this.rightHover=true;};
  @action handleRightMouseLeave=(): void => {this.rightHover=false;};
  //***********************
  handleBackClick=(e: React.FormEvent<HTMLElement>): void => {
    const {frame}=this.props;
    frame!.decrementDay();
  }
  //***********************
  handleForwardClick=(e: React.FormEvent<HTMLElement>): void => {
    const {frame}=this.props;
    frame!.incrementDay();
  }
  //***********************
  render() {
    const {frame,viewState,proto,masterStyles,as}=this.props;
    const leftStyle={
      visibility: frame!.boundDayPast? as!.hidden:as!.visible,
      paddingLeft: '2px',
      paddingTop: '2px',
      cursor: 'pointer'
    };
    const rightStyle={
      visibility: frame!.boundDayFuture? as!.hidden:as!.visible,
      paddingRight: '2px',
      paddingTop: '2px',
      cursor: 'pointer'
    };
    let date: TimeLine=frame!.windowDate;
    let dateStr: string=(date.month+1)+'/'+date.day;
    let wid=viewState!.colWidthDate;
    let leftCol=(this.leftHover? proto!.colorHover:proto!.colorControl);
    let rightCol=(this.rightHover? proto!.colorHover:proto!.colorControl);
    const style={
      ...masterStyles!.noSelect,
      display: 'flex',
      height: '24px',
      borderWidth: '1px',
      borderStyle: 'none solid none none',
      alignItems: as!.center,
      justifyContent: as!.center,
      minWidth: `${wid}px`,
      maxWidth: `${wid}px`,
      borderColor: proto!.colorBorder,
      flex: '1 1 auto'
    };
    const daynavSpacerStyle={flex: '1',margin: 'auto',textAlign: as!.center,paddingBottom: '1px'};
    return (
      <div style={style}>
        <div
          onClick={this.handleBackClick}
          onMouseEnter={this.handleLeftMouseEnter}
          onMouseLeave={this.handleLeftMouseLeave}
          style={leftStyle}
        >
          <LeftArrowVector color={leftCol} width="16" height="16" />
        </div>
        <div style={daynavSpacerStyle}>{dateStr}</div>
        <div
          onClick={this.handleForwardClick}
          onMouseEnter={this.handleRightMouseEnter}
          onMouseLeave={this.handleRightMouseLeave}
          style={rightStyle}
        >
          <RightArrowVector color={rightCol} width="16" height="16" />
        </div>
      </div>
    );
  }
  //***********************
}
// ***********************************
