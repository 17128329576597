// ***********************************
import * as React from 'react';
import {computed} from 'mobx';
import {inject,observer} from 'mobx-react';
import TimeLine from '../../../../../Scripts/timeline';
import {IStores,IStoresInject,IEvent,IChannel} from '../../../../../Types/interfaces';
import EventItem from '../../../../Controls/Event/event';
// ***********************************
interface IProps extends IStoresInject {
  channel: IChannel;
  item: IEvent;
  index: number;
  last:boolean;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Row extends React.Component<IProps,{}> {
  //***********************
  @computed private get now(): boolean {return this.getNow();}
  //***********************
  private getNow(): boolean {
    const {item}=this.props;
    if(item.endTime<Date.now().valueOf()) {return false;}
    if(item.startTime>Date.now().valueOf()) {return false;}
    return true;
  }
  //***********************
  render() {
    const {proto,viewState,item,masterStyles}=this.props;

    let fontSize='12px';
    switch(viewState!.mediaState) {
      case 0: case 1: case 2: break;
      case 3: case 4: fontSize='14px'; break;
      case 5: case 6: case 7: fontSize='16px'; break;
      case 8: fontSize='18px'; break;
      default: break;
    }

    let width1=viewState!.colWidthDate;
    const style1={
      minWidth: `${width1}px`,maxWidth: `${width1}px`,borderColor: proto!.colorBorder,...masterStyles!.flexCenter,
      borderStyle: 'none solid none none',borderWidth: '1px',padding: '8px 0',fontSize: fontSize
    };
    let startTime=new TimeLine(item.startTime);
    let baseStyle={
      borderColor: proto!.colorBorder,
      backgroundColor: this.now? '#fffcd5':proto!.colorWindow,
      borderStyle: 'solid none none none',
      borderWidth: '1px'
    };
    let eventRowStyle={
      display: 'flex',
    };

    return (
      <div id={this.props.last?'dailyJustAired':''}style={baseStyle}>
        <div style={eventRowStyle}>
          <div style={style1}>{startTime.toTimeString()}</div>
          <EventItem
            channelIndex={this.props.channel.ChannelIndex}
            item={this.props.item}
            flex={100}
            last={false}
            running={false}
            continues={false}
            now={this.now}
          />
        </div>
      </div>
    );
  }
  //***********************
}
// ***********************************