import {PadStart} from '../Scripts/common';
// ***********************************
export default class TimeLine {
  //***********************
  private _date: Date;
  private _daysShort: string[];
  private _daysLong: string[];
  private _monthShort: string[]=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'];
  private _monthLong: string[]=['January','February','March','April','May','June','July','August','September','October','November','Dececember'];
  //***********************
  public get Date(): Date {return this._date;}
  public get minute(): number {return this._date.getMinutes();}
  public set minute(val: number) {this._date.setMinutes(val);}
  //***********************
  public get hour(): number {return this._date.getHours();}
  public set hour(val: number) {this._date.setHours(val);}
  //***********************
  public get day(): number {return this._date.getDate();}
  public set day(val: number) {this._date.setDate(val);}
  //***********************
  public get month(): number {return this._date.getMonth();}
  public set month(val: number) {this._date.setMonth(val);}
  //***********************
  public get year(): number {return this._date.getFullYear();}
  public set year(val: number) {this._date.setFullYear(val);}
  //***********************
  public get offset(): number {return this._date.getTimezoneOffset()/60;}
  public get stdOffset(): number {return this._stdOffset();}
  public get dst(): boolean {return this.offset<this.stdOffset;}
  public get raw(): number {return this._date.valueOf();}
  public get timeZoneOffset(): number {return this._date.getTimezoneOffset();}
  //***********************
  constructor();
  constructor(val: string);
  constructor(val: number);
  constructor(val: Date);
  constructor(val: TimeLine);
  constructor(val?: TimeLine|Date|string|number) {
    let source: Date;
    if(val) {
      if(val instanceof Date) {source=val;}
      else if(val instanceof TimeLine) {source=new Date(val.raw);}
      else if(typeof val==='number') {source=new Date(val);}
      else if(typeof val==='string') {
        const timeStr=val.substr(0,val.length-6)+'Z';
        source=new Date(timeStr);
        if(isNaN(source.valueOf())) {
          let err: string='invalid date string in TimeLine: string must be ISO-8601 compliant : ex 1969-12-01T00:00:00-06:00'
          throw new Error(err);
        }
        const offSet=source.getTimezoneOffset()/60;
        source.setHours(source.getHours()+offSet);
      }
      else {
        let err: string = 'an error has occurred in TimeLine';
        throw new Error(err);
      }
    }
    else {source=new Date();}

    this._daysShort=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    this._daysLong=['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    this._monthShort=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'];
    this._monthLong=['January','February','March','April','May','June','July','August','September','October','November','December'];
    this._date=source;
  }
  //***********************
  public dayValue(): string {
    return this._daysLong[this._date.getDay()];
  }
  //***********************
  public shortDayValue(): string {
    return this._daysShort[this._date.getDay()];
  }
  //***********************
  public toShortString(): string {
    let result: string='';
    result+=(this._date.getMonth()+1)+'/'+this._date.getDate();
    return result;
  }
  //***********************
  public toMidString(): string {
    let result: string='';
    result+=this._daysShort[this._date.getDay()]+' - '+this.toShortString();
    return result;
  }
  //***********************
  public toLongString(): string {
    let result: string='';
    result+=this._daysLong[this._date.getDay()]+' - '+this.toShortString();
    return result;
  }
  //***********************
  public toShortFullDateString(): string {
    let result: string='';
    result+=`${this._hour()}:${PadStart(this._date.getMinutes().toString(),'0',2)} `;
    result+=this._hourMod()+' ';
    result+=this._daysShort[this._date.getDay()]+', ';
    result+=this._monthShort[this._date.getMonth()]+' ';
    result+=this._date.getDate();
    return result;
  }
  //***********************
  public toLongFullDateString(): string {
    let result: string='';
    result+=`${this._hour()}:${PadStart(this._date.getMinutes().toString(),'0',2)} `;
    result+=this._hourMod()+' ';
    result+=this._daysLong[this._date.getDay()]+', ';
    result+=this._monthLong[this._date.getMonth()]+' ';
    result+=this._date.getDate();
    return result;
  }
  //***********************
  public toTimeString(): string {
    let result: string='';
    result+=`${this._hour()}:${PadStart(this._date.getMinutes().toString(),'0',2)} `;
    result+=this._hourMod()+' ';
    return result;
  }
  //***********************
  // yyyymmddhh
  public serviceValue(): string {
    let d=this._date;
    let result: string=PadStart(d.getFullYear().toString(),'0',4);
    result+=PadStart((d.getMonth()+1).toString(),'0',2);
    result+=PadStart(d.getDate().toString(),'0',2);
    result+=PadStart(d.getHours().toString(),'0',2);
    result+=PadStart(d.getMinutes().toString(),'0',2);
    return result;
  }
  //***********************
  public serviceValueHourOffset(val: number): string {
    let d=new Date(this._date);
    d.setHours(d.getHours()+val);
    let result: string=PadStart(d.getFullYear().toString(),'0',4);
    result+=PadStart((d.getMonth()+1).toString(),'0',2);
    result+=PadStart(d.getDate().toString(),'0',2);
    result+=PadStart(d.getHours().toString(),'0',2);
    result+=PadStart(d.getMinutes().toString(),'0',2);
    return result;
  }
  //***********************
  public valueOf(): number {
    return this._date.valueOf();
  }
  //***********************
  private _hour(): number {
    let result: number=this._date.getHours();
    return (result+11)%12+1;
  }
  //***********************
  public floored(): TimeLine {
    let flooredDate=new Date(this._date);
    flooredDate.setHours(0,0,0,0);
    return new TimeLine(flooredDate);
  }
  //***********************
  public floor(): void {
    this._date.setHours(0,0,0,0);
  }
  //***********************
  public floorHour(): void {
    // milliseconds in an hour=3600000
    this._date=new Date(Math.floor(this._date.valueOf()/3600000)*3600000);
  }
  //***********************
  private _hourMod(): string {
    return this._date.getHours()>11? 'PM':'AM';
  }
  //***********************
  private _stdOffset(): number {
    var jan=new Date(this._date.getFullYear(),0,1);
    var jul=new Date(this._date.getFullYear(),6,1);
    return Math.max(jan.getTimezoneOffset(),jul.getTimezoneOffset());
  }
  //***********************
  public shiftDay(amount: number): TimeLine {
    let result: TimeLine=new TimeLine(this);
    result.day+=amount;
    return result;
  }
  //***********************
}
// ***********************************