// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject,IProgramImage} from '../../../../../../../../Types/interfaces';
import LoadingVector from '../../../../../../../SVG/loading';
import ImageNotFoundVector from '../../../../../../../SVG/imagenotfound';
// ***********************************
interface IProps extends IStoresInject {thumbSize: number,image: IProgramImage}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class RibbonItem extends React.Component<IProps,{}> {
  //***********************
  @observable private _hover: boolean;
  @observable private _imageInitialized: boolean;
  @observable private _imageFound: boolean;
  @observable private _process: boolean;
  private _image: HTMLImageElement;
  private _imageUrl: string;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  componentDidMount(){ this._preloadImage(this.props.image.Uri); }
  @action componentWillUnmount(){ this._process=false; }
  //***********************
  @action private _construct(): void {
    const {image,thumbSize}=this.props;
    this._hover=false;
    this._process=true;
    this._imageUrl=`${image.Uri}?h=${thumbSize}`;
  }
  //***********************
  @action _preloadImage(imageUrl: string) {
    if(this._imageUrl===imageUrl) {return;}
    this._imageInitialized=false;
    this._imageFound=true;
    this._imageUrl=imageUrl;
    this._image=new Image();
    this._image.onload=this.imageFound;
    this._image.onerror=this.imageNotFound;
    this._image.src=imageUrl;
  }
  //***********************
  @action setInitialized(foundImage: boolean): void {
    this._imageInitialized=true;
    this._imageFound=foundImage;
  }
  //***********************
  imageFound=(): void => {this.setInitialized(true);};
  imageNotFound=(): void => {this.setInitialized(false);};
  imageError=(): void => {this.setInitialized(false);};
  //***********************
  @action handleMouseEnter=(): void => {
    this._hover=true;
  }
  //***********************
  @action handleMouseLeave=(): void => {
    this._hover=false;
  }
  //***********************
  handleMouseClick=(): void => {
    const {viewState,image}=this.props;
    viewState!.setScreenShot(image);
  }
  //***********************
  render() {
    const {thumbSize,proto,image}=this.props;
    
    if(!this._process){ return(''); }

    let margin: number=4;
    let thumb=thumbSize-2-(margin*2)

    const ownerStyle={
      border: this._imageInitialized&&this._imageFound&&this._hover? '1px solid black':`1px solid ${proto!.colorWindow}`,
      width: `${thumb}px`,height: `${thumb}px`,display: 'inline-flex',verticalAlign: 'middle',cursor: 'pointer',margin: `${margin}px ${margin*2}px ${margin}px 0`
    };

    const noImageStyle={
      flex: 100,
      border: '1px solid #d5d5d5'
    };
    const imageStyle={
      flex: 100,
      backgroundImage: `url(${this._imageUrl})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      filter: this._hover? 'grayscale(.15) brightness(.85)':'',
      backgroundSize: 'cover'
    };

    if(!this._imageInitialized) {
      return (
        <div style={ownerStyle}>
          <div style={noImageStyle} >
            <LoadingVector fill={'#d5d5d5'} size={thumb} />
          </div>
        </div>
      );
    }
    else {
      if(this._imageFound) {
        return (
          <div style={ownerStyle} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleMouseClick} >
            <div title={`${image.SubTypeCode} • ${image.Type}`} style={imageStyle} />
          </div>
        );
      }
      else {
        return (
          <div style={ownerStyle} >
            <div style={noImageStyle} >
              <ImageNotFoundVector fill={'#d5d5d5'} size={thumb} />
            </div>
          </div>
        );
      }
    }
  }
  //***********************
}
// ***********************************