// ***********************************
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject, IDropDownItem } from '../../../../Types/interfaces';
import TimeLine from '../../../../Scripts/timeline';
import DropDownList, { InitDropDownList, InitDropDownItem } from '../../../Controls/DropDownList/dropdownlist';
import Now from '../../../Controls/Now/now';
import Navigation from '../../../Controls/Navigation/navigation';
import Print from '../../../Controls/Print/print';
import Filters from '../../../Controls/Filters/filters';
import Search from '../../../Controls/Search/search';
// ***********************************
interface IProps extends IStoresInject { }
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class Settings extends React.Component<IProps, {}> {
  //***********************
  getLineups(): IDropDownItem[] {
    const { lineupInfo } = this.props;
    let lineups = lineupInfo!.lineups;
    let result: IDropDownItem[] = [];
    for (let i: number = 0; i < lineups.length; i++) {
      let item = InitDropDownItem(lineups[i].LineupName, lineups[i].Description, i, true);
      result.push(item);
    }
    return result;
  }
  //***********************
  getChannels(): IDropDownItem[] {
    const { lineupInfo } = this.props;
    let channels = lineupInfo!.channels;
    let result: IDropDownItem[] = [];
    for (let i: number = 0; i < channels.length; i++) {
      let text:string=lineupInfo!.getChannelText(channels[i]);
      let item = InitDropDownItem(text, channels[i].Description, i, true);
      result.push(item);
    }
    return result;
  }
  //***********************
  getDates(): IDropDownItem[] {
    const { viewState, frame } = this.props;
    let result: IDropDownItem[] = [];
    let dates = frame!.dates;
    for (let i: number = 0; i < dates.length; i++) {
      let dt: TimeLine = dates[i];
      switch (viewState!.mediaState) {
        case 0: case 1: case 2:
          result.push(InitDropDownItem(new TimeLine(dt).toShortString(), '', i, true));
          break;
        case 3:
          result.push(InitDropDownItem(new TimeLine(dt).toMidString(), '', i, true));
          break;
        case 4: case 5: default:
          result.push(InitDropDownItem(new TimeLine(dt).toLongString(), '', i, true));
          break;
      }
    }
    return result;
  }
  //***********************
  getHours(): IDropDownItem[] {
    let result: IDropDownItem[] = [];
    const { frame } = this.props;
    for (let i = 0; i < 24; i++) {
      var mid: string = (i < 12) ? ' AM' : ' PM';
      var hour = ((i + 11) % 12 + 1);
      let enabled = !frame!.isHourBound(i);
      let item = InitDropDownItem(hour.toString() + ':00' + mid, '', i, enabled);
      result.push(item);
    }
    return result;
  }
  //***********************
  handleLineupItemClick = (lineupIndex: number): void => {
    const { lineupInfo } = this.props;
    lineupInfo!.setLineupIndex(lineupIndex);
  }
  //***********************
  handleChannelItemClick = (channelIndex: number): void => {
    const { lineupInfo } = this.props;
    lineupInfo!.setChannelIndex(channelIndex);
  }
  //***********************
  handleDateItemClick = (dayIndex: number): void => {
    const { frame } = this.props;
    frame!.setDateIndex(dayIndex);
  }
  //***********************
  handleHourItemClick = (hourIndex: number): void => {
    const { frame } = this.props;
    frame!.setHourIndex(hourIndex);
  }
  //***********************
  toggleHD = (): void => {
    const { viewState } = this.props;
    viewState!.setHD(!viewState!.filterHd);
  }
  //***********************
  toggleNew = (): void => {
    const { viewState } = this.props;
    viewState!.setNew(!viewState!.filterNew);
  }
  //***********************
  render() {
    const { viewState, frame, proto, lineupInfo, siteSettings } = this.props;
    let border: boolean=siteSettings!.enableScrollingBody;
    if (!viewState!.showSettings && (viewState!.mediaState < 3 && siteSettings!.showHamburger)) { return (<div/>); }
    if (!lineupInfo!.channelsLoaded) { return (<div>LOADING...</div>); }
    let lineupDdl = InitDropDownList(lineupInfo!.lineupIndex, this.getLineups(), this.handleLineupItemClick, 'Channel Lineup');
    let channelDdl = InitDropDownList(lineupInfo!.channelIndex, this.getChannels(), this.handleChannelItemClick, 'Channel');
    let hoursDdl = InitDropDownList(frame!.hourIndex, this.getHours(), this.handleHourItemClick, 'Time');
    let baseStyle = { minHeight:'62px',borderColor: proto!.colorBorder, borderWidth: '1px', borderStyle: `${border?'none':'solid'} none ${border?'solid':'none'} none`, padding: '8px' };
    return (
      <div style={baseStyle}>
        <Print />
        {siteSettings!.enableViews ? <Navigation /> : ''}
        {siteSettings!.enableLineups ? <DropDownList {...lineupDdl} /> : ''}
        <DropDownList {...channelDdl} /> 
        <DropDownList {...hoursDdl} buttonRight={<Now />} />
        {siteSettings!.enableSearch ? <Search /> : ''}
        <Filters />
      </div>
    );
  }
  //***********************
}
// ***********************************