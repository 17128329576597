// ***********************************
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../Types/interfaces';
// ***********************************
interface IProps extends IStoresInject { 
  message: string;
  caller: string;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class AppError extends React.Component<IProps, {}> {
  //***********************
  componentDidMount(){
    let {message, caller}=this.props;
    console.log('Error:\''+message+'\' in '+caller);
  }

  render() {
    return ('');
  }
  //***********************
}
// ***********************************