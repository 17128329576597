import * as React from 'react';
// ***********************************
interface IProps {
  color: string;
  size: string;
}
// ***********************************
export default class DefaultVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.size,
      width: this.props.size,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 512 512">
        <g fill={this.props.color}>
          <path d="M482.513 83.942c-7.225-7.233-15.797-10.85-25.694-10.85h-36.541v-27.41c0-12.56-4.477-23.315-13.422-32.261C397.906 4.475 387.157 0 374.591 0h-18.268c-12.565 0-23.318 4.475-32.264 13.422-8.949 8.945-13.422 19.701-13.422 32.261v27.41h-109.63v-27.41c0-12.56-4.475-23.315-13.422-32.261C178.64 4.475 167.886 0 155.321 0H137.05c-12.562 0-23.317 4.475-32.264 13.422-8.945 8.945-13.421 19.701-13.421 32.261v27.41H54.823c-9.9 0-18.464 3.617-25.697 10.85-7.233 7.232-10.85 15.8-10.85 25.697v365.453c0 9.89 3.617 18.456 10.85 25.693 7.232 7.231 15.796 10.849 25.697 10.849h401.989c9.897 0 18.47-3.617 25.694-10.849 7.234-7.234 10.852-15.804 10.852-25.693V109.639c-.001-9.9-3.615-18.464-10.845-25.697zM347.187 45.686c0-2.667.849-4.858 2.56-6.567 1.711-1.711 3.901-2.568 6.57-2.568h18.268c2.67 0 4.853.854 6.57 2.568 1.712 1.712 2.567 3.903 2.567 6.567v82.224c0 2.666-.855 4.853-2.567 6.567-1.718 1.709-3.9 2.568-6.57 2.568h-18.268c-2.669 0-4.859-.855-6.57-2.568-1.711-1.715-2.56-3.901-2.56-6.567V45.686zm-219.272 0c0-2.667.855-4.858 2.568-6.567 1.714-1.711 3.901-2.568 6.567-2.568h18.271c2.667 0 4.858.854 6.567 2.568 1.711 1.712 2.57 3.903 2.57 6.567v82.224c0 2.666-.855 4.856-2.57 6.567-1.713 1.709-3.9 2.568-6.567 2.568H137.05c-2.666 0-4.856-.855-6.567-2.568-1.709-1.715-2.568-3.901-2.568-6.567V45.686zm328.897 429.402H54.823v-292.36h401.989v292.36z"/>
          <path d="M158 202.69492h34.711864v255H158z"/>
          <path d="M430 255.25424v34.711864H80V255.25424zm0 106v34.711864H80V361.25424z"/>
          <path d="M318 202.69492h34.711864v255H318z"/>
        </g>
      </svg>
    );
  }
  //***********************
}