// ***********************************
import * as React from 'react';
//import ReactGA from 'react-ga';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../Types/interfaces';
import TvEverywhereVector from '../../SVG/Logos/tveverywhere';
// ***********************************
interface IProps extends IStoresInject { }
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class TvEverywhere extends React.Component<IProps, {}> {
  //***********************
  @observable hover:boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.hover=false;
  }
  //***********************
  hClick = (): boolean => {
    const { siteSettings } = this.props;
    window.open(siteSettings!.tvEverywhere,'_blank');
    //ReactGA.event({ category: 'User Action', action: 'Clicked TV Everywhere' });
    return false;
  }
  //***********************
  @action hMouseEnter= ():void =>{ this.hover=true; };
  @action hMouseLeave= ():void =>{ this.hover=false; };
  //***********************
  render() {
    const { siteSettings, masterStyles } = this.props;
    const baseStyle={ ...masterStyles!.componentBase };
    const labelStyle={fontSize:'.75em', paddingTop:'2px' };
    const svgStyle={ padding: '2px 0 0 4px', cursor:'pointer' };
    if(!siteSettings!.showTvEverywhere){return('');}
    return (
      <div style={baseStyle}>
        <div style={labelStyle}>TV Everywhere</div>
        <div style={svgStyle} onClick={this.hClick} onMouseEnter={this.hMouseEnter} onMouseLeave={this.hMouseLeave} >
          <TvEverywhereVector hover={!this.hover} height={24}/>
        </div>
      </div>
    );
  }
  //***********************
}
// ***********************************