import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
  fill?: string;
}
// ***********************************
export default class FacebookVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.size,
      width: this.props.size,
    };
    if(this.props.fill){
      return(
        <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 310 310" >
          <path fill={this.props.fill} d="M81.703 165.106h33.981V305c0 2.762 2.238 5 5 5H178.3c2.762 0 5-2.238 5-5V165.765h39.064c2.54 0 4.677-1.906 4.967-4.429l5.933-51.502c.163-1.417-.286-2.836-1.234-3.899-.949-1.064-2.307-1.673-3.732-1.673h-44.996V71.978c0-9.732 5.24-14.667 15.576-14.667h29.42c2.762 0 5-2.239 5-5V5.037c0-2.762-2.238-5-5-5h-40.545c-.286-.014-.921-.037-1.857-.037-7.035 0-31.488 1.381-50.804 19.151-21.402 19.692-18.427 43.27-17.716 47.358v37.752H81.703c-2.762 0-5 2.238-5 5v50.844c0 2.762 2.238 5.001 5 5.001z"/>
        </svg>
      );
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 456 456">
        <path fill="#3A559F" d="M0 0v455.73h242.704V279.691h-59.33v-71.864h59.33v-60.353c0-43.893 35.582-79.475 79.475-79.475h62.025v64.622h-44.382c-13.947 0-25.254 11.307-25.254 25.254v49.953h68.521l-9.47 71.864h-59.051V455.73H455.73V0H0z"/>
      </svg>
    );
  }
  //***********************
}