// ***********************************
import {IRgba, IAdJson} from '../Types/interfaces';
// ***********************************

export function GetUrlParameter(uri: string,name: string,valueWhenNull: string=''): string {
  // eslint-disable-next-line no-useless-escape
  name=name.replace(/[\[]/,'\\[').replace(/[\]]/,'\\]');
  var regex=new RegExp('[\\?&]'+name+'=([^&#]*)');
  var results=regex.exec(uri);
  return results===null? valueWhenNull:decodeURIComponent(results[1].replace(/\+/g,' '));
}
// ***********************************
export function PadStart(target: string,padChar: string,count: number): string {
  if(target.length>=count) {return target;}
  while(target.length<count) {target=padChar+target;}
  return target;
}
// ***********************************
export function HexToRgbaHover(hex: string,hover: boolean): IRgba {
  let result=HexToRgba(hex,hover? .6:1);
  return result;
}
// ***********************************
export function HexToRgba(hex: string,alpha: number): IRgba {
  var arrBuff=new ArrayBuffer(4);
  var vw=new DataView(arrBuff);
  vw.setUint32(0,parseInt(hex.substr(1,6),16),false);
  var arrByte=new Uint8Array(arrBuff);

  let result: IRgba={r: arrByte[1],g: arrByte[2],b: arrByte[3],a: alpha};
  return result;
}
// ***********************************
export function MediaMatch(width: string): boolean {
  return window.matchMedia('(min-width: '+width+'px)').matches;
}
// ***********************************
export function DurationToMinutes(duration: string): number {
  let durationRegex=/P((([0-9]*\.?[0-9]*)Y)?(([0-9]*\.?[0-9]*)M)?(([0-9]*\.?[0-9]*)W)?(([0-9]*\.?[0-9]*)D)?)?(T(([0-9]*\.?[0-9]*)H)?(([0-9]*\.?[0-9]*)M)?(([0-9]*\.?[0-9]*)S)?)?/;
  let matches=duration.match(durationRegex);
  let result: number=parseInt(matches![12],0)*60;
  result+=parseInt(matches![14],0);
  return result;
}
// ***********************************
export function AutoTextColor(bgColor: string,dark: string='#202020',light: string='#F0F0F0'): string {
  let color=bgColor;
  switch(bgColor.length) {
    default: return dark;
    case 6: break;
    case 7:
      color=bgColor.substring(1); break;
    case 3:
      color=bgColor;
      color=color[0]+color[0]+color[1]+color[1]+color[2]+color[2];
      break;
    case 4:
      color=bgColor.substring(1);
      color=color[0]+color[0]+color[1]+color[1]+color[2]+color[2];
      break;
  }

  let red: number=parseInt(color.substring(0,2),16); // hexToR
  let green: number=parseInt(color.substring(2,4),16); // hexToG
  let blue: number=parseInt(color.substring(4,6),16); // hexToB
  let uicolors: number[]=[red/255,green/255,blue/255];
  let c: number[]=uicolors.map((col) => {
    if(col<=0.03928) {
      return col/12.92;
    }
    return Math.pow((col+0.055)/1.055,2.4);
  });
  let luminance=(0.2126*c[0])+(0.7152*c[1])+(0.0722*c[2]);
  return (luminance>0.179)? dark:light;
}
// ***********************************
export function MinutesToMilliseconds(minutes: number) {
  // 60000 milliseconds in a minute
  return minutes*60000;
}
// ***********************************
export function Flex(itemWidth: number,boxWidth: number): number {
  return ((itemWidth/boxWidth)*100);
}
// ***********************************
export function PopupPosStr(popupWidth: number,popupHeight: number) {
  try {
    popupWidth=window.screen.availWidth<popupWidth? window.screen.availWidth:popupWidth;
    popupHeight=window.screen.availHeight<popupHeight? window.screen.availHeight:popupHeight;
    var posStr: string='';
    let left=(window.screen.width-popupWidth)/2;
    let top=(window.screen.availHeight*.4-popupHeight/2);
    top=top<0? 0:top;
    left=left<0? 0:left;
    posStr='left='+left+',top='+top;
  }
  catch(e) {posStr='left=0,top=0';}
  return posStr+',width='+popupWidth+',height='+popupHeight;
}
// ***********************************
export function HttpEncode(value: string): string {
  // let el = document.createElement('div');
  // el.innerText=value;
  // return el.innerHTML;
  return encodeURIComponent(value);
}
// ***********************************
// Get IE or Edge browser version
export function DetectIE() {
  let ua=window.navigator.userAgent;

  let msie=ua.indexOf('MSIE ');
  if(msie>0) {return parseInt(ua.substring(msie+5,ua.indexOf('.',msie)),10);}

  let trident=ua.indexOf('Trident/');
  if(trident>0) {
    var rv=ua.indexOf('rv:');
    return parseInt(ua.substring(rv+3,ua.indexOf('.',rv)),10);
  }

  var edge=ua.indexOf('Edge/');
  if(edge>0) {return parseInt(ua.substring(edge+5,ua.indexOf('.',edge)),11);}

  return 13; // not microsoft browser
}
// ***********************************
export function WheelUp(e: WheelEvent,wheelScrollType: string): boolean {
  let result: boolean=false;
  switch(wheelScrollType) {
    case 'wheel':
      if(e.deltaY<0) {result=true;}
      break;
    case 'mousewheel':
      if((e as any).wheelDelta>0) {result=true;}
      break;
    default:
      const err: string='unknown wheel';
      throw new Error(err);
  }
  return result;
}
// ***********************************
export function getContrast(hexcolor:string){
	// If a leading # is provided, remove it
	if (hexcolor.slice(0, 1) === '#') {
		hexcolor = hexcolor.slice(1);
	}

	// Convert to RGB value
	var r = parseInt(hexcolor.substr(0,2),16);
	var g = parseInt(hexcolor.substr(2,2),16);
	var b = parseInt(hexcolor.substr(4,2),16);

	// Get YIQ ratio
	var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  

	// Check contrast
	return (yiq >= 128) ? 'black' : 'white';
};
// ***********************************
export function IsJson(str: string): IAdJson {
  if(str==='FREESTAR') {return {isGpt: true, isFreestar: true, slot:'', key: ''};}
  try {
    var json: IAdJson=JSON.parse(str);
  } catch(e) {
    return {isGpt: false,isFreestar: false, slot: '', key: ''};
  }
  if(!json.slot) {return {isGpt: false, isFreestar: false, slot: '', key: ''};}
  return {isGpt: true, isFreestar: false, slot: json.slot, key: json.key};
}
// ***********************************