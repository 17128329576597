import '../loader.css';
// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
// ***********************************
interface IProps extends IStoresInject {
  last: boolean;
  flex: number;
  borderTop?: boolean;
  searchStyle?: boolean;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class LoaderSmall extends React.Component<IProps,{}>{
  //***********************
  render() {
    const {proto,masterStyles,borderTop,searchStyle,as}=this.props;
    const smallText={
      position: as!.relative,color: 'rgba(164,164,164, .9)',fontStyle: as!.italic,
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',fontSize: '12px',fontWeight: as!.bold
    };
    const loaderSmallText={...smallText,marginRight: '-55px',right: '46px'};
    const searchSmallText={...smallText,marginRight: '-35px',right: '53px'};

    let style={
      borderColor: proto!.colorBorder,borderRightStyle: this.props.last? as!.none:as!.solid,flex: this.props.flex.toString()+'%',
      borderWidth: '1px',display: 'flex',alignItems: as!.center,justifyContent: as!.center,width: '100%',
      borderTopStyle: borderTop? as!.solid:as!.none,...masterStyles!.noSelect
    };
    let trimStyle={display: 'flex',alignItems: as!.center,justifyContent: as!.center,padding: '10px 0'};
    return (
      <div style={style}>
        <div style={trimStyle}>
          <div className="loader-small" />
          <div style={searchStyle? searchSmallText:loaderSmallText}>{searchStyle? 'SEARCHING':'LOADING'}</div>
        </div>
      </div>
    );
  }
  //***********************
}
