// ***********************************
import * as React from 'react';
import {action,observable} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import {DetectIE} from '../../../../Scripts/common';
// ***********************************
interface IProps extends IStoresInject {}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class ScreenShot extends React.Component<IProps,{}> {
  //***********************
  @observable private _hover: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  componentDidMount() {
    document.body.style.overflow='hidden';
  }
  //***********************
  componentWillUnmount() {
    document.body.style.overflow='';
  }
  //***********************
  @action private _construct(): void {this._hover=false;}
  //***********************
  @action handleMouseEnter=(): void => {this._hover=true;}
  //***********************
  @action handleMouseLeave=(): void => {this._hover=false;}
  //***********************
  handleMouseClick=(): void => {
    const {viewState}=this.props;
    viewState!.setScreenShot();
    this.handleMouseLeave();
  }
  //***********************
  render() {
    const {viewState,as,proto,masterStyles}=this.props;

    let ie=DetectIE()<12;

    const ownerStyle={
      position: as!.fixed,right: 0,top: 0,...masterStyles!.flexCenter,
      width: '100%',height: '100%',backgroundColor: ie? '#d5d5d5':'#000000bb',
      color: this._hover? proto!.colorHover:'white',zIndex: 9999
    };

    const closeXStyle={
      position: as!.fixed,right: 15,top: proto!.protoTop+15,padding: '5px',cursor: 'pointer',
      fontFamily: 'Verdana',fontSize: '3.5em',fontWeight: 900,backgroundColor: '#00000040',borderRadius: '5px'
    };
    let divImageStyle={
      display: 'inline-block',
      maxWidth: '97%',
      maxHeight: '95%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: `url(${viewState!.screenShot!.Uri})`,
      content: `url(${viewState!.screenShot!.Uri})`,
      border: '1px solid white',
      margin: 'auto'
    }

    let imgImageStyle={      
      maxWidth: '97%',
      maxHeight: '95%',
      border: '1px solid white',
    };

    return (
      <div style={ownerStyle}>
        {ie?<img style={imgImageStyle} src={viewState!.screenShot!.Uri} alt=""/>:<div style={divImageStyle} />}
        <div style={closeXStyle} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleMouseClick}>X</div>
      </div>
    );
  }
  //***********************
}
// ***********************************