import * as React from 'react';
// ***********************************
interface IProps {
  color: string;
  width: number;
}
// ***********************************
export default class MoreRightVector extends React.Component<IProps, {}> {
  //***********************
  height:number;
  constructor(props:IProps){
    super(props);
    this.height=this.props.width*(386.258/64.124864);
  }
  render() {
    let style={
      fill:this.props.color,
      width: this.props.width,
      height: this.height,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} width={this.props.width} height={this.height} viewBox="0 0 64.124864 386.258">
  <path d="M4.3e-7 0v386.258l64.12486273-193.129z"/>
</svg>
    );
  }
  
  //***********************
}