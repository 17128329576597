import * as React from 'react';
// ***********************************
interface IProps {
  height: string;
  width: string;
  fill: string;
  index?: number;
}
// ***********************************
export default class MailTo extends React.Component<IProps, {}> {
  //***********************
  svg1(style:{}): any {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.height} width={this.props.width} viewBox="0 0 438 438">
        <g fill={this.props.fill}>
          <path d="M218.841 256.659L19.744 81.824h398.187M139.529 218.781L0 341.311V96.252" />
          <path d="M157.615 234.665l61.226 53.762 61.214-53.762 138.002 121.187H19.619M298.141 218.787L437.676 96.252v245.059" />
        </g>
      </svg>
    );
  }
  //***********************
  svg2(style:{}): any {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.height} width={this.props.width} viewBox="0 0 512 512">
        <g fill={this.props.fill}>
          <path d="M331.756 277.251l-42.881 43.026c-17.389 17.45-47.985 17.826-65.75 0l-42.883-43.026L26.226 431.767C31.959 434.418 38.28 436 45 436h422c6.72 0 13.039-1.58 18.77-4.232L331.756 277.251z" />
          <path d="M467 76H45c-6.72 0-13.041 1.582-18.772 4.233l164.577 165.123c.011.011.024.013.035.024.011.011.013.026.013.026l53.513 53.69c5.684 5.684 17.586 5.684 23.27 0l53.502-53.681s.013-.024.024-.035c0 0 .024-.013.035-.024L485.77 80.232C480.039 77.58 473.72 76 467 76zM4.786 101.212C1.82 107.21 0 113.868 0 121v270c0 7.132 1.818 13.79 4.785 19.788l154.283-154.783L4.786 101.212zM507.214 101.21L352.933 256.005 507.214 410.79C510.18 404.792 512 398.134 512 391V121c0-7.134-1.82-13.792-4.786-19.79z" />
        </g>
      </svg>
    );
  }
  //***********************
  render() {
    let style = {
      height: this.props.height,
      width: this.props.width,
    };
    switch (this.props.index) {
      case 1: default: return(this.svg1(style));
      case 2: return(this.svg2(style));
    }
  }
  //***********************
}