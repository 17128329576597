// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {inject,observer} from 'mobx-react';
import {HexToRgbaHover} from '../../../Scripts/common';
import TimeLine from '../../../Scripts/timeline';
import {IStores,IStoresInject,IRgba,IEvent} from '../../../Types/interfaces';
import {GridColorStyle,ViewContext} from '../../../Types/enums';
import LoaderSmall from '../Loaders/LoaderSmall/loadersmall';
import SocialMedia from './SocialMedia/socialmedia';
import EventDetails from './EventDetails/eventdetails';
import OrientLeft from './OrientLeft/orientleft';
import OrientRight from './OrientRight/orientright';
// ***********************************
interface IProps extends IStoresInject {
  channelIndex: number;
  item: IEvent;
  flex: number;
  last: boolean;
  running: boolean;
  continues: boolean;
  float?: boolean;
  debug?: any;
  now?:boolean;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class EventItem extends React.Component<IProps,{}> {
  //***********************
  private _flagClicked:number;
  @observable private _imageInitialized: boolean;
  @observable private _hover: boolean;
  private _showImage: boolean;
  private _imageUrl: string;
  private _image: HTMLImageElement;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
    this._showImage=true;
    this.componentWillUpdate();
  }
  //***********************
  @action _construct() {
    this._hover=false;
    this._imageInitialized=false;
  }
  //***********************
  @action setShowImage(showImage: boolean): void {
    this._showImage=showImage;
    this._imageInitialized=true;
  }
  //***********************
  imageFound=(): void => {this.setShowImage(true);};
  imageNotFound=(): void => {this.setShowImage(false);};
  imageError=(): void => {this.setShowImage(false);};
  //***********************

  @action preloadImage(imageUrl: string) {
    if(!this.props.siteSettings!.showProgramImages) {
      this._showImage=false;
      this._imageInitialized=true;
      return;
    }
    if(this._imageUrl===imageUrl) { return;}
    this._imageInitialized=false;
    this._imageUrl=imageUrl;
    this._image=new Image();
    this._image.onload=this.imageFound;
    this._image.onerror=this.imageNotFound;
    this._image.src=imageUrl;
  }
  //***********************
  @action handleClick=(e: React.FormEvent<HTMLElement>): boolean => {
    e.stopPropagation();
    const {viewState,item,lineupInfo}=this.props;
    if(this.props.item.EventId===0){return false;} // EventId of 0 === No program available
    viewState!.clickedEventId=this.props.item.EventId;
    let channel: string=lineupInfo!.getChannelName(item.channelIndex);
    let time: string=new TimeLine(item.startTime).toShortFullDateString();
    viewState!.setDetails(item.EventId,item.ProgramId,item.channelIndex!,channel,time);
    return false;
  }
  //***********************
  @action handleMouseEnter=(): void => {
    this._hover=true;
    const {viewState}=this.props;
    if(viewState!.clickedEventId>-1){
      this._flagClicked=viewState!.clickedEventId;
      window.setTimeout(this.declick,100);
    }
  }
  //***********************
  declick = () =>{
    const {viewState}=this.props;
    if(this._flagClicked===viewState!.clickedEventId){
      viewState!.clickedEventId=-1;
      this._flagClicked=-2;
    }
  }
  //***********************
  @action handleMouseLeave=(): void => {
    this._hover=false;
  }
  //***********************
  componentWillMount() {
    this.componentWillUpdate();
  }
  //***********************
  componentWillUpdate() {
    this.preloadImage(this.props.item.ShowCardUri);
  }
  //***********************
  render() {
    const {item,flex,last,running,continues,proto,siteSettings,viewState,masterStyles,as}=this.props;

    let borderStyle={
      borderColor: proto!.colorBorder,
      borderWidth: '1px'
    };

    if(viewState!.filterNew&&item!.Flags&&item!.Flags.NewRepeat!=='N') {
      let emptyStyle={
        ...borderStyle,
        borderRightStyle: last? as!.none:as!.solid,
        flex: flex.toString()+'%',display: 'flex',width: '100%',
        backgroundColor: proto!.colorBorder
      };
      return (<div style={emptyStyle} />);
    }

    if(!siteSettings!.loaded||!this._imageInitialized) {
      return (<LoaderSmall flex={flex} last={last} />);
    }

    let colSet=siteSettings!.colors.getColorForEvent(item);

    let clicked = viewState!.clickedEventId===this.props.item.EventId;

    let bgColRgba: IRgba=HexToRgbaHover(colSet.BackgroundColor,this._hover);
    let bgCol: string=`rgba(${bgColRgba.r},${bgColRgba.g},${bgColRgba.b},${bgColRgba.a})`;
    bgCol=siteSettings!.genreColorStyle===GridColorStyle.None? proto!.colorWindow:bgCol
    bgCol=clicked&&siteSettings!.enableDetails?'#D9DFFF':bgCol;
    bgCol=this.props.now?'#fffcd5':bgCol;
    bgCol=this._hover?'#D9DFFF':bgCol;

    let baseScale=.7;
    let viewScale=baseScale+.15;
    switch(viewState!.mediaState) {
      case 0: case 1: case 2: viewScale=baseScale+.05; break;
      case 3: case 4: viewScale=baseScale+.1; break;
      default: break;
    }

    const scale=viewState!.fourKDisplay? 1.2:viewScale;
    const hasSocialMedia=item.Links&&item.Links.length>0;

    const smMaxOffset=hasSocialMedia? proto!.maxGridHeight-21:proto!.maxGridHeight;
    const smMinOffset=hasSocialMedia? proto!.minGridHeight-21:proto!.minGridHeight;

    let maxHeight=viewState!.fourKDisplay? smMaxOffset+35:smMaxOffset-5;
    let minHeight=viewState!.fourKDisplay? smMinOffset+35:smMinOffset-5;

    maxHeight=proto!.forceGridMaxHeight? maxHeight:9999;
    minHeight=proto!.forceGridMinHeight? minHeight:0;

    let ownerStyle={
      ...borderStyle,
      cursor: 'pointer',
      borderRightStyle: last? as!.none:as!.solid,
      boxSizing: as!.borderBox,
      flex: this.props.flex.toString()+'%',
      display: 'flex',
      alignItems: as!.flexStart,
      justifyContent: as!.center,
      backgroundColor: bgCol,
      wordWrap: as!.breakWord,
      transition: 'background-color .25s'
    };
    let detailOuterStyle={
      flexGrow: 1,
      zIndex: 2,
      margin: '2px'
    };
    let detailInnerStyle={
      overflow: as!.hidden,
      maxHeight: this.props.float? '500px':maxHeight+'px',
      minHeight: (this.props.float||viewState!.viewMode===ViewContext.Daily)? '0':minHeight+'px',
    };
    let genreStyle={...masterStyles!.getGenreStyle(colSet.BackgroundColor)};
    let orientStyle={display: 'flex',height: '100%',alignItems: as!.center,flexGrow: 0};

    return (
      <div id="owner" style={ownerStyle} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleClick}>
        <OrientLeft item={this.props!.item} running={running} orientStyle={orientStyle} />
        <div style={detailOuterStyle}>
          <div style={detailInnerStyle}>
            <EventDetails
              showImage={this._showImage}
              channelIndex={this.props.channelIndex}
              item={item}
              flex={this.props.flex}
              running={running}
              hover={this._hover}
              debug={this.props.debug}
              fontSize={scale}
              now={this.props.now}
            />
          </div>
          <SocialMedia item={item} />
        </div>
        <OrientRight item={this.props!.item} continues={continues} orientStyle={orientStyle} />
        <div style={genreStyle} />
      </div>
    );
  }
  //***********************
}