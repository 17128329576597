// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../Types/interfaces';
import {ViewContext} from '../../../Types/enums';
import NavButton from './NavButton/navbutton';
// ***********************************
interface IProps extends IStoresInject {}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BackButton extends React.Component<IProps,{}> {
  //***********************
  keyDownBinding:any;
  //***********************
  constructor (props:IProps) {
    super(props);
    this.keyDownBinding=this.handleKeyDown.bind(this);
  }
  //***********************
  componentDidMount() { window.addEventListener('keydown',this.keyDownBinding,false); }
  componentWillUnmount() { window.removeEventListener('keydown',this.keyDownBinding,false); }
  //***********************
  handleKeyDown=(e: React.KeyboardEvent<HTMLElement>) => {
    if(e.keyCode===27) {
      this.handleClick();
      return false;
    }
    return true;
  }
  //***********************
  handleClick=(): void => {
    const {viewState}=this.props;
    viewState!.viewBack();
  }
  //***********************
  render() {

    const {masterStyles}=this.props;
    let baseStyle={...masterStyles!.componentBase};
    return (
      <div style={baseStyle}>
        <NavButton title={'Return to listing'} viewContext={ViewContext.Back} handleClick={this.handleClick} />
      </div>
    );
  }
  //***********************
}