// ***********************************
import * as React from 'react';
import { inject } from 'mobx-react';
import { IStoresInject, IStores } from '../../../../Types/interfaces';
// ***********************************
interface IProps extends IStoresInject {
  legendColor: string;
  borderColor: string;
  textColor: string;
  genreText: string;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
export default class LegendItem extends React.Component<IProps, {}> {
  //***********************
  render() {
    const { genreText, legendColor, borderColor, textColor, masterStyles } = this.props;
    let baseStyle = { display: 'inline-block', padding: '5px' };

    let bubbleStyle = {
      backgroundColor: legendColor, height: '22px', width: '14px', borderRadius: '6px', borderWidth: '1px',
      borderStyle: 'solid', borderColor: borderColor, ...masterStyles!.inlineMiddle
    };
    let textStyle = { color: textColor, marginLeft: '3px', fontSize: '12px', ...masterStyles!.inlineMiddle };
    return (
      <div style={baseStyle} >
        <div style={bubbleStyle} />
        <div style={textStyle}>{genreText}</div>
      </div>
    );
  }
  //***********************
}
// ***********************************