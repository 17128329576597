// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject,IEvent,ISearchDisplayEvent, IProgramEventDetails} from '../../../../Types/interfaces';
import SocialMediaBtn from './SocialMediaBtn/socialmediabtn';
import {EventLinkTypeEnum} from '../../../../Types/enums';
// ***********************************
interface IProps extends IStoresInject {
  item: IEvent|ISearchDisplayEvent|IProgramEventDetails|null;
  large?: boolean;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class SocialMedia extends React.Component<IProps,{}> {
  //***********************
  socialMedia: EventLinkTypeEnum[]=[];
  constructor(props: IProps) {
    super(props);
    // set order for the links
    this.socialMedia.push(EventLinkTypeEnum.Facebook);
    this.socialMedia.push(EventLinkTypeEnum.Twitter);
    this.socialMedia.push(EventLinkTypeEnum.Instagram);
    this.socialMedia.push(EventLinkTypeEnum.Pinterest);
    this.socialMedia.push(EventLinkTypeEnum.LinkedIn);
    this.socialMedia.push(EventLinkTypeEnum.ProgramInfo);
    this.socialMedia.push(EventLinkTypeEnum.LiveStream);
  }
  render() {
    const {item,as}=this.props;
    let links: any[]=[];
    for(let i: number=0;i<this.socialMedia.length;i++) {
      let linkType: EventLinkTypeEnum=this.socialMedia[i];
      for(let j: number=0;item!.Links&&j<item!.Links.length;j++) {
        let link=item!.Links[j];
        if(link.Type===linkType) {
          links.push(<SocialMediaBtn key={'SocialMediaBtn1'+i} link={link} size={this.props.large?26:18} />);
        }
      }
    }
    if(links.length<1) {return (<div />);}
    let setStyle={float: as!.left,padding: '1px 1px 0 2px',height: this.props.large?'28px':'20px'};
    return (<div style={setStyle}>{links}</div>);
  }
  //***********************
}
// ***********************************