// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject,IDropDownItem} from '../../../Types/interfaces';
import DownArrowVector from '../../../Application/SVG/downarrow';
import {styles} from './styles';
// ***********************************
interface IProps extends IStoresInject {
  selectedIndex: number;
  dropDownitems: IDropDownItem[];
  selectionHandler: (id: number) => void;
  dropDownTitle: string;
  borderFrame: boolean;
  flatLeft?: boolean;
  buttonRight?: JSX.Element;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class DropDownList extends React.Component<IProps,{}> {
  //***********************
  @observable private open: boolean;
  @observable private hover: boolean;
  private selectedElement: HTMLDivElement|null;
  private frameElement: HTMLDivElement|null;
  private willOpen: boolean=false;
  private clickBinding: any;
  private keydownBinding: any;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
    this.clickBinding=this.handleGlobalEvent.bind(this);
    this.keydownBinding=this.handleGlobalEvent.bind(this);
  }
  //***********************
  @action private _construct() {
    this.open=false;
    this.hover=false;
  }
  //***********************
  componentDidMount() {
    document.addEventListener('click',this.clickBinding);
    document.addEventListener('keydown',this.keydownBinding);
  }
  //***********************
  componentWillUnmount() {
    document.removeEventListener('click',this.clickBinding);
    document.removeEventListener('keydown',this.keydownBinding);
  }
  //***********************
  @action handleGlobalEvent=(e: React.FormEvent<HTMLElement>) => {
    this.open=this.willOpen||false;
    this.willOpen=false;
  }
  //***********************
  @action handleClick=(e: React.MouseEvent<HTMLDivElement>): void => {
    this.open=!this.open;
    let element=this.selectedElement;
    let container=this.frameElement;
    if(container&&element) {
      if(element.offsetTop<container.scrollTop) {
        container.scrollTop=element.offsetTop;
      } else {
        const offsetBottom=element.offsetTop+(element.offsetHeight+5);
        const scrollBottom=container.scrollTop+container.offsetHeight;
        if(offsetBottom>scrollBottom) {
          container.scrollTop=offsetBottom-container.offsetHeight;
        }
      }
    }
    this.willOpen=this.open;
  }
  //***********************
  @action handleItemClick=(e: React.MouseEvent<HTMLDivElement>) => {
    let id: number=parseInt(e.currentTarget.id.split('.')[0],0);
    this.props.selectionHandler(id);
    this.open=false;
    this.willOpen=false;
  }
  //***********************
  @action handleMouseEnter=(): void => {this.hover=true;};
  @action handleMouseLeave=(): void => {this.hover=false;};
  //***********************
  @action handleMouseEnterItem=(e: React.MouseEvent<HTMLDivElement>): void => {
    const item=e.currentTarget;
    let selected: boolean=item.id.split('.')[1]==='Y';
    item.style.color=selected? styles.ddlItemSelected.color:'#000000';
    item.style.backgroundColor=selected? styles.ddlItemSelected.backgroundColor:'#E0E0E0';
  }
  //***********************
  @action handleMouseLeaveItem=(e: React.MouseEvent<HTMLDivElement>): void => {
    const item=e.currentTarget;
    let selected: boolean=item.id.split('.')[1]==='Y';
    item.style.color=selected? styles.ddlItemSelected.color:'';
    item.style.backgroundColor=selected? styles.ddlItemSelected.backgroundColor:'';
  }
  //***********************
  handleDisabledItemClick() {
    //this.willOpen = true;
  }
  //***********************
  render() {
    const {proto,masterStyles,flatLeft,dropDownTitle,as}=this.props;
    const {uiRoundness}=this.props.siteSettings!;
    const rightElement=this.props.buttonRight? true:false;
    const baseStyle={...masterStyles!.componentBase};
    const labelStyle={fontSize: '.75em'};
    const horzPad: string=((uiRoundness/2)+6).toString()+'px';
    const ddlStyle={
      ...masterStyles!.noSelect,...masterStyles!.inlineMiddle,...styles.ddlStyleBase,
      border: '1px solid '+proto!.colorBorder,backgroundColor: proto!.colorWindow,
      borderTopRightRadius: rightElement? '0':uiRoundness+'px',borderBottomRightRadius: rightElement? '0':uiRoundness+'px',
      borderTopLeftRadius: flatLeft? '0':uiRoundness+'px',borderBottomLeftRadius: flatLeft? '0':uiRoundness+'px',
      padding: '4px '+horzPad+' 5px '+horzPad,height: '30px',boxSizing: as!.borderBox
    };
    const listFrameStyle={
      ...styles.ddlListStyle,...masterStyles!.noSelect,cursor: 'pointer',
      visibility: this.open? as!.visible:as!.hidden,border: '1px solid '+proto!.colorBorder,
    };
    const ddlDisplayTextStyle={...styles.spanStyle,...styles.ddlDisplayTextStyleBase,color: this.hover? proto!.colorHover:proto!.textColorDark};
    const downArrowStyle={...masterStyles!.inlineMiddle,marginBottom: '-7px',paddingLeft: '7px'};

    let list: {}[]=[];
    let items=this.props.dropDownitems;

    for(var i: number=0;i<items.length;i++) {
      const selected=(this.props.selectedIndex===i);
      const enabled=items[i].itemEnabled;
      const itemStyle=enabled? selected? styles.ddlItemSelected:styles.ddlItem:styles.ddlItemDisabled;
      let descStyle={...styles.ddlItemDesc};
      if(selected) {descStyle.color='#E0E0E0';}
      let id: string=items[i].itemValue.toString()+(selected? '.Y':'.N');
      let hasDesc: boolean=items[i].itemDesc.length>0;
      const iHnd=enabled? this.handleItemClick:this.handleDisabledItemClick;
      const iEnt=enabled? this.handleMouseEnterItem:undefined,iLve=enabled? this.handleMouseLeaveItem:undefined;
      list.push(
        <div id={id} style={itemStyle} onMouseEnter={iEnt} onMouseLeave={iLve} onClick={iHnd} key={'DropDownListDiv'+i} ref={(el) => {if(selected) {this.selectedElement=el;} }}>
          <div style={styles.ddlItemName}><span style={styles.spanStyle}>{items[i].itemText}</span></div>
          {hasDesc? <div style={descStyle}><span style={styles.spanStyle}>{items[i].itemDesc}</span></div>:''}
        </div>
      );
    }

    const color=this.hover? proto!.colorHover:proto!.colorControl;
    const ent=this.handleMouseEnter,lve=this.handleMouseLeave,hnd=this.handleClick;
    return (
      <div style={baseStyle}>
        <div style={labelStyle}>{dropDownTitle}</div>
        <div onMouseEnter={ent} onMouseLeave={lve} onClick={hnd} style={ddlStyle}>
          {items[this.props.selectedIndex]? <span style={ddlDisplayTextStyle}>{items[this.props.selectedIndex].itemText}</span>:<span />}
          <div style={downArrowStyle}><DownArrowVector color={color} width="14" height="12" /></div>
        </div>
        <div style={listFrameStyle} ref={(el) => {this.frameElement=el;}}>
          {list}
        </div>
        {rightElement? this.props.buttonRight:''}
      </div>
    );
  }
  //***********************
}
// ***********************************
export function InitDropDownList(index: number,items: IDropDownItem[],handler: (id: number) => void,title: string,frame: boolean=false): IProps {
  let result={
    selectedIndex: index,
    dropDownitems: items,
    selectionHandler: handler,
    dropDownTitle: title,
    borderFrame: frame
  };
  return result;
}
// ***********************************
export function InitDropDownItem(text: string,desc: string,value: number,enabled: boolean): IDropDownItem {
  let result={
    key: value,
    itemText: text,
    itemDesc: desc,
    itemValue: value,
    itemEnabled: enabled
  };
  return result;
}
// ***********************************