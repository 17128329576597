// ***********************************
import * as React from 'react';
//import ReactGA from 'react-ga';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../Types/interfaces';
import NowClockVector from '../../SVG/nowclock';
import { ViewContext } from '../../../Types/enums';
// ***********************************
interface IProps extends IStoresInject { }
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class Now extends React.Component<IProps, {}> {
  //***********************
  @observable hover:boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.hover=false;
  }
  //***********************
  handleNowClick = (): void => {
    const {frame, viewState} = this.props;
    viewState!.setViewMode(ViewContext.Standard, true);
    frame!.now();
    //ReactGA.event({ category: 'User Action', action: 'Clicked Now Button' });
  }
  //***********************
  @action handleNowMouseEnter= ():void =>{
    this.hover=true;
  }
  //***********************
  @action handleNowMouseLeave= ():void =>{
    this.hover=false;
  }
  //***********************
  render() {
    const { siteSettings, proto, masterStyles,as } = this.props;
    let nowCol=this.hover?proto!.colorHover:proto!.textColorDark;
    let nowStyle={
      ...masterStyles!.componentNoMargin,
      whiteSpace: as!.noWrap,
      borderStyle: 'solid solid solid none',
      borderWidth: '1px',
      padding: '6px 4px 6px 4px',
      cursor: 'pointer',
      borderColor:proto!.colorBorder, 
      color:nowCol, 
      backgroundColor: proto!.colorButton,
      borderTopRightRadius: siteSettings!.uiRoundness+'px',
      borderBottomRightRadius: siteSettings!.uiRoundness+'px',
      marginLeft: 0,
      boxSizing: as!.borderBox,
      height: '30px'
    };
    let svgDivStyle={
      ...masterStyles!.inlineMiddle,
      height: '13px',
    };
    let txtDivStyle={
      ...masterStyles!.inlineMiddle,
      fontSize:'1em',
      padding: '0 5px'
    };
    return (
      <div 
        onClick={this.handleNowClick} 
        onMouseEnter={this.handleNowMouseEnter}
        onMouseLeave={this.handleNowMouseLeave}
        style={nowStyle}
      >
        <div style={svgDivStyle}><NowClockVector color={nowCol} width="13" height="13"/></div>
        <div style={txtDivStyle}>Now</div>
      </div>
    );
  }
  //***********************
}
// ***********************************