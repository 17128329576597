import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../Types/interfaces';
import CheckBox from '../../Controls/CheckBox/checkbox';
// ***********************************
interface IProps extends IStoresInject { }
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class Filters extends React.Component<IProps, {}> {
  //***********************
  //***********************
  toggleHD = (): void => {
    const { viewState } = this.props;
    viewState!.setHD(!viewState!.filterHd);
  }
  //***********************
  toggleNew = (): void => {
    const { viewState } = this.props;
    viewState!.setNew(!viewState!.filterNew);
  }
  //***********************  
  render() {
    const { masterStyles, viewState, siteSettings, as } = this.props;
    if(siteSettings!.dynamic){ return ''; }
    let baseStyle = { ...masterStyles!.componentBase, whiteSpace: as!.noWrap, padding: '4px', height: '30px' };
    let labelStyle = { marginBottom: '9px', marginTop: '-4px', marginLeft: '-3px', fontSize: '.75em' };
    return (
      <div style={baseStyle}>
        <div style={labelStyle}>Filters</div>
        <CheckBox label={'New'} checked={viewState!.filterNew} onClick={this.toggleNew} />
      </div>
    );
  }
}