// ***********************************
import {IEvent,IGenreColorSet,IDictionary} from '../Types/interfaces';
// ***********************************
export default class GridColors {
  // ***********************************
  private _colorMapGenre: IDictionary<IGenreColorSet>;
  private _colorMapGenreId: IDictionary<IGenreColorSet>;
  private _colorMapShowType: IDictionary<IGenreColorSet>;
  public get emptyColSet(): IGenreColorSet {return this.getEmptyColorSet();}
  // ***********************************
  constructor(colorSet: IGenreColorSet[]) {
    this._colorMapGenre={};
    this._colorMapGenreId={};
    this._colorMapShowType={};
    for(let i: number=0;i<colorSet.length;i++) {
      let col: IGenreColorSet=colorSet[i];
      col.HoverColor=(col.HoverColor==='*')? col.BackgroundColor:col.HoverColor;
      let lbl='GENRE_'+col.DisplayGenreId.toString();
      this._colorMapGenre[col.DisplayGenre]=col;
      this._colorMapGenreId[lbl]=col;
      this._colorMapShowType[col.ShowType]=col;
    }
  }
  // ***********************************
  public getColorForEvent(show: IEvent): IGenreColorSet {
    if(!show.DisplayGenreId) {return this.getEmptyColorSet();}
    let lbl='GENRE_'+show.DisplayGenreId!.toString();
    if(this._colorMapGenreId[lbl]) {return this._colorMapGenreId[lbl];}
    if(this._colorMapShowType[show.ShowType]) {return this._colorMapShowType[show.ShowType];}
    return this.getEmptyColorSet();
  }
  // ***********************************
  public getColorSplit(genreId: number|undefined,showType: any): IGenreColorSet {
    if(!genreId) {return this.getEmptyColorSet();}
    let lbl='GENRE_'+genreId!.toString();
    if(this._colorMapGenreId[lbl]) {return this._colorMapGenreId[lbl];}
    if(this._colorMapShowType[showType]) {return this._colorMapShowType[showType];}
    return this.getEmptyColorSet();
  }
  // ***********************************
  public getColorForGenre(genre: number): IGenreColorSet {
    let lbl='GENRE_'+genre.toString();
    if(this._colorMapGenreId[lbl]) {return this._colorMapGenreId[lbl];}
    return this.getEmptyColorSet();
  }
  // ***********************************
  private getEmptyColorSet(): IGenreColorSet {
    return {
      DisplayGenreId: 203,
      DisplayGenre: 'Comedy',
      ShowType: '',
      BackgroundColor: '#FFFFFF',
      HoverColor: '#C5E6FF',
      TextColor: '#000000',
      TextHoverColor: '#000'
    };
  }
  // ***********************************

}
// ***********************************