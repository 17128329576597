// ***********************************
import { CellType } from '../Types/enums';
import { ICellParams, IEvent, IEventParams } from '../Types/interfaces';
// ***********************************
export default class RowBuilder {
  //***********************
  private _minDuration: number;
  public get minDuration():number {return this._minDuration;}
  //***********************
  constructor(minDuration: number){
    this._minDuration=minDuration;
  }
  //***********************
  public GetEventParams(cell: ICellParams, item: IEvent, running: boolean, continues: boolean, debug: any): IEventParams {
    if (cell.type === CellType.Event) { cell.type = cell.duration > (3 * this._minDuration) ? CellType.Event : CellType.EventStub; }
    let result = {
      ...cell,
      item: item,
      running: running,
      continues: continues,
      debug: debug
    };
    return result;
  }
  //***********************
  public GetCellParams(type: CellType, duration: number, last: boolean): ICellParams {
    let result = {
      type: type,
      duration: duration,
      last: last
    };
    return result;
  }
  //***********************
}
// ***********************************