// ***********************************
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject, ICellParams, IEventParams } from '../../../../../Types/interfaces';
import { CellType } from '../../../../../Types/enums';
import { MinutesToMilliseconds, Flex } from '../../../../../Scripts/common';
import RowBuilder from '../../../../../Scripts/rowbuilder';
import LoaderSmall from '../../../../Controls/Loaders/LoaderSmall/loadersmall';
import TimeHole from '../../../../Controls/TimeHole/timehole';
import EventItem from '../../../../Controls/Event/event';
import EventStub from '../../../../Controls/Event/EventStub/eventstub';
import AppError from '../../../../AppError/apperror';
import Channel from './Channel/channel';
// ***********************************
interface IProps extends IStoresInject {
  channelIndex: number;
  firstIndex:number;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class Row extends React.Component<IProps, {}> {
  //***********************
  private _rowBuilder: RowBuilder;
  private _index: number = -1;
  // @ts-ignore: variable is never consumed, it is set by render
  // and consumes observable to force re-render on change
  private _renderCount: number = 0;
  //***********************
  get eventKey(): number { return ++this._index; }
  //***********************
  constructor(props:IProps){
    super(props);
    this._rowBuilder= new RowBuilder(5);
  }
  //***********************
  public componentDidUpdate(): void {
    const {channelIndex,firstIndex}=this.props;
    if (channelIndex === firstIndex) { this.props.viewState!.finalizeGenreList(); }
  }
  //***********************
  public render() {
    const { schedule, proto, channelIndex, frame, lineupInfo, viewState, siteSettings } = this.props;
    const rb=this._rowBuilder;
    this._renderCount = schedule!.loadIndex;
    const channel = lineupInfo!.channels[channelIndex];
    const day=channel.Days[0];

    let maxHeight=viewState!.fourKDisplay?proto!.maxGridHeight+40:proto!.maxGridHeight;
    let minHeight=viewState!.fourKDisplay?proto!.minGridHeight+40:proto!.minGridHeight;

    maxHeight=proto!.forceGridMaxHeight?maxHeight:9999;
    minHeight=proto!.forceGridMinHeight?minHeight:0;

    const baseStyle = {
      borderColor: proto!.colorBorder, 
      backgroundColor: proto!.colorWindow,
      display: 'flex', 
      borderStyle: 'solid none none none', 
      borderWidth: '1px',
      maxHeight: maxHeight + 'px',
      minHeight: minHeight + 'px'
    };

    const eventsStyle = { display: 'flex', borderStyle: 'none', flex: '100%' };
    let eventData: any[] = [];
    const frameStart = frame!.start.valueOf();
    const frameEnd = frameStart + MinutesToMilliseconds(frame!.duration);
    let trailAvailable = frame!.duration;
    let lastEventEnd: number | null = null;
    let borrowedTime: number = 0;
    for (let i: number = 0; i < day.Shows.length; i++) {
      let evt = day.Shows[i];
      
      if (evt.endTime <= frameStart) { continue; } // if event ends before the frame starts we don't render it
      if (evt.startTime >= frameEnd) { break; } //if the event starts after the frame ends we don't render it and we're done with the row

      let timeHole: number = 0;  // if there is a space between events, we need to fill it.  the timehole will be the "width"
      let leadAvail: number = 0;

      if (lastEventEnd) { 
        if (evt.startTime !== lastEventEnd) { 
          timeHole += (lastEventEnd - evt.startTime) / 60000; 
        } 
      }
      else { 
        if (evt.startTime > frameStart) { 
          leadAvail += (evt.startTime - frameStart) / 60000; 
        }
      }

      // TimeHole is a special "empty event" cell
      if (timeHole > 0) { eventData.push(rb.GetCellParams(CellType.TimeHole, timeHole, false)); }
      if (leadAvail > 0) {
        eventData.push(rb.GetCellParams(CellType.Loader, leadAvail, false));
        schedule!.loadFrame();
      }

      let colSet=siteSettings!.colors.getColorForEvent(evt);
      viewState!.addGenreList(colSet);

      let running = evt.startTime < frameStart; // event started previous to the frame
      let continues = evt.endTime > frameEnd; // event continues beyond the frame

      let durationUsed = (running ? evt.DurationMinutes - ((frameStart - evt.startTime) / 60000) : evt.DurationMinutes) - borrowedTime;
      borrowedTime = 0;
      let minDur: number = lastEventEnd ? 5 : 10;
      if (durationUsed < minDur) {
        borrowedTime = minDur - durationUsed;
        durationUsed = minDur;
      }
      durationUsed = Math.min(durationUsed, trailAvailable - timeHole);

      trailAvailable -= (durationUsed + leadAvail);

      let cp = rb.GetCellParams(CellType.Event, durationUsed, trailAvailable === 0);
      eventData.push(rb.GetEventParams(cp, evt, running, continues, durationUsed));

      //set the next events reference to this event
      lastEventEnd = evt.endTime;
    }
    if (trailAvailable > 0) {
      eventData.push(rb.GetCellParams(CellType.Loader, trailAvailable, true));
      schedule!.loadFrame();
    }
    return (
      <div style={baseStyle}>
        <Channel channel={channel} index={this.props.channelIndex} />
        <div style={eventsStyle}>
          {this.assembleRenderedRow(eventData, frame!.duration, channelIndex)}
        </div>
      </div>
    );
  }
  // ***********************************
  private assembleRenderedRow(items: any[], totalDuration: number, channelIndex: number): JSX.Element[] {
    const rb=this._rowBuilder;
    let component: string = 'Views.Standard.Body.Row';
    let result: JSX.Element[] = [];
    let deficit: number = 0;
    for (let i = items.length - 1; i >= 0; i--) {
      let min= (i===items.length-1)?2*rb.minDuration:rb.minDuration;
      if (deficit) {
        if (items[i].duration > min) {
          items[i].duration -= deficit;
          deficit = 0;
        }
        else {
          deficit -= items[i].duration;
          items[i].duration = 0;
        }
      }
      if (items[i].duration < min) {
        deficit += (min - items[i].duration);
        items[i].duration = min;
      }
    }
    for (let i = 0; i < items.length; i++) {
      let item: ICellParams = items[i];
      let flex = Flex(item.duration, totalDuration);
      switch (item.type) {
        case CellType.Event: {
          let evt: IEventParams = items[i];
          result.push(<EventItem key={'StandardEventItem'+evt.item.startTime} channelIndex={channelIndex} item={evt.item} flex={flex} last={evt.last} running={evt.running} continues={evt.continues} debug={item.duration} />);
          break;
        }
        case CellType.EventStub: {
          let evt: IEventParams = items[i];
          result.push(<EventStub key={'StandardEventItem'+evt.item.startTime} channelIndex={channelIndex} item={evt.item} flex={flex} last={evt.last} running={evt.running} continues={evt.continues} debug={item.duration} />);
          break;
        }
        case CellType.Loader:
          result.push(<LoaderSmall key={'StandardLoaderSmall'+i} flex={flex} last={item.last} />);
          break;
        case CellType.TimeHole:
          result.push(<TimeHole key={'StandardTimeHole'+i} flex={flex} last={item.last} />);
          break;
        default:
          let msg: string = 'unknown error';
          result.push(<AppError message={msg} caller={component} />);
          break;
      }
    }
    return result;
  }
  // ***********************************
}