// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import DayNav from '../../../Controls/DayNav/daynav';
import DailyBar from './DailyBar/dailybar';
import {AutoTextColor} from '../../../../Scripts/common';
// ***********************************
interface IProps extends IStoresInject {listener?: boolean;}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class ScheduleNav extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {siteSettings,proto,masterStyles}=this.props;
    let border: boolean=siteSettings!.enableScrollingBody;
    let schedStyle={
      ...masterStyles!.schedNav,
      borderStyle: `${border?'none':'solid'} none none`,
      fontSize: '16px',
      backgroundColor: proto!.colorButton,
      backgroundBlendMode: 'luminosity',
      borderColor: proto!.colorBorder,
      color: AutoTextColor(proto!.colorControlLight,proto!.textColorDark,proto!.textColorLight),
      filter: 'none'
    };
    return (
      <div style={schedStyle} >
        <DayNav listener={this.props.listener? this.props.listener:false} />
        <DailyBar />
      </div>
    );
  }
  //***********************
}
// ***********************************
