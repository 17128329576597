// ***********************************
import {action,observable,computed} from 'mobx';
import {IStores,IProgramEventDetails} from '../Types/interfaces';
import {PadStart} from '../Scripts/common';
// ***********************************
export default class Details {
  //***********************
  private _parent: IStores;
  private _program: IProgramEventDetails;
  private _programId: number;
  private _eventId: number;
  private _displayChannel: string;
  private _displayTime: string;
  @observable private _initialized: boolean;
  @observable private _ready: boolean;
  public get program(): IProgramEventDetails {return this._program;}
  public get programId(): number {return this._programId;}
  public get eventId(): number {return this._eventId;}
  public get displayChannel(): string {return this._displayChannel;}
  public get displayTime(): string {return this._displayTime;}
  @computed public get initialized(): boolean {return this._initialized;}
  @computed public get ready(): boolean {return this._ready;}
  public get seasonEpisodeShort(): string {return this.getSeasonEpisode(false);}
  public get seasonEpisode(): string {return this.getSeasonEpisode(true);}
  //***********************
  constructor() {
    this._construct();
  }
  //***********************
  @action private _construct() {
    this._initialized=false;
  }
  //***********************
  @action public initialize(parent: IStores): void {
    this._parent=parent;
    this._initialized=true;
  }
  //***********************
  private getSeasonEpisode(longFormat:boolean): string {
    if(this._program.SeasonNumber===0||this._program.SeasonEpisodeNumber===0) {return '';}
    let result:string='';
    if(longFormat){
      result='Season '+this._program.SeasonNumber.toString();
      result+=' • Episode '+this._program.SeasonEpisodeNumber.toString();
    }
    else {
      result='S'+PadStart(this._program.SeasonNumber.toString(),'0',2);
      result+='E'+PadStart(this._program.SeasonEpisodeNumber.toString(),'0',2);
    }
    return result;
  }
  //***********************
  @action public getEvent(eventId: number,programId: number,channelIndex: number,channel: string,time: string): void {
    if(this._eventId===eventId) {return;}
    this._eventId=eventId;
    this._programId=programId;
    this._displayChannel=channel;
    this._displayTime=time;
    this._ready=false;
    this._program={} as IProgramEventDetails;
    const debug=this._parent.viewState.debug;
    let fetchUrl=this._parent.frame.fetchUrlGet(this._parent.siteSettings.siteId,this._parent.lineupInfo.currentLineup.LineupId,null,channelIndex).urls[0] as string;
    this._parent.fetcher.fetchConfirm<IProgramEventDetails>(fetchUrl,this.processEventDetails,debug);
  }
  //***********************
  @action processEventDetails=(data: IProgramEventDetails,confirm: boolean): void => {
    const {viewState}= this._parent;
    if(confirm) {
      this._program=data;
      let min: number=data.DurationMinutes%60;
      let hours: number=(data.DurationMinutes-min)/60;
      this._program.durationDisplay=(hours>0? hours.toString()+'hr ':'')+(min>0? (PadStart(min.toString(),'0',2)+'min'):'');
      this._program.Flags.AD=false;
      this._program.AudioStreams.forEach(as => {
        if(as.AudioServiceType==='visually_impaired'){ this._program.Flags.AD=true; }
      });
    }
    else {
      this._program={} as IProgramEventDetails;
      this._eventId=-1;
      this._programId=-1;
    }
    this._ready=true;
    viewState.invalidateBanners();
  }
  //***********************
}
// ***********************************