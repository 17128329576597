// ***********************************
import * as React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../Types/interfaces';
import PrintVector from '../../SVG/print';
// ***********************************
interface IProps extends IStoresInject { }
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class Print extends React.Component<IProps, {}> {
  //***********************
  @observable hover:boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.hover=false;
  }
  //***********************
  @action handleNowMouseEnter= ():void =>{ this.hover=true; };
  @action handleNowMouseLeave= ():void =>{ this.hover=false; };
  handleNowClick = (): void => { window.print(); };
  //***********************
  render() {
    const { siteSettings, proto, masterStyles, as } = this.props;
    const printActive=false;

    if(!proto!.protoTypeMode||!printActive){return('');}
    let nowCol=this.hover?proto!.colorHover:proto!.textColorDark;
    let nowStyle={
      ...masterStyles!.componentBase,
      whiteSpace: as!.noWrap,
      borderStyle: 'solid',
      borderWidth: '1px',
      padding: '5px 4px 6px 4px',
      cursor: 'pointer',
      borderColor:proto!.colorBorder, 
      color:nowCol, 
      backgroundColor: proto!.colorButton,
      borderRadius: siteSettings!.uiRoundness+'px',
      marginLeft: '8px'
    };
    let svgDivStyle={
      ...masterStyles!.noSelect,
      ...masterStyles!.inlineMiddle,
      height: '13px',
      margin: '-1px 2px 0 1px'
    };
    let txtDivStyle={
      ...masterStyles!.noSelect,
      ...masterStyles!.inlineMiddle,
      margin:'1px 3px 1px 3px',
      fontSize:'12px'
    };
    let printCol=this.hover?proto!.colorHover:'#E0E0E0';
    return (
      <div 
        onClick={this.handleNowClick} 
        onMouseEnter={this.handleNowMouseEnter}
        onMouseLeave={this.handleNowMouseLeave}
        style={nowStyle}
      >
        <div style={svgDivStyle}><PrintVector color={printCol} width="16" height="16"/></div>
        <div style={txtDivStyle}>Print</div>
      </div>
    );
  }
  //***********************
}
// ***********************************