import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
  fill?: string;
}
// ***********************************
export default class TwitterVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.size,
      width: this.props.size,
    };
    if (this.props.fill) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 310 310">
          <path fill={this.props.fill} d="M302.973 57.388c-4.87 2.16-9.877 3.983-14.993 5.463 6.057-6.85 10.675-14.91 13.494-23.73.632-1.977-.023-4.141-1.648-5.434-1.623-1.294-3.878-1.449-5.665-.39-10.865 6.444-22.587 11.075-34.878 13.783-12.381-12.098-29.197-18.983-46.581-18.983-36.695 0-66.549 29.853-66.549 66.547 0 2.89.183 5.764.545 8.598C101.163 99.244 58.83 76.863 29.76 41.204c-1.036-1.271-2.632-1.956-4.266-1.825-1.635.128-3.104 1.05-3.93 2.467-5.896 10.117-9.013 21.688-9.013 33.461 0 16.035 5.725 31.249 15.838 43.137-3.075-1.065-6.059-2.396-8.907-3.977-1.529-.851-3.395-.838-4.914.033-1.52.871-2.473 2.473-2.513 4.224-.007.295-.007.59-.007.889 0 23.935 12.882 45.484 32.577 57.229-1.692-.169-3.383-.414-5.063-.735-1.732-.331-3.513.276-4.681 1.597-1.17 1.32-1.557 3.16-1.018 4.84 7.29 22.76 26.059 39.501 48.749 44.605-18.819 11.787-40.34 17.961-62.932 17.961-4.714 0-9.455-.277-14.095-.826-2.305-.274-4.509 1.087-5.294 3.279-.785 2.193.047 4.638 2.008 5.895 29.023 18.609 62.582 28.445 97.047 28.445 67.754 0 110.139-31.95 133.764-58.753 29.46-33.421 46.356-77.658 46.356-121.367 0-1.826-.028-3.67-.084-5.508 11.623-8.757 21.63-19.355 29.773-31.536 1.237-1.85 1.103-4.295-.33-5.998-1.431-1.704-3.816-2.255-5.852-1.353z"/>
        </svg>
      );
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 456 456" >
        <path fill="#50ABF1" d="M0 0h455.731v455.731H0z"/>
        <path fill="#FFF" d="M60.377 337.822c30.33 19.236 66.308 30.368 104.875 30.368 108.349 0 196.18-87.841 196.18-196.18 0-2.705-.057-5.39-.161-8.067 3.919-3.084 28.157-22.511 34.098-35 0 0-19.683 8.18-38.947 10.107-.038 0-.085.009-.123.009 0 0 .038-.019.104-.066 1.775-1.186 26.591-18.079 29.951-38.207 0 0-13.922 7.431-33.415 13.932-3.227 1.072-6.605 2.126-10.088 3.103-12.565-13.41-30.425-21.78-50.25-21.78-38.027 0-68.841 30.805-68.841 68.803 0 5.362.617 10.581 1.784 15.592-5.314-.218-86.237-4.755-141.289-71.423 0 0-32.902 44.917 19.607 91.105 0 0-15.962-.636-29.733-8.864 0 0-5.058 54.416 54.407 68.329 0 0-11.701 4.432-30.368 1.272 0 0 10.439 43.968 63.271 48.077 0 0-41.777 37.74-101.081 28.885l.019.005z"/>
      </svg>
    );
  }
  //***********************
}