// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../Types/interfaces';
import {observable, action} from 'mobx';
// ***********************************
interface IProps extends IStoresInject {
  scrollUp: boolean;
  click: () => void;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class ShadowRow extends React.Component<IProps,{}> {
  //***********************
  private _index: number=-1;
  @observable private _hover: boolean;
  // @ts-ignore: variable is never consumed, it is set by render
  // and consumes observable to force re-render on change
  private _renderCount: number=0;
  //***********************
  get eventKey(): number {return ++this._index;}
  //***********************
  @action private mouseEnter = () => { this._hover=true; }    
  @action private mouseLeave = () => { this._hover=false; }
  //***********************
  public render() {
    const {schedule,proto,as,masterStyles}=this.props;
    this._renderCount=schedule!.loadIndex;

    let baseStyle={
      ...masterStyles!.flexCenter,
      position:as!.relative,
      borderColor: proto!.colorBorder,
      color: this._hover?'white':'black',
      fontWeight:this._hover?as!.bold:as!.normal, 
      display: 'flex',
      borderStyle: 'solid none none none',
      borderWidth: '1px',
      height: '15px',
      cursor:this._hover?'pointer':'',
      fontFamily: 'Verdana',
      padding:'2px 0'
    };
    let hoverStyle={...baseStyle, backgroundColor:proto!.colorHover};
    let noHoverStyle={...baseStyle, background: `linear-gradient(${this.props.scrollUp?'360deg':'180deg'},${proto!.colorWindow} 25%,#1E90FF)`,};

    let finalstyle=this._hover?hoverStyle:noHoverStyle;

    return (<div style={finalstyle} onClick={this.props.click} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>More {this.props.scrollUp?'above this':'below this'}</div>);
  }
   // ***********************************
}