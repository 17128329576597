import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
}
// ***********************************
export default class CCVector extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {size}=this.props;
    let style={
      height: size,
      width: size,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={size} width={size} viewBox="0 0 19.584 19.584">
        <path d="M0-.000864h19.584v19.584H0z" />
        <path fill="#fff" d="M18.02946 9.791619c.001652-3.1632341-.12735-4.8263589-1.290024-5.9344982-1.204849-1.11455-5.980415-1.0751698-6.9462794-1.0989811-.9873639.0238113-5.7629305-.0164847-6.9462786 1.0989811C1.6635297 4.9661759 1.5337005 6.6283849 1.5568548 9.791619c-.023155 3.166897.1066749 4.829106 1.2900232 5.933582 1.1833481 1.120045 5.9589147 1.079749 6.9462786 1.098981.9658644-.01923 5.7414304.02015 6.9462794-1.098981 1.162674-1.10356 1.291676-2.765769 1.290024-5.933582z" />
        <path d="M6.0451629 14.149037c1.5891197.02254 2.9095515-.957606 3.1552324-3.406304H7.2283751c-.1503666 1.045268-.6458367 1.479405-1.1823905 1.502781-.6860987-.022542-1.2966034-.716326-1.2826349-2.5046352-.0147898-1.7916493.5965362-2.3401645 1.2826349-2.3543574.5529873.0150278 1.0607825.3773651 1.2325126 1.4526886h1.9227197c-.2711527-2.3902571-1.5817245-3.3879369-3.1552323-3.4063042-1.7715316.0183673-3.2053546 1.2740245-3.204533 4.358065-.0024708 3.1249512 1.4313581 4.3806082 3.2037113 4.3580662zm7.5421561 0c1.595693.02254 2.916125-.957606 3.155233-3.406304h-1.972021c-.143793 1.045268-.639262 1.479405-1.183212 1.502781-.679525-.02254-1.29003-.716326-1.232512-2.5046352-.05752-1.7916493.552987-2.3401645 1.232512-2.3543574.55956.015028 1.067356.3773651 1.232513 1.4526886h1.92272c-.26458-2.3902571-1.575152-3.3879369-3.155233-3.4063042-1.764958.018367-3.199603 1.2740245-3.204532 4.358065.0057 3.1249512 1.439574 4.3806082 3.204532 4.3580662z" />
      </svg>
    );
  }
}