// ***********************************
import * as React from 'react';
import { inject } from 'mobx-react';
import { IStores, IStoresInject } from '../../../Types/interfaces';
// ***********************************
interface IProps extends IStoresInject {
  flex:number;
  last: boolean;
  debug?:any;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
export default class TimeHole extends React.Component<IProps, {}> {
  //***********************
  debug():{}{
    return(
      <div>{'Debug: '+this.props.debug}</div>
    );
  }
  //***********************
  render() {
    const { flex, last, proto,as } = this.props;
    let borderStyle={ 
      borderColor:proto!.colorBorder, 
      borderWidth:'1px' 
    };
    let emptyStyle={ 
      ...borderStyle,
      borderRightStyle:last?as!.none:as!.solid,
      flex: flex.toString()+'%', display: 'flex', width: '100%', 
      backgroundColor:proto!.colorBorder 
    };
    return (
      <div style={emptyStyle}>
        {this.props.debug?this.debug():''}
      </div>
    );
  }
  //***********************
}