// ***********************************
//import ReactGA from 'react-ga';
import {action,observable,computed} from 'mobx';
import {IStores,ISearchResult} from '../Types/interfaces';
import TimeLine from '../Scripts/timeline';
import {ISearchDisplayEvent} from '../Types/interfaces';
// ***********************************
export default class Search {
  //***********************
  private _parent: IStores;
  private _events: ISearchDisplayEvent[];
  private _stateProfile: string;
  @observable private _searchText: string;
  @observable private _initialized: boolean;
  @observable private _ready: boolean;
  public get events(): ISearchDisplayEvent[] {return this._events;}
  @computed public get initialized(): boolean {return this._initialized;}
  @computed public get ready(): boolean {return this._ready;}
  @computed public get searchText(): string {return this._searchText;}
  //***********************
  constructor() {
    this._construct();
  }
  //***********************
  @action private _construct() {
    this._initialized=false;
  }
  //***********************
  @action public initialize(parent: IStores): void {
    this._parent=parent;
    this._initialized=true;
  }
  //***********************
  @action public runSearch(): void {
    const {viewState}=this._parent;
    //ReactGA.event({ category: 'User Action', action: 'Initiated Search', label: viewState!.searchValue});
    this._searchText=viewState!.searchValue;
    let newStateProfile=this.getStateProfile();
    if(newStateProfile===this._stateProfile) { return; }
    this._ready=false;
    this._events=[];
    this._stateProfile=newStateProfile;
    const debug=this._parent.viewState.debug;
    let fetchPost=this._parent.frame.fetchUrlPost(this._parent.siteSettings.siteId,this._parent.lineupInfo.currentLineup.LineupId);
    this._parent.fetcher.fetchPost<ISearchResult>(fetchPost,this.processSearchResults,debug);
  }
  //***********************
  @action processSearchResults=(data: ISearchResult): void => {
    data.Shows.forEach(show => {
      let event={} as ISearchDisplayEvent;
      event.EventId=show.EventId;
      event.ProgramId=show.ProgramId;
      event.Category=show.Category.length>0? show.Category[0].Text:'';
      event.Rating=show.Rating;
      event.Links=show.Links;
      event.Flags=show.Flags;
      event.Title=show.Title.length>0? show.Title[0].Text:'';
      event.EpisodeTitle=show.EpisodeTitle.length>0? show.EpisodeTitle[0].Text:'';
      event.Description=show.Description.length>0? show.Description[0].Text:'';
      event.ImageUri=show.ShowCardUri;
      event.EventDate=new TimeLine(show.StartTime).toLongString();
      event.StartTime=new TimeLine(show.StartTime).toTimeString();
      event.EndTime=new TimeLine(show.EndTime).toTimeString();
      event.ChannelName=this._parent.lineupInfo.getChannelName(show.ChannelIndex!);
      event.ChannelNumber=this._parent.lineupInfo.getChannelNumber(show.ChannelIndex!);
      event.ChannelIndex=show.ChannelIndex;
      this._events.push(event);
    });
    this._ready=true;
  }
  //***********************
  private getStateProfile(): string {
    const {lineupInfo,viewState}=this._parent;
    let result: string;
    result=this.searchText;
    result+=lineupInfo!.currentLineup.LineupId;
    if(result!==this._stateProfile) {viewState.invalidateBanners();}
    return result;
  }
  //***********************
}
// ***********************************