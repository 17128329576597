// ***********************************
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../../../../Types/interfaces';
// ***********************************
interface IProps extends IStoresInject { }
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class ExtendedBar extends React.Component<IProps, {}> {
  //***********************
  render() {
    const { viewState, proto, masterStyles } = this.props;
    let wid = viewState!.colWidthDate;
    const style = {
      ...masterStyles!.flexCenterInline, minHeight: '24px', minWidth: `${wid}px`, maxWidth: `${wid}px`,
      borderColor: proto!.colorBorder, borderWidth: '1px', borderStyle: 'none solid none none'
    };
    return (
      <div style={style}>
        Date
      </div>
    );
  }
  //***********************
}
// ***********************************
