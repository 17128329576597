// ***********************************
import * as React from 'react';
import {observable,action,computed} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject,IProgramImage} from '../../../../../../../Types/interfaces';
import RibbonItem from './RibbonItem/ribbonitem';
import RibbonButton from './RibbonButton/ribbonbutton';
// ***********************************
interface IProps extends IStoresInject {
  width: number,
  killSwitch: () => void
  setPages: (pages: JSX.Element[]) => void
}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Ribbon extends React.Component<IProps,{}> {
  //***********************
  private _timeoutId: number=-1;
  @observable private _programImagePages: IProgramImage[][];
  @observable private _hover: boolean;
  @observable private _ribbonLeft: number;
  private _thumbSize=120;
  private _ribbonWidth: number;
  private _ribbonIndex: number;
  @observable private _pageIndex: number;
  @observable private _pageIndexFloat: number;
  private _pageMaxLength: number;
  private _subTypeDefines: string[]=['SRBA','CRCG','SNBA','PREP','PRKA','',''];
  @computed private get programImages(): IProgramImage[] {return this._programImagePages[this._pageIndex];}
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action handleMouseEnter=(): void => {this._hover=true;}
  //***********************
  @action handleMouseLeave=(): void => {this._hover=false;}
  //***********************
  @action private _construct(): void {
    this._ribbonIndex=0;
    this._pageIndex=0;
    this._ribbonLeft=0;
    this._pageIndexFloat=0;
    this._pageMaxLength=20;
    const {siteSettings}=this.props;
    if(!this._programImagePages&&siteSettings!.detailFields.ProgramImage) {
      this._programImagePages=[];
      this._programImagePages.push([]);
      const {session,details}=this.props;
      session!.subscribeProgramImages(details!.programId,this.setProgramImages);
    }
  }
  //***********************
  @action private setProgramImages=(images: IProgramImage[]): void => {
    const {killSwitch}=this.props;
    let includedImages: IProgramImage[]=[];
    let includedImagePages: IProgramImage[][]=[];
    for(let i=0;i<images.length;i++) {
      let image=images[i];
      if(this.filter(image.SubTypeCode)) {includedImages.push(image);}
      if(includedImages.length===this._pageMaxLength||i+1===images.length) {
        if(includedImages.length>0){includedImagePages.push(includedImages);}
        includedImages=[];
      }
    }
    if(includedImagePages.length===0) {killSwitch();}
    this._programImagePages=includedImagePages;

    this.setPages(0);
  }
  private setPages(count:number): void {
    let pageIndex=this._pageIndex+count;

    const {details,setPages}=this.props;
    let pages: JSX.Element[]=[];
    let prog=details!.program;
    let baseStyle={padding: '0 5px',fontSize: '1em'}
    let spaceStyle={...baseStyle,fontWeight: 900};
    let curStyle={...baseStyle,fontWeight: 900, color:'white', backgroundColor:'black'};
    let lnkStyle={...baseStyle,fontWeight: 400};
    let disabledStyle={...baseStyle,color: '#cccccc',fontWeight: 400}

    if(this._programImagePages.length>0) {
      let disabled=pageIndex===0;
      pages.push(
        <span
          key={`${pageIndex}-back-${prog.EventId}`}
          style={disabled? {...disabledStyle}:{...lnkStyle}}
          onClick={disabled? undefined:this.clickBack}
          className={disabled? '':'baseLink'}
        >
          Back
        </span>
      );
      pages.push(<span style={spaceStyle}> • </span>)
    }

    for(let i=0;i<this._programImagePages.length;i++) {
      let selected=(i===pageIndex);
      pages.push(
        <span
          key={`${pageIndex}-${i}-${prog.EventId}`}
          style={selected? {...curStyle}:{...lnkStyle}}
          onClick={() => this.clickPage(i+1)}
          className={selected? '':'baseLink'}
        >
          {i+1}
        </span>
      );
    }

    if(this._programImagePages.length>0) {
      pages.push(<span style={spaceStyle}> • </span>)
      let disabled=(this._programImagePages.length===pageIndex+1);
      pages.push(
        <span
          key={`${pageIndex}-next-${prog.EventId}`}
          style={disabled? {...disabledStyle}:{...lnkStyle}}
          onClick={disabled? undefined:this.clickNext}
          className={disabled? '':'baseLink'}
        >
          Next
        </span>
      );
    }
    setPages(pages);
  }
  //***********************
  filter(sample: string): boolean {
    if(this._subTypeDefines.find(val => (val===sample))) {return false;}
    return true;
  }
  //***********************
  @action private clickLeft=(): void => {
    if(this._ribbonIndex>0) {
      let newRibbonLeft=0-(this._thumbSize*this._ribbonIndex);
      let difference=Math.abs(newRibbonLeft-this._ribbonLeft);
      if(difference<=this._thumbSize) {this._ribbonIndex-=1;}
    }
    this.transitionRibbon();
  }
  //***********************
  @action private clickPage(index: number): void {
    this._pageIndex=index-1;
    this._ribbonIndex=0;
    this._ribbonLeft=0;
    this.setPages(0);
  }
  //***********************
  @action private clickNext=(): void => {
    if((this._pageIndex+this._pageIndexFloat+1)===this._programImagePages.length) {return;}
    this._pageIndexFloat++;
    this.setPages(this._pageIndexFloat);
    if(this._timeoutId>-1) {window.clearTimeout(this._timeoutId);}
    this._timeoutId=window.setTimeout(this.processTimeout,300);
  }
  //***********************
  @action private clickBack=(): void => {
    if(this._pageIndex+this._pageIndexFloat===0) {return;}
    this._pageIndexFloat--;
    this.setPages(this._pageIndexFloat);
    if(this._timeoutId>-1) {window.clearTimeout(this._timeoutId);}
    this._timeoutId=window.setTimeout(this.processTimeout,300);
  }
  //***********************
  @action private processTimeout=():void =>{
    this._pageIndex+=this._pageIndexFloat;
    this._ribbonIndex=0;
    this._ribbonLeft=0;
    this._timeoutId=-1;
    this._pageIndexFloat=0;
  }
  //***********************
  @action private clickRight=(): void => {
    let {width}=this.props;
    if(this._ribbonWidth-(this._ribbonIndex*this._thumbSize)>width) {
      let newRibbonLeft=0-(this._thumbSize*this._ribbonIndex);
      let difference=Math.abs(newRibbonLeft-this._ribbonLeft);
      if(difference<=this._thumbSize) {this._ribbonIndex+=1;}
    }
    this.transitionRibbon();
  }
  //***********************
  @action transitionRibbon=() => {
    let newRibbonLeft=0-(this._thumbSize*this._ribbonIndex);
    if(this._ribbonLeft===newRibbonLeft) {return;}
    if(this._ribbonLeft<newRibbonLeft) {this._ribbonLeft+=1}
    if(this._ribbonLeft>newRibbonLeft) {this._ribbonLeft-=1}
    if(this._ribbonLeft!==newRibbonLeft) {window.setTimeout(this.transitionRibbon,2);}
  }
  //***********************
  render() {
    const {width,as}=this.props;
    const {programImages}=this;

    this._ribbonWidth=0;
    let ribbonImages: JSX.Element[]=[];
    for(let i=0;programImages&&i<programImages.length;i++) {
      let image=programImages[i];
      ribbonImages.push(<RibbonItem key={image.Uri} thumbSize={this._thumbSize} image={image} />);
      this._ribbonWidth+=this._thumbSize;
    }

    const ownerStyle={border: '0px solid red',width: `${width}px`,height: `${this._thumbSize+8}px`,whiteSpace: as!.noWrap,overflow: as!.hidden};
    const ribbonStyle={
      margin: '0 -25px',
      position: as!.relative,left: this._ribbonLeft,maxWidth: `${width}px`,zIndex: 999,
      height: `${this._thumbSize}px`,display: 'inline-block',whiteSpace: as!.noWrap
    };

    let enableL=this._ribbonIndex>0;
    let enableR=this._ribbonWidth-(this._ribbonIndex*this._thumbSize)>width;

    return (
      <div style={ownerStyle} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} >
        <RibbonButton enabled={enableL} offset={'left'} thumbSize={this._thumbSize} hover={this._hover} click={this.clickLeft} />
        <div style={ribbonStyle}>
          {ribbonImages}
        </div>
        <RibbonButton enabled={enableR} offset={'right'} thumbSize={this._thumbSize} hover={this._hover} click={this.clickRight} />
      </div>
    );
  }
  //***********************
}
// ***********************************