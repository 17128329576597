import * as React from 'react';
// ***********************************
interface IProps {
  width: number;
  rating: number;
}
// ***********************************
export default class StarRatingVector extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {rating,width}=this.props;
    let height: number=width*(92.272003/512);
    let colStr='#FFD700';
    let noColStr='#CCCCCC';
    let fill1: string=(rating>=.5)? colStr:noColStr;
    let fill2: string=(rating>=1.5)? colStr:noColStr;
    let fill3: string=(rating>=2.5)? colStr:noColStr;
    let fill4: string=(rating>=3.5)? colStr:noColStr;
    let fill5: string=(rating>=4.5)? colStr:noColStr;
    let style={height: height,width: width};
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={height} width={width} viewBox="0 0 512 92.272003">
        <path fill={fill1} d="M48.512 70.472l29.968 21.8-11.432-35.256L97.016 35.24H59.968L48.512 0 37.056 35.24H0l29.984 21.776-11.456 35.256z" />
        <path fill={fill2} d="M140.8 35.24h-37.056l29.984 21.776-11.456 35.256 29.984-21.8 29.984 21.8-11.456-35.256 29.992-21.776h-37.064L152.256 0z" />
        <path fill={fill3} d="M244.544 35.24h-37.056l29.984 21.776-11.456 35.256L256 70.472l29.984 21.8-11.456-35.256 29.984-21.776h-37.056L256 0z" />
        <path fill={fill4} d="M348.288 35.24h-37.064l29.992 21.776-11.456 35.256 29.984-21.8 29.984 21.8-11.456-35.256 29.984-21.776H371.2L359.744 0z" />
        <path fill={fill5} d="M452.032 35.24h-37.048l29.968 21.776-11.456 35.256 29.992-21.8 29.984 21.8-11.456-35.256L512 35.24h-37.056L463.488 0z" />
      </svg>
    );
  }
  //***********************
}
// ***********************************