// ***********************************
import * as React from 'react';
import {action,observable} from 'mobx';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import DailyVector from '../../../SVG/daily';
import ExtendedVector from '../../../SVG/extended';
import DefaultVector from '../../../SVG/default';
import PreviousVector from '../../../SVG/previous';
import CloseVector from '../../../SVG/close';
import {ViewContext} from '../../../../Types/enums';
// ***********************************
interface IProps extends IStoresInject {
  title: string;
  viewContext: ViewContext;
  color?: string;
  svgColor?: string;
  handleClick(viewContext: ViewContext): void;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class NavButton extends React.Component<IProps,{}> {
  //***********************
  @observable hover: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.hover=false;
  }
  //***********************
  @action mouseEnter=(): void => {this.hover=true;};
  @action mouseLeave=(): void => {this.hover=false;};
  //***********************
  handleClick=(): void => { this.props.handleClick(this.props.viewContext); }
  //***********************
  render() {
    const title=this.props.title;
    const {siteSettings,proto,masterStyles}=this.props;

    let btnStyle={
      ...masterStyles!.btn,
      ...masterStyles!.flexCenterInline,
      borderColor: proto!.colorBorder,
      marginRight: '2px',
      backgroundColor: this.props.color? this.props.color:proto!.colorButton,
      borderRadius: siteSettings!.uiRoundness+'px',
    };
    let hoverColor=this.hover? proto!.colorHover:this.props.svgColor? this.props.svgColor:proto!.colorControl;
    return (
      <div title={title} style={btnStyle} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} onClick={this.handleClick} >{getButtonStyle(this.props.viewContext,hoverColor)}</div>
    );
  }
  //***********************
}
// ***********************************
function getButtonStyle(viewContext: ViewContext,hoverColor: string): any {
  switch(viewContext) {
    case ViewContext.Daily: return <DailyVector color={hoverColor} size="22" />;
    case ViewContext.Extended: return <ExtendedVector color={hoverColor} size="22" />;
    case ViewContext.Back: return <PreviousVector color={hoverColor} size="22" />;
    case ViewContext.Close: return <CloseVector color={hoverColor} size="14" />;
    default: case ViewContext.Standard: return <DefaultVector color={hoverColor} size="22" />;
  }
}
// ***********************************