// ***********************************
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../../../Types/interfaces';
import Banner from '../../../../Controls/Banner/banner';
// ***********************************
interface IProps extends IStoresInject {
  borderTop: boolean;
  index: number;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class InGridAd extends React.Component<IProps, {}>{
  //***********************
  render() {
    //const { proto, masterStyles, borderTop, as } = this.props;
    let style = {
      // ...masterStyles!.noSelect, 
      // ...masterStyles!.flexCenter,
      // borderColor: proto!.colorBorder, 
      // borderWidth: '1px', 
      // borderTopStyle: borderTop?as!.solid:as!.none,
      // padding: '5px 0',
      // textAlign: as!.center
    };
    return (
      <div style={style}>
        <Banner dynamic={true} adIndex={this.props.index} placement={'in-grid'} pageIndex={0}/>
      </div>
    );
  }
  //***********************
}