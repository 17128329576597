import './index.css';
// ***********************************
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import App from './Application/app';
import Init from './Stores/init';
import {IStores,ILoadParams} from './Types/interfaces';
import * as Common from './Scripts/common';
// ***********************************
import Promise from 'promise-polyfill';
//import 'whatwg-fetch';
if(!(window as any).Promise) {(window as any).Promise=Promise;}

// ***********************************
configure({enforceActions: 'always'});

let embed=Common.GetUrlParameter(window.location.search,'embed');

if(embed==='nexstar') {
  var head=document.getElementsByTagName('head')[0];
  var script=document.createElement('script');
  script.type='text/javascript';
  script.src='https://media.nexstardigitalmedia.com/nxsglobal/global/js/iframeResizer/iframeResizer.contentWindow.js';
  head.appendChild(script);
}

let token=Common.GetUrlParameter(window.location.search,'token');

let params: ILoadParams={
  share: Common.GetUrlParameter(window.location.search,'share'),
  siteId: parseInt(Common.GetUrlParameter(window.location.search,'siteid'),0),
  lineupId: '',
  lineupAddress:'',
  currentTime:'',
  observesDst:false,
  utcOffset:0,
  debug: Common.GetUrlParameter(window.location.search,'debug')==='T',
  origin: Common.GetUrlParameter(window.location.search,'origin'),
  border: Common.GetUrlParameter(window.location.search,'border','F')==='T',
  dynamic: ValidateToken(token),
  showHamburger: Common.GetUrlParameter(window.location.search,'hamburger','T')==='T'
};

// handle no baseURL
if(window.location.search==='') {
  switch(window.location.host) {
    default:
    case 'localhost:3008': 
    case 'dev.titantvguide.com':
      params.siteId=477;
      break;
    case 'qa.titantvguide.com':
      params.siteId=49733;
      break;
    case 'test.titantvguide.com':
    case 'www.titantvguide.com':
    case 'titantvguide.com':
      params.siteId=78089;
      break;
  }
}

if(!params.dynamic&&params.siteId>0) {doStandard();}
// ***********************************
function doStandard(){
  let stores: IStores;
  let callback=() => {
    ReactDOM.render(<Provider {...stores}><App /></Provider>,document.getElementById('root'));
  };
  stores=Init(params,callback);
}
// ***********************************

// ***********************************
function ValidateToken(token: string): boolean {
  if(token==='Hv23P1WOHgroQFWlcFNWBnTFhm1y73PvQ5mADLUSvg6F4kfA0cAQqK0blmcnvg5M') {
    window.addEventListener('message',messageHandler);
    window.parent.postMessage(`{"guideMessage":"Hello AntennaWeb, from NextGen Guides.", "state":"READY"}`,'*');
    return true;
  }
  return false;
}
// ***********************************
function messageHandler(event: MessageEvent): void {
  const { data } =event;
  const hosts="http://localhost:3001|https://antennaweb.org|https://qa.antennaweb.org|https://prototype.antennaweb.org|https://test.antennaweb.org|https://www.antennaweb.org";

  // Guard clause to catch unwanted messages from other hosts
  if(hosts.indexOf(event.origin)===-1) {return;}
  if(typeof data==='object'&&data.awMessage) {
    // Filtered event handling here
    if(data.awMessage) {
      params.siteId=parseInt(data.siteId,0);
      params.lineupId=data.lineupId;
      params.lineupAddress=data.lineupAddress;
      params.colorMatch=data.colorMatch;
      params.currentTime=data.currentTime;
      params.observesDst=data.observesDst;
      params.utcOffset=data.utcOffset;
      console.log(`GuidesComms: siteId:${data.siteId}, lineupId:${data.lineupId}, lineupAddress:${data.lineupAddress}, currentTime:${data.currentTime}, observesDst:${data.observesDst},  utcOffset:${data.utcOffset}`);
      doStandard();
    } 
  }
}
  //***********************
