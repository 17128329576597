import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
}
// ***********************************
export default class WebLinkVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style = {
      height: this.props.size,
      width: this.props.size,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 512 512">
        <path fill="#4a702d" d="M0 0v512h512V0H0zm263.701 399.083l-60.352 60.352c-20.139 20.139-46.912 31.232-75.413 31.232-28.501 0-55.275-11.093-75.413-31.232-41.579-41.6-41.579-109.269 0-150.848l90.496-90.517c41.557-41.579 109.248-41.6 150.848 0 8.341 8.32 8.341 21.824 0 30.165-8.32 8.341-21.824 8.341-30.165 0-24.96-24.96-65.557-24.917-90.517 0l-90.496 90.517c-24.96 24.96-24.96 65.557 0 90.517 24.171 24.171 66.325 24.171 90.496 0l60.352-60.352c8.341-8.32 21.845-8.32 30.165 0 8.341 8.342 8.341 21.846-.001 30.166zM460.48 203.435L354.027 309.888c-20.139 20.139-46.912 31.232-75.413 31.232s-55.253-11.093-75.413-31.232c-8.32-8.341-8.32-21.845 0-30.187 8.341-8.32 21.845-8.32 30.165 0 24.171 24.171 66.347 24.171 90.496 0l106.453-106.432c24.96-24.96 24.96-65.557 0-90.517-24.171-24.171-66.325-24.171-90.496 0l-72.405 72.384c-8.32 8.341-21.824 8.341-30.165 0-8.32-8.32-8.32-21.824 0-30.165l72.405-72.384c20.139-20.16 46.912-31.253 75.413-31.253 28.501 0 55.275 11.093 75.413 31.253 41.579 41.578 41.579 109.248 0 150.848z"/>
      </svg>
    );
  }
  //***********************
}