// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../Types/interfaces';
import DayNav from '../../../../Controls/DayNav/daynav';
import HourNav from '../../../../Controls/HourNav/hournav';
import {AutoTextColor} from '../../../../../Scripts/common';
// ***********************************
interface IProps extends IStoresInject {listener?: boolean;}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class ScheduleNav extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {siteSettings,viewState,proto,masterStyles,as}=this.props;
    let esb: boolean=siteSettings!.enableScrollingBody;
    let schedStyle={
      ...masterStyles!.schedNav,
      borderStyle: `${esb? 'none':'solid'} none none`,
      fontSize: '16px',
      backgroundColor: proto!.colorButton,
      backgroundBlendMode: 'luminosity',
      borderColor: proto!.colorBorder,
      color: AutoTextColor(proto!.colorControlLight,proto!.textColorDark,proto!.textColorLight),
      filter: 'none',
      overflowY: esb? as!.scroll:as!.auto
    };
    let wid=viewState!.colWidthChannel;
    let spaceStyle={
      ...masterStyles!.inlineMiddle,
      borderWidth: '1px',
      borderStyle: 'none solid none none',
      width: `${wid}px`,
      height: '24px',
      borderColor: proto!.colorBorder
    };
    return (
      <div style={schedStyle} >
        <DayNav listener={false} />
        {siteSettings!.showChannelNumbers&&siteSettings!.enableViews? <div style={spaceStyle} />:''}
        <HourNav listener={this.props.listener? this.props.listener:false} />
      </div>
    );
  }
  //***********************
}
// ***********************************
