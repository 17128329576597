// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IContributorRelated, IStores,IStoresInject} from '../../../../../Types/interfaces';
//import SocialMedia from '../../../../Controls/Event/SocialMedia/socialmedia';
// ***********************************
interface IProps extends IStoresInject {data: IContributorRelated[], name: string}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyRelated extends React.Component<IProps,{}> {

  //***********************
  handleMouseLeave=(e: React.FormEvent<HTMLElement>) => {
    e.currentTarget.style.textDecoration='underline';
  }
  //***********************
  handleMouseEnter=(e: React.FormEvent<HTMLElement>) => {
    e.currentTarget.style.textDecoration='none';
  }
  //***********************
  render() {
    let memberLinkStyle={cursor: 'pointer'};
    
    const {as,proto,data,name}=this.props;

    const ownerStyle={fontSize: '1.1em',margin: '10px 0'};
    const headerDivStyle={
      fontWeight: as!.bold,fontFamily: 'Roboto Slab',
      fontSize: '.9em',color: proto!.textColorDark
    };
    const sectionStyle={fontSize: '.8em', marginLeft: '10px'};

    let material=[];

    for(let i: number=0;i<data.length&&i<10;i++) {
      let {Type,Origin,Value}=data[i];
      if(Type!=='website') {continue;}

      material.push(<div style={sectionStyle}>
        <span
          onClick={() => {window.open(Value,'_blank')}}
          style={memberLinkStyle} 
          onMouseEnter={this.handleMouseLeave}
          onMouseLeave={this.handleMouseEnter}
        >{name}{ Origin==='Official Website'?'':' on'}&nbsp;{Origin}</span></div>);
    }

    return (
      <div style={ownerStyle}>
        <div style={headerDivStyle}>Related Materials</div>
        {material}
      </div>
    );
  }
  //***********************
}
// ***********************************