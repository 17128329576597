import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
}
// ***********************************
export default class PinterestVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.size,
      width: this.props.size,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 456 456">
        <path fill="#C9353D" d="M0 0h455.731v455.731H0z"/>
        <path fill="#FFF" d="M160.601 382C86.223 350.919 37.807 262.343 68.598 172.382 99.057 83.391 197.589 36.788 286.309 69.734c88.972 33.04 132.978 131.213 98.486 219.22-35.709 91.112-131.442 123.348-203.22 100.617 5.366-13.253 11.472-26.33 15.945-39.943 4.492-13.672 7.356-27.878 10.725-41.037 2.9 2.44 5.814 5.027 8.866 7.439 15.861 12.535 33.805 13.752 52.605 9.232 19.977-4.803 35.764-16.13 47.455-32.78 19.773-28.16 26.751-60.019 21.972-93.546-4.942-34.668-25.469-59.756-57.65-72.389-48.487-19.034-94.453-12.626-134.269 22.259-30.622 26.83-40.916 72.314-26.187 107.724 5.105 12.274 13.173 21.907 25.379 27.695 6.186 2.933 8.812 1.737 10.602-4.724.133-.481.295-.955.471-1.422 3.428-9.04 2.628-16.472-3.473-25.199-11.118-15.906-9.135-34.319-3.771-51.961 10.172-33.455 40.062-55.777 75.116-56.101 9.39-.087 19.056.718 28.15 2.937 27.049 6.599 44.514 27.518 46.264 55.253 1.404 22.242-2.072 43.849-11.742 64.159-4.788 10.055-11.107 18.996-20.512 25.325-8.835 5.945-18.496 8.341-28.979 5.602-14.443-3.774-22.642-16.95-18.989-31.407 3.786-14.985 8.685-29.69 12.399-44.69 1.57-6.344 2.395-13.234 1.751-19.696-1.757-17.601-18.387-25.809-33.933-17.216-10.889 6.019-16.132 16.079-18.564 27.719-2.505 11.992-1.292 23.811 2.61 35.439.784 2.337.9 5.224.347 7.634-7.063 30.799-14.617 61.49-21.306 92.369-1.952 9.011-1.59 18.527-2.239 27.815-.123 1.778-.017 3.574-.017 5.939z"/>
      </svg>
    );
  }
  //***********************
}
// ***********************************