// ***********************************
import * as React from 'react';
import {observable} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IContributor, IStores,IStoresInject} from '../../../../Types/interfaces';
import LoaderSmall from '../../../Controls/Loaders/LoaderSmall/loadersmall';
import BodyCore from './BodyParts/bodycore';
import CloseCastButton from '../../../Controls/Navigation/closecastbutton';
// ***********************************
interface IProps extends IStoresInject {width: number; data? : IContributor, noData: boolean}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyCast extends React.Component<IProps,{}> {
  //***********************
  @observable fontSize: number;
  //***********************
  render() {
    const maxWidth=1280;

    const {viewState,details,proto,as}=this.props;

    if(!details!.ready) {
      const waitDivStyle={paddingBottom: '20px'};
      return (<div style={waitDivStyle}><LoaderSmall flex={100} last={true} searchStyle={true} /></div>);
    }

    const fontSize: string=((viewState!.mediaState)+12).toString()+'px ';
    const padSize: string=((viewState!.mediaState)+12).toString()+'px ';

    const availWidth=this.props.width<maxWidth? this.props.width:maxWidth;
    const componentPadding=Math.ceil(availWidth*.02);

    let cbStyle={textAlign: as!.right,};

    const ownerStyle={
      fontSize: fontSize,
      padding: componentPadding+'px',
      width: '100%',
      maxWidth: maxWidth+'px',
      backgroundColor: proto!.colorWindow,
      boxSizing: as!.borderBox
    };

    const sectionStyle={marginBottom: padSize};
    if(this.props.noData) {
      return (
        <div style={ownerStyle}>
          <div style={cbStyle}><CloseCastButton enabled={!viewState!.screenShot} /></div>
          <div style={sectionStyle}>No contributer details found</div>
        </div>
      );
    }
    return (
      <div style={ownerStyle}>
        <div style={cbStyle}><CloseCastButton enabled={!viewState!.screenShot} /></div>
        <div style={sectionStyle}><BodyCore width={availWidth} padding={componentPadding} data={this.props.data} /></div>
      </div>
    );

  }
  //***********************
}
// ***********************************