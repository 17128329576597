// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../../Types/interfaces';
import MovieRatingVector from '../../../../../SVG/Ratings/movierating';
// ***********************************
interface IProps extends IStoresInject {movieRating: string; vectorSize: number;}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class MovieRating extends React.Component<IProps,{}> {
  //***********************
  active: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    const {siteSettings,movieRating}=this.props;
    this.active=siteSettings!.detailFields.TVRatings&&movieRating.length>0;
  }
  //***********************
  render() {
    if(!this.active) {return ('');}
    const {movieRating,vectorSize}=this.props;

    let width:number;
    switch(movieRating){
      default: width=vectorSize; break;
      case 'G': width= vectorSize*1.15; break;
      case 'PG': width= vectorSize*1.83; break;
      case 'PG-13': width= vectorSize*3.54; break;
      case 'R': width= vectorSize*1.15; break;
      case 'NC-17': width= vectorSize*4; break;
    }


    const ratingDivStyle={
      display: 'inline-block', 
      borderRadius:'3px', 
      marginRight:'4px', 
      height: vectorSize+'px', 
      width: width+'px'
    };

    return (
      <div style={ratingDivStyle}><MovieRatingVector size={vectorSize} rating={movieRating} /></div>
    );
  }
  //***********************
}
// ***********************************