// ***********************************
import * as React from 'react';
import {observable} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import LoaderSmall from '../../../Controls/Loaders/LoaderSmall/loadersmall';
import BodyCore from './BodyParts/bodycore';
import ImageDebug from './BodyParts/imagedebug';
import CloseDetailsButton from '../../../Controls/Navigation/closedetailbutton';
// ***********************************
interface IProps extends IStoresInject {width: number;}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyDetails extends React.Component<IProps,{}> {
  //***********************
  @observable fontSize: number;
  //***********************
  render() {
    const maxWidth=1280;

    const {viewState,details,proto,as}=this.props;

    if(!details!.ready) {
      const waitDivStyle={paddingBottom: '20px'};
      return (<div style={waitDivStyle}><LoaderSmall flex={100} last={true} searchStyle={true} /></div>);
    }

    const fontSize: string=((viewState!.mediaState)+12).toString()+'px ';
    const padSize: string=((viewState!.mediaState)+12).toString()+'px ';

    const availWidth=this.props.width<maxWidth?this.props.width:maxWidth;
    const componentPadding=Math.ceil(availWidth * .02);

    let cbStyle = { 
      // ...masterStyles!.inlineMiddle,
       textAlign:as!.right,
      // backgroundColor:'transparent',
      // float:as!.right,
      // marginBottom:'5px'
    };

    const ownerStyle={
      fontSize: fontSize,
      padding: componentPadding+'px',
      width:'100%',
      maxWidth:maxWidth+'px',
      backgroundColor:proto!.colorWindow,
      boxSizing:as!.borderBox
    };
    
    const sectionStyle={marginBottom:padSize};
    
    return (
      <div style={ownerStyle}>
        <div style={cbStyle}><CloseDetailsButton enabled={!viewState!.screenShot}/></div>
        <div style={sectionStyle}><BodyCore width={availWidth} padding={componentPadding}/></div>
        <ImageDebug />
      </div>
    );
  }
  //***********************
}
// ***********************************