// ***********************************
import * as React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../Types/interfaces';
import { ViewContext } from '../../Types/enums';
import MenuVector from '../SVG/menu';
import AntennaWebVector from '../SVG/Logos/antennaweb2';
import TitanTvVector from '../SVG/Logos/titantv';
// ***********************************
interface IProps extends IStoresInject { }
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class Header extends React.Component<IProps, {}> {
  //***********************
  @observable menuHover: boolean;
  @observable timeHover: boolean;
  //***********************
  constructor(props:IProps){
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct(){
    this.menuHover=false;
    this.timeHover=false;
  }
  //***********************
  now = (): void => {
    const {frame, viewState} = this.props;
    viewState!.setViewMode(ViewContext.Standard, true);
    frame!.now();
  }
  //***********************
  toggleSettings = (): void => {
    const {viewState} = this.props;
    viewState!.setShowSettings(!viewState!.showSettings); 
  }
  //***********************
  @action handleMenuMouseEnter=():void =>{ this.menuHover=true; };
  @action handleMenuMouseLeave=():void =>{ this.menuHover=false; };
  @action handleTimeMouseEnter=():void =>{ this.timeHover=true; };
  @action handleTimeMouseLeave=():void =>{ this.timeHover=false; };
  //***********************
  render() {
    const { viewState, siteSettings, proto, masterStyles, as } = this.props;
    let esb=siteSettings!.enableScrollingBody;
    if (!siteSettings!.loaded) { return (<div />); }
    let menuColor:string=proto!.hoverableColor(this.menuHover);
    let baseStyle={ 
      padding: esb?'2px':'5px',
      flexGrow:0, flexBasis:'auto'
    };
    let timeStyle={ 
      ...masterStyles!.noSelect,
      ...masterStyles!.inlineMiddle,
      fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
      margin: '10px',
      cursor:'pointer',
      fontSize:'16px', 
    };
    let brandingStyle={
      fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
      margin: '2px 0 2px 0',
      fontSize:'10px', 
    };
    let timeTextStyle={
      ...masterStyles!.inlineMiddle,
      color: this.timeHover?proto!.colorHover:proto!.textColorDark
    };
    let logoStyle={ ...masterStyles!.ttvLogo };
    let awLogoStyle={ ...masterStyles!.awLogo, float:as!.right };
    let logoParams:{height:number,width:number}={ ...masterStyles!.ttvLogoParams };
    let awLogoParams:{height:number,width:number}={ ...masterStyles!.awLogoParams };
    let menuSvgStyle={ float:as!.left, marginRight: '5px', width: '20px', height: '20px', cursor: 'pointer' };

    let displayMenu: boolean=viewState!.mediaState<3&&!viewState!.viewDetails;
    displayMenu=(displayMenu&&siteSettings!.showHamburger);

    return (
      <div style={baseStyle}>
        {displayMenu?<div style={menuSvgStyle} onClick={this.toggleSettings} onMouseEnter={this.handleMenuMouseEnter} onMouseLeave={this.handleMenuMouseLeave}>
          <MenuVector color={menuColor} height="20" width="20" />
        </div>:''}
        {siteSettings!.branding.TitanTVLogo?<a title="Visit TitanTV" href="https://www.titantv.com/"><div style={logoStyle}><TitanTvVector {...logoParams} /></div></a>:''}
        <div style={timeStyle}>{viewState!.mediaState>3?<div  style={timeTextStyle} onClick={this.now} onMouseEnter={this.handleTimeMouseEnter} onMouseLeave={this.handleTimeMouseLeave}>{viewState!.displayTime[1]}</div>:''}</div>
        {siteSettings!.branding.AntennaWebLogo?<a  title="Visit AntennaWeb" href="https://www.antennaweb.org/"><div style={awLogoStyle}><AntennaWebVector {...awLogoParams}  /></div></a>:''}
        <div style={brandingStyle}>The following content is provided by TitanTV</div>
      </div>
    );
  }
  //***********************
}
// ***********************************
