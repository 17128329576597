// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {action} from 'mobx';
import {IStores,IStoresInject,ICastDetail} from '../../../../../Types/interfaces';
// ***********************************
interface IProps extends IStoresInject { }
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyCast extends React.Component<IProps,{}> {
  //***********************
  @action handleCastClick=(e: React.FormEvent<HTMLElement>,castId: string): boolean => {
    e.stopPropagation();
    const {viewState}=this.props;
    viewState!.setCast(castId);
    return false;
  }
  //***********************
  handleMouseLeave=(e: React.FormEvent<HTMLElement>) => {
    e.currentTarget.style.textDecoration='underline';
  }
  //***********************
  handleMouseEnter=(e: React.FormEvent<HTMLElement>) => {
    e.currentTarget.style.textDecoration='none';
  }
  //***********************
  render() {
    const {as,proto,details,siteSettings}=this.props;

    let memberLinkStyle={cursor: 'pointer'};

    if(!siteSettings!.detailFields.Cast) {return ('');}
    let prog=details!.program;

    let cast: any[]=[];
    for(let i: number=0;i<prog.Cast.length;i++) {
      let ci: ICastDetail=prog.Cast[i];
      cast.push((
        <div style={{fontSize: '.8em'}} key={'BodyCastDiv'+i}>
          <span style={{fontWeight: as!.bold}}>{ci.Role} - </span>
          {siteSettings!.detailFields.ExtendedCastAndCrewInfo?
            <span
              style={memberLinkStyle}
              onClick={(e) => {this.handleCastClick(e,ci.PersonId);}}
              onMouseEnter={this.handleMouseLeave}
              onMouseLeave={this.handleMouseEnter}
            >
              {ci.Name}
            </span>
            :<span>
              {ci.Name}
            </span>
          }
        </div>
      ));
    }

    if(cast.length===0) {return ('');}

    const ownerStyle={fontSize: '1.1em',margin: '10px 0'};
    const headerDivStyle={
      fontWeight: as!.bold,fontFamily: 'Roboto Slab',
      fontSize: '1.1em',color: proto!.textColorDark
    };
    const sectionStyle={marginLeft: '10px'};

    return (
      <div style={ownerStyle}>
        <div style={headerDivStyle}>Cast & Credits</div>
        <div style={sectionStyle}>{cast}</div>
      </div>
    );
  }
  //***********************
}
// ***********************************