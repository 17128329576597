// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {inject,observer} from 'mobx-react';
import {HexToRgbaHover} from '../../../../Scripts/common';
import {IStores,IStoresInject,IRgba,IEvent} from '../../../../Types/interfaces';
import {GridColorStyle} from '../../../../Types/enums';
import MoreContentVector from '../../../SVG/morecontent';
import OrientLeft from '../OrientLeft/orientleft';
import OrientRight from '../OrientRight/orientright';
import EventItem from '../event';
// ***********************************
interface IProps extends IStoresInject {
  channelIndex: number;
  item: IEvent;
  flex: number;
  last: boolean;
  running: boolean;
  continues: boolean;
  width?: number;
  debug?: any;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class EventStub extends React.Component<IProps,{}> {
  //***********************
  @observable hover: boolean;
  @observable mouseX: number;
  @observable mouseY: number;
  @observable windowWidth: number;
  @observable windowHeight: number;
  maxExpanderWidth: number=200;
  //***********************
  private resizeBinding:any;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
    this.resizeBinding=this.updateWindowDimensions.bind(this);
  }
  //***********************
  @action private _construct() {
    this.hover=false;
  }
  //***********************
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize',this.resizeBinding);
  }
  //***********************
  componentWillUnmount() {
    window.removeEventListener('resize',this.resizeBinding);
  }
  //***********************
  @action updateWindowDimensions= () => {
    this.windowWidth=window.innerWidth;
  }
  //***********************
  @action click=(e: React.FormEvent<HTMLElement>): boolean => {
    this.hover=!this.hover;
    return false;
  }
  //***********************
  @action handleMouseMove=(e: React.MouseEvent<HTMLDivElement>): void => {
    const {viewState}=this.props;
    switch(viewState!.mediaState) {
      case 1: case 2: this.maxExpanderWidth=200; break;
      default: this.maxExpanderWidth=340; break;
    }
    let w=this.maxExpanderWidth;
    this.mouseX=(e.pageX+w>this.windowWidth)? (e.pageX-w):e.pageX;
    this.mouseY=e.pageY-50;
  }
  //***********************
  @action handleMouseEnter=(e: React.FormEvent<HTMLDivElement>): void => {this.hover=true;};
  @action handleMouseLeave=(e: React.FormEvent<HTMLElement>): void => {this.hover=false;};
  //***********************
  render() {
    const {channelIndex,item,flex,last,running,continues,proto,siteSettings,viewState,masterStyles,as,debug}=this.props;
    const borderStyle={
      borderColor: proto!.colorBorder,
      borderWidth: '1px'
    };
    if(viewState!.filterNew&&item!.Flags.NewRepeat!=='N') {
      const emptyStyle={
        ...borderStyle,
        borderRightStyle: last? as!.none:as!.solid,
        flex: flex.toString()+'%',display: 'flex',width: '100%',
        backgroundColor: proto!.colorBorder
      };
      return (<div style={emptyStyle} />);
    }

    let colSet=siteSettings!.colors.getColorForEvent(item);

    let bgColRgba: IRgba=HexToRgbaHover(colSet.BackgroundColor,this.hover);
    let bgCol: string=`rgba(${bgColRgba.r},${bgColRgba.g},${bgColRgba.b},${bgColRgba.a})`;
    bgCol=this.hover? siteSettings!.genreColorStyle===GridColorStyle.None? 'rgba(154, 179, 255, 0.363)':bgCol:proto!.colorWindow;

    const ownerStyle={
      ...borderStyle,
      cursor: 'pointer',
      borderRightStyle: last? as!.none:as!.solid,
      boxSizing: as!.borderBox,
      flex: this.props.flex.toString()+'%',
      display: 'flex',
      alignItems: as!.flexStart,
      justifyContent: as!.flexStart,
      backgroundColor: bgCol,
      overflow: as!.hidden,
      wordWrap: as!.breakWord,
    };
    const genreStyle={...masterStyles!.getGenreStyle(colSet.BackgroundColor)};
    const orientStyle={display: 'flex',height: '100%',alignItems: as!.center,flexGrow: 0};
    const stubStyle={display: 'flex',height: '100%',alignItems: as!.center,justifyContent: as!.center,flexGrow: 1};
    const rightColor=proto!.hoverableColor(this.hover);

    const expanderStyle={
      position: as!.absolute,left: this.mouseX+'px',top: this.mouseY+'px',maxWidth: this.maxExpanderWidth,backgroundColor: 'white',
      zIndex: 9999,borderColor: proto!.colorBorder,borderWidth: '1px',borderStyle: 'solid',
    };
    return (
      <div id="owner" style={ownerStyle}>
        <OrientLeft item={this.props!.item} running={running} orientStyle={orientStyle} />
        <div style={stubStyle} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.click} onMouseMove={this.handleMouseMove}>
          <MoreContentVector color={rightColor} width={5} />
        </div>
        <OrientRight item={this.props!.item} continues={continues} orientStyle={orientStyle} />
        {this.hover? <div style={expanderStyle} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
          <EventItem channelIndex={channelIndex} item={item} flex={100} last={true} running={false} continues={false} float={true} debug={debug} />
        </div>:''}
        <div style={genreStyle} />
      </div>
    );
  }
  //***********************
}