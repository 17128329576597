// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {action,observable} from 'mobx';
import {IStores,IStoresInject} from '../../../Types/interfaces';
import FacebookVector from '../../SVG/Social/facebook';
import TwitterVector from '../../SVG/Social/twitter';
import LinkedInVector from '../../SVG/Social/linkedin';
import MailToVector from '../../SVG/Social/mailto';
import TitanTvVector from '../../SVG/titantv';
// ***********************************
interface IProps extends IStoresInject {
  corporate?:
  boolean; spacerColor?:
  string; hoverColor: string;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class OurSocialMedia extends React.Component<IProps,{}> {
  //***********************
  @observable ttvHover: boolean;
  @observable fbHover: boolean;
  @observable twHover: boolean;
  @observable liHover: boolean;
  @observable mtHover: boolean;
  //***********************
  @action mouseEnter=(e: React.FormEvent<HTMLElement>): boolean => {
    switch(e.currentTarget.id) {
      case 'osmTtv': this.ttvHover=true; break;
      case 'osmFace': this.fbHover=true; break;
      case 'osmTwit': this.twHover=true; break;
      case 'osmLink': this.liHover=true; break;
      case 'osmMail': this.mtHover=true; break;
      default: break;
    }
    return false;
  }
  //***********************
  @action mouseLeave=(e: React.FormEvent<HTMLElement>): boolean => {
    switch(e.currentTarget.id) {
      case 'osmTtv': this.ttvHover=false; break;
      case 'osmFace': this.fbHover=false; break;
      case 'osmTwit': this.twHover=false; break;
      case 'osmLink': this.liHover=false; break;
      case 'osmMail': this.mtHover=false; break;
      default: break;
    }
    return false;
  }
  //***********************
  handleClick=(e: React.FormEvent<HTMLElement>): boolean => {
    const {siteSettings,proto,viewState}=this.props;
    let url;
    switch(e.currentTarget.id) {
      case 'osmTtv': url='http://titantvinc.com'; break;
      case 'osmFace': url='http://facebook.com/titantvinc'; break;
      case 'osmTwit': url='http://twitter.com/titantvinc'; break;
      case 'osmLink': url='http://linkedin.com/company/titantv-inc'; break;
      case 'osmMail': 
        if(proto!.protoTypeMode){
          alert(`Email sent to the link you clicked will go to TitanTV Inc., and not your local station or affiliate.\n\nIf you wish to make inquiry or comment about your local programming, please conact your local station or programming provider directly instead.`);
        }
        url=`mailto:${siteSettings!.siteEmailAddress}?subject=TitanTV Affiliate Guide REF: Site ${siteSettings!.siteId}`; 
        viewState!.safeMailTo(url);
        url=null;
        break;
      default: break;
    }
    if(url) {window.open(url,'_blank',undefined,false);}
    return false;
  }
  //***********************
  render() {
    const {as,siteSettings}=this.props;
    const base: string='#FFFFFF';
    const hover: string=this.props.hoverColor;
    const spacer: string=this.props.spacerColor? this.props.spacerColor:base;
    let ttvTitle='Visit TitanTv, Inc.';
    let fbTitle='Follow us on Facebook';
    let twTitle='Follow us on Twitter';
    let liTitle='Follow us on Linked In';
    let mtTitle='Send us an email';

    let sectionStyle={display: 'inline'};
    let setStyle={padding: '5px',display: 'flex',alignItems: as!.center,justifyContent: as!.center};
    let itemStyle={cursor: 'pointer',margin: '2px 4px 2px 4px', padding:'0 4px', height: '24px',display: 'inline-block'};
    let spacerStyle={display: 'inline-block',borderRight: `solid 1px ${spacer}`,width: '1px',height: '19px', position:as!.relative, left:'1px', bottom:'2px'};

    let {CorporateFooterLink,CorporateSocialMedia,CorporateFooterEmail}=siteSettings!.branding;

    return (
      <div style={setStyle}>
        {CorporateFooterLink? <div style={sectionStyle}>
          <div id="osmTtv" title={ttvTitle} style={itemStyle} onClick={this.handleClick} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
            <TitanTvVector height={24} fill={this.ttvHover? hover:base} />
          </div>
          {CorporateFooterEmail||CorporateSocialMedia? <div style={spacerStyle} />:''}
        </div>:''}
        {CorporateSocialMedia? <div style={sectionStyle}>
          <div id="osmFace" title={fbTitle} style={itemStyle} onClick={this.handleClick} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
            <FacebookVector size={24} fill={this.fbHover? hover:base} />
          </div>
          <div id="osmTwit" title={twTitle} style={itemStyle} onClick={this.handleClick} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
            <TwitterVector size={24} fill={this.twHover? hover:base} />
          </div>
          <div id="osmLink" title={liTitle} style={itemStyle} onClick={this.handleClick} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
            <LinkedInVector size={24} fill={this.liHover? hover:base} />
          </div>
          {CorporateFooterEmail? <div style={spacerStyle} />:''}
        </div>:''}
        {CorporateFooterEmail? <div style={sectionStyle}>
          <div id="osmMail" title={mtTitle} style={itemStyle} onClick={this.handleClick} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
            <MailToVector height="24" width="24" fill={this.mtHover? hover:base} index={1} />
          </div>
        </div>:''}
      </div>
    );
  }
  //***********************
}