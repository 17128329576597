import * as React from 'react';
// ***********************************
interface IProps {
  color: string;
  size: string;
}
// ***********************************
export default class PreviousVector extends React.Component<IProps,{}> {
  //***********************
  render() {
    let style={
      height: this.props.size,
      width: this.props.size,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 16 16">
        <path fill={this.props.color} d="M15.992 6.996H3.414l2.293-2.293-1.414-1.414-4 4c-.391.391-.391 1.023 0 1.414l4 4 1.414-1.414-2.293-2.293h12.578v-2z" />
      </svg>
    );
  }
  //***********************
}