// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import LoaderSmall from '../../../Controls/Loaders/LoaderSmall/loadersmall';
import SearchEvent from '../../../Controls/Event/SearchEvent/searchevent';
// ***********************************
//import Row from './Row/row';
// ***********************************
interface IProps extends IStoresInject {}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodySearchResults extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {proto,search,viewState,as}=this.props;
    const baseStyle={
      borderColor: proto!.colorBorder,
      backgroundColor: proto!.colorWindow,
      borderStyle: 'solid none none none',
      borderWidth: '1px',
      padding: '8px'
    };
    let fontSize:string='14px';
    let textAlign:string='left';
    switch (viewState!.mediaState) {
      case 0: case 1: case 2:
        fontSize='14px';
        textAlign='center';
        break;
      case 3:
        fontSize='16px';
        textAlign='left';
        break;
      case 4: case 5: default:
        fontSize='18px';
        textAlign='left';
        break;
    }

    let waitDivStyle={padding:'20px 0'};
    let headerDivStyle={fontSize: fontSize, fontWeight: as!.bold};
    let resultsHeaderDivStyle={...headerDivStyle, color:proto!.textColorDark};
    let resultsDivStyle={fontSize: '1em', textAlign:textAlign==='left'?as!.left:as!.center, display: 'inline-block', padding: '0 10px' };
    let labelStyle={ textAlign: as!.left, fontSize: fontSize, paddingTop:'12px', marginBottom: '2px' };

    let pass=false;

    if(!search!.ready&&!pass) {
      return (
        <div style={baseStyle}>
          <div style={resultsHeaderDivStyle}>Searching for '{search!.searchText}'...</div>
          <div style={waitDivStyle}><LoaderSmall flex={100} last={true} searchStyle={true}/></div>
        </div>
      );
    }
    
    let count=search!.events.length;
    let searchResults: any[]=[];
    let startDay=count>0?search!.events[0].EventDate:'';
    if(count>0){searchResults.push(<div style={labelStyle} key={'BodySearchResultsLabel'}>{startDay}</div>);}

    for(let i:number=0; i< search!.events.length; i++){
      if(search!.events[i].EventDate!==startDay){
        startDay=search!.events[i].EventDate;
        searchResults.push(<div style={labelStyle} key={'BodySearchResultsDiv'+i}>{startDay}</div>);
      }
      searchResults.push(<SearchEvent key={'BodySearchResultsEvent'+i} item={search!.events[i]}/>);
    }
    let countText=count>0?'('+count.toString()+' results found over the next week)':'no results found';

    return (
      <div style={baseStyle}>
        <div style={resultsHeaderDivStyle}>
          Search results for '{search!.searchText}'
          <div style={{fontSize:count>0?'12px':'14px', fontWeight:'normal'}}>&nbsp;{countText}</div>
        </div>
        <div style={resultsDivStyle}>
          {searchResults}
        </div>
      </div>
    );
  }
  //***********************
}
// ***********************************