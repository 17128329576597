// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../../../Types/interfaces';
import {ViewContext} from '../../../../../../../Types/enums';
// ***********************************
interface IProps extends IStoresInject {index: number;}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class ChannelLinks extends React.Component<IProps,{}>  {
  //***********************
  @observable dailyHover: boolean;
  @observable extendedHover: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.dailyHover=false;
    this.extendedHover=false;
  }
  //***********************
  handleExtendedClick=(): void => {
    const {schedule}=this.props;
    if(!schedule!.initialized) {return;}
    this.setViewMode(ViewContext.Extended);
  }
  //***********************
  handleDailyClick=(): void => {
    const {schedule}=this.props;
    if(!schedule!.initialized) {return;}
    this.setViewMode(ViewContext.Daily);
  }
  //***********************
  setViewMode(context: ViewContext): void {
    const {viewState,lineupInfo}=this.props;
    lineupInfo!.setChannelIndex(this.props.index);
    viewState!.setViewMode(context,true);
  }
  //***********************
  @action mouseEnter=(e: React.FormEvent<HTMLElement>): boolean => {
    switch(e.currentTarget.id) {
      case 'dailyLnk': this.dailyHover=true; break;
      case 'extendedLnk': this.extendedHover=true; break;
      default: break;
    }
    return false;
  }
  //***********************
  @action mouseLeave=(e: React.FormEvent<HTMLElement>): boolean => {
    switch(e.currentTarget.id) {
      case 'dailyLnk': this.dailyHover=false; break;
      case 'extendedLnk': this.extendedHover=false; break;
      default: break;
    }
    return false;
  }
  //***********************
  render() {
    const {masterStyles}=this.props;
    const baseStyle={...masterStyles!.flexCenter,flex: 1,paddingTop: '3px'};
    return (
      <div style={baseStyle}>
        <div id="dailyLnk" onClick={this.handleDailyClick} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} style={masterStyles!.getMainLinkStyle(this.dailyHover)}>Daily</div>
        <b>&nbsp;|&nbsp;</b>
        <div id="extendedLnk" onClick={this.handleExtendedClick} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} style={masterStyles!.getMainLinkStyle(this.extendedHover)}>14 Day</div>
      </div>);
  }
  //***********************
}