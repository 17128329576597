import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
  fill?: string;
}
// ***********************************
export default class LiveStreamVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.size,
      width: this.props.size,
    };
    if(this.props.fill){
      return(
        <svg xmlns="http://www.w3.org/2000/svg"  style={style} height={this.props.size} width={this.props.size} viewBox="0 0 456 456">
          <path fill={this.props.fill} d="M0 0v455h455V0H0zm166.062 321.575v-188.15L328.938 227.5l-162.876 94.075z"/>
        </svg>
      );
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 456 456">
        <path fill="#454545" d="M0 0v455h455V0H0zm166.062 321.575v-188.15L328.938 227.5l-162.876 94.075z"/>
      </svg>
    );
  }
  //***********************
}