import './debug.css';
// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../Types/interfaces';
import {ViewContext} from '../../Types/enums';
// ***********************************
interface IProps extends IStoresInject {}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Debug extends React.Component<IProps,{}>{
  //***********************
  private handleAdminClick=(): void => {
    const {siteSettings}=this.props;
    let features='menubar=no,resizable=no,width=1179,height=963';
    window.open(siteSettings!.adminUrl+siteSettings!.siteId,'Apg Manager',features);
  }
  //***********************
  render() {
    const {siteSettings,viewState,proto,lineupInfo}=this.props;

    if(!siteSettings!.canDebug||!viewState!.debug) {return ('');}

    return (
      <div className="debug-base">
          <div style={{float: 'right'}}>
            <input id="proto" type="checkbox" checked={proto!.protoTypeMode} onChange={() => {proto!.protoTypeMode=!proto!.protoTypeMode;}} />
            <label className="debug-colors-el" htmlFor="proto">Prototype Mode</label>
          </div>
        <div className="debug-header">
          Debug Window
        </div>
        <div className="debug-content">
          <div>Environment: <span>{siteSettings!.environment}</span></div>
          <div>MediaState: <span>{viewState!.mediaState}</span></div>
          <div>ViewContext: <span>{ViewContext[viewState!.viewMode]}</span></div>
          <div>LineupIndex: <span>{lineupInfo!.lineupIndex}</span><br /></div>
          <div style={{margin: '15px 0 0 0',verticalAlign: 'top'}}>
            <button onClick={viewState!.togglePadBorder}>{viewState!.padBorder? 'Remove Padding':'Pad Edge For Screenshot'}</button>
            <span style={{paddingLeft:'5px', fontSize:'8px', color:'#808080' }}>(Chrome or Edge only)</span>
          </div>
        </div>
        <div className="debug-admin" title="Admin" onClick={this.handleAdminClick} />
      </div>
    );
  }
  //***********************
}
// ***********************************