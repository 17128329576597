// ***********************************
import * as React from 'react';
//import ReactGA from 'react-ga';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../Types/interfaces';
import Views from './Views/views';
import LoaderLarge from './Controls/Loaders/LoaderLarge/loaderlarge';
import Debug from './Debug/debug';
//import {DetectIE} from '../Scripts/common';
// ***********************************
interface IProps extends IStoresInject {}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class App extends React.Component<IProps,{}> {
  //***********************
  private _resizeBinding: any;
  private _timer:any;
  private _lastHeight:number;
  private _appDiv:HTMLDivElement;
  constructor(props: IProps) {
    super(props);
    this._resizeBinding=this.updateMedia.bind(this);
    this.state={siteId: '',lineupId: ''};
  }
  //***********************
  updateMedia=() => {
    const {viewState}=this.props;
    viewState!.updateMedia();
  }
  //***********************
  componentDidMount() {
    const {lineupInfo,viewState,siteSettings}=this.props;
    window.addEventListener('resize',this._resizeBinding);
    viewState!.mountBeforeUnload();
    //ReactGA.initialize(viewState!.googleTrackingId);
    //ReactGA.event({category: 'User Action',action: 'Init Site',label: `${siteSettings!.siteId}`,nonInteraction: true});
    if(siteSettings!.dynamic) { 
      lineupInfo!.initLineupIndex(siteSettings!.startingLineupId);
      this.setElementHeightChange(); 
    }
  
  }
  //***********************
  componentWillUnmount() {
    const {viewState}=this.props;
    window.removeEventListener('resize',this._resizeBinding);
    viewState!.unMountBeforeUnload();
  }
  //***********************
  componentDidUpdate() {
    const {lineupInfo,siteSettings}=this.props;
    if(lineupInfo!.lineupLoaded) {
      if(lineupInfo!.lineupIndex===-1) { lineupInfo!.initLineupIndex(siteSettings!.startingLineupId); }
    }
    //ReactGA.pageview(siteSettings!.environment+'/siteid/'+siteSettings!.siteId);
  }
  //***********************
  setElementHeightChange = ():void => {
    if(!this._timer) { this._timer = setInterval(this.setElementHeightChange, 2000); }
    else { 
      if(!this._appDiv){ return; }
      let newHeight=this._appDiv.offsetHeight;
      if(this._lastHeight !== newHeight  ) {
        this.handleElementHeightChange(newHeight);
        this._lastHeight = newHeight;
      }
    }
  }
  //***********************
  handleElementHeightChange = (height:number):void => {
    window.parent.postMessage(`{"guideMessage":"${ height }", "state":"HEIGHT" }`,'*');
  }
  //***********************
  render() {
    const {siteSettings,viewState,lineupInfo,proto}=this.props;

    let stopper=false;

    if(!siteSettings!.loaded||!lineupInfo!.lineupLoaded||stopper) {
      return (<LoaderLarge flex={100} last={false} />);
    }

    let standardStyle={
      backgroundColor: proto!.colorWindow,
      color: proto!.textColorDark,
      fontFamily: siteSettings!.defaultFont,
      fontSize: '10px',minWidth: '320px'
    };

    let borderStyle={border: '1px solid silver',margin: viewState!.padBorder? '47px':'0'};

    let style: any;
    if(viewState!.border||viewState!.padBorder) {style={...standardStyle,...borderStyle};}
    else {style=standardStyle;}

    return (
      <div ref={(el) => {this._appDiv=el!;}} >
        <div style={style}>
          <Views />
        </div>
        {viewState!.padBorder? <Debug />:''}
      </div>
    );
  }
  //***********************
}
// ***********************************
