// ***********************************
import {action,observable,computed} from 'mobx';
import {IStores,ILoadParams,ISchedule, IEventFlags} from '../Types/interfaces';
import TimeLine from '../Scripts/timeline';
// ***********************************
export default class Schedule {
  //***********************
  private _loadTimer: number=-1;
  private _parent: IStores;
  private _siteId: number;
  private _fetchCalls: string[];
  private _usedChunks: string[];
  @observable private _loadIndex: number;
  @observable private _initialized: boolean;
  @computed public get initialized() {return this._initialized;}
  @computed public get loadIndex(): number {return this._loadIndex;}
  //***********************
  constructor(params: ILoadParams) {
    this._construct(params);
  }
  //***********************
  @action private _construct(params: ILoadParams) {
    this._loadIndex=0;
    this._siteId=params.siteId;
    this._initialized=false;
    this._fetchCalls=[];
    this._usedChunks=[];
  }
  //***********************
  public clearFetchCache() {
    this._fetchCalls=[];
    this._usedChunks=[];
  }
  //***********************
  @action public initialize(parent: IStores): void {
    this._parent=parent;
    this._initialized=true;
  }
  //***********************
  private resetTimer(): void {
    window.clearTimeout(this._loadTimer);
    this._loadTimer=window.setTimeout(
      () => {
        this.buildFetchCall();
        this.processNextLoad();
      },
      500
    );
  }
  //***********************
  public loadFrame(): void {
    if(!this.initialized) {return;}
    this.resetTimer();
  }
  //***********************
  private processNextLoad(): void {
    const {viewState}= this._parent;
    let call=this._fetchCalls.shift();
    if(!call) {return;}
    this._parent.fetcher.fetch<ISchedule>(call,this.processLoad,this._parent.viewState.debug);
    viewState.invalidateBanners();
  }
  //***********************
  buildFetchCall(): void {
    let sig=this._parent.frame.fetchUrlGet(this._siteId,this._parent.lineupInfo.currentLineup.LineupId,this._usedChunks);
    this._usedChunks.push(...sig.chunks);
    this._fetchCalls.push(...sig.urls);
  }
  //***********************
  @action processLoad=(data: ISchedule): void => {
    const {lineupCacheId}=this._parent.lineupInfo;
    if(lineupCacheId!==data.LineupCacheId) {
      this._parent.lineupInfo.forceGetChannels();
    }
    else {
      data.Channels.forEach(channel => {
        channel.Days.forEach(day => {
          day.Shows.forEach(show => {
            show.startTime=new TimeLine(show.StartTime).valueOf();
            show.endTime=new TimeLine(show.EndTime).valueOf();
            show.channelIndex=channel.ChannelIndex;
            if(!show.Flags){show.Flags={} as IEventFlags;}
            show.Flags.AD=false;
            if(show.AudioStreams) {
              show.AudioStreams.forEach(as => {
                if(as.AudioServiceType==='visually_impaired'){ show.Flags.AD=true; }
              });
            }
          });
        });
        this._parent.lineupInfo.setChannelLineupEvents(channel,0);
      });
    }
    this._loadIndex++;
    this.processNextLoad();
  }
  //***********************
}
// ***********************************