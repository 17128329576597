// ***********************************
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../Types/interfaces';
import Settings from './Settings/settings';
import BodyStandard from './Body/body';
import Legend from '../../Controls/Legend/legend';
import ScheduleNav from './Body/ScheduleNav/schedulenav';
import {DetectIE} from '../../../Scripts/common';
// ***********************************
interface IProps extends IStoresInject { hide:boolean; }
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class ViewStandardPrototype extends React.Component<IProps,{}> {
  //***********************
  componentDidMount(){ 
    let {siteSettings}=this.props;
    document.body.style.overflow=DetectIE()>10&&siteSettings!.enableScrollingBody?'hidden':''; 
  }
  //***********************
  componentWillUnmount(){ document.body.style.overflow=''; }
  //***********************
  render(){
    const { viewState,siteSettings,as }=this.props;
    let esb:boolean=siteSettings!.enableScrollingBody;
    let baseStyle={
      display:this.props.hide?'none':'flex',
      flexDirection:as!.column,
      flexBasis:'auto',
      overflow:as!.hidden
    };
    let viewStyle = esb?{
      overflowY: as!.scroll,
      display:'flex',
      flexDirection:as!.column
    }:{};
    return(
      <div id="defaultView" style={baseStyle}>
        <Settings/>
        <ScheduleNav listener={true} />
        <div id={'primaryViewDiv'} style={viewStyle}>
          <BodyStandard/>
        </div>
        <ScheduleNav listener={false} />
        <Legend genreList={viewState!.genreList}/>
      </div>
    );
  }
  //***********************
}
// ***********************************
