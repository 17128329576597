import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
  fill?: string;
}
// ***********************************
export default class LinkedInVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.size,
      width: this.props.size,
    };
    if(this.props.fill){
      return(
        <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 310 310">
          <path fill={this.props.fill} d="M72.16 99.73H9.927c-2.762 0-5 2.239-5 5v199.928c0 2.762 2.238 5 5 5H72.16c2.762 0 5-2.238 5-5V104.73c0-2.761-2.238-5-5-5zM41.066.341C18.422.341 0 18.743 0 41.362 0 63.991 18.422 82.4 41.066 82.4c22.626 0 41.033-18.41 41.033-41.038C82.1 18.743 63.692.341 41.066.341zM230.454 94.761c-24.995 0-43.472 10.745-54.679 22.954V104.73c0-2.761-2.238-5-5-5h-59.599c-2.762 0-5 2.239-5 5v199.928c0 2.762 2.238 5 5 5h62.097c2.762 0 5-2.238 5-5V205.74c0-33.333 9.054-46.319 32.29-46.319 25.306 0 27.317 20.818 27.317 48.034v97.204c0 2.762 2.238 5 5 5H305c2.762 0 5-2.238 5-5V194.995c0-49.565-9.451-100.234-79.546-100.234z"/>
        </svg>
      );
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 456 456">
        <path fill="#0084B1" d="M0 0h455.731v455.731H0z"/>
        <g fill="#FFF">
          <path d="M107.255 69.215c20.873.017 38.088 17.257 38.043 38.234-.05 21.965-18.278 38.52-38.3 38.043-20.308.411-38.155-16.551-38.151-38.188 0-20.985 17.282-38.105 38.408-38.089zM129.431 386.471H84.71c-5.804 0-10.509-4.705-10.509-10.509V185.18c0-5.804 4.705-10.509 10.509-10.509h44.721c5.804 0 10.509 4.705 10.509 10.509v190.783c-.001 5.803-4.705 10.508-10.509 10.508zM386.884 241.682c0-39.996-32.423-72.42-72.42-72.42h-11.47c-21.882 0-41.214 10.918-52.842 27.606-1.268 1.819-2.442 3.708-3.52 5.658-.373-.056-.594-.085-.599-.075v-23.418c0-2.409-1.953-4.363-4.363-4.363h-55.795c-2.409 0-4.363 1.953-4.363 4.363V382.11c0 2.409 1.952 4.362 4.361 4.363l57.011.014c2.41.001 4.364-1.953 4.364-4.363V264.801c0-20.28 16.175-37.119 36.454-37.348 10.352-.117 19.737 4.031 26.501 10.799 6.675 6.671 10.802 15.895 10.802 26.079v117.808c0 2.409 1.953 4.362 4.361 4.363l57.152.014c2.41.001 4.364-1.953 4.364-4.363V241.682z"/>
        </g>
      </svg>
    );
  }
  //***********************
}