
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component';

interface IProps /*extends IStoresInject*/ {
  adIndex: number,
  pageIndex: number,
  width: number,
  height: number
}
interface IState {
  placementIndex: number;
}
// tslint:disable-next-line
//declare global {interface Window {googletag?: any;}}

@inject((stores: IStores): IStoresInject => ({...stores}))
@observer 
export class FreestarAd extends React.Component<IProps,IState> {
  //***********************  
  constructor(props: IProps) {
    super(props);
    this.state={ 
      placementIndex: props.adIndex+1
    } 
  }
  //***********************  
  buildComponentKey(): string {
    const {pageIndex,width,height}=this.props;
    const {placementIndex}=this.state;
    let result: string=pageIndex.toString()
      .concat('-')
      .concat(placementIndex.toString())
      .concat('-')
      .concat(width.toString())
      .concat('-')
      .concat(height.toString())
    return result;
  }
  //***********************
  buildSlotId(): string {
    const {pageIndex,width,height}=this.props;
    const {placementIndex}=this.state;

    let result: string=pageIndex.toString()
      .concat('-')
      .concat(placementIndex===1?'top':'bottom')
      .concat('-')
      .concat(width.toString())
      .concat('x')
      .concat(height.toString())
    return result;
  }
  //***********************
  render() {
    const {placementIndex}=this.state;

    const publisher: string='titantvguide-com';
    const placementName='titantvguide_affiliate_'+placementIndex.toString();
    const isMobile=true;
    
    let esb: boolean=false;
    
    let style={
      padding: '20px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: '#D5D5D5',
      borderStyle: `solid none ${esb? 'solid':'none'} none`,
      borderWidth: isMobile? '0':'1px',
    }

    return (
      <div style={style} key={this.buildComponentKey()}>
        <FreestarAdSlot
          publisher={publisher}
          placementName={placementName}
          slotId={this.buildSlotId()}
          classList={['m-30', 'p-15', 'b-thin-red']}
          onNewAdSlotsHook={(placementName: any) => console.log('creating ad', placementName)}
          onDeleteAdSlotsHook={(placementName: any) => console.log('destroying ad', placementName)}
          onAdRefreshHook={(placementName: any) => console.log('refreshing ad', placementName)}
        />
      </div>
    );
  }
}