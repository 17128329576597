import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
}
// ***********************************
export default class StereoVector extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {size}=this.props;
    let style={
      height: size,
      width: size,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={size} width={size} viewBox="0 0 504 504">
        <path d="M0 0h504v504H0z" />
        <path fill="#fff" d="M443.62643 295.32502h-.97081v-35.85257c0-104.82505-84.26032-190.113944-187.62704-190.113944-103.3599 0-187.623621 85.288894-187.623621 190.113944v35.85257h-.96741c-14.25912 0-25.86127 10.81657-25.86127 25.28473l.0545 67.04132c0 14.46816 11.60215 26.60096 25.86127 26.60096h1.76451c2.65357 13.59067 12.92722 20.38255 25.18339 20.38255h14.197811c14.25912 0 26.78439-13.50085 26.78439-27.97591V300.14773c0-14.67889-12.76712-25.21218-26.78439-25.21218h-3.37573v-18.19574c0-84.54615 67.40552-153.33554 150.77337-153.33554 83.36103 0 150.76995 68.78939 150.76995 153.33554v18.19574h-3.37232c-14.01727 0-26.7844 10.53329-26.7844 25.21218v106.51785c0 14.4716 12.52187 27.97591 26.7844 27.97591h14.19782c12.25616 0 22.52641-6.79534 25.18338-20.38255h1.76452c14.26593 0 25.86126-12.60955 25.86126-27.0777l.0545-66.15002c-.007-14.46471-11.60214-25.7062-25.86808-25.7062z" />
        <path fill="#fff" d="M346.59940726 350.26008265q0 24.13490358-10.84621049 43.37023937-10.68901242 19.23532506-31.43828224 30.12325585-20.74926982 10.88792004-49.20092841 10.88792004-34.11053768 0-56.27453424-14.88016563-15.71914578-10.70645775-25.6222051-28.490063-9.74587057-17.96507828-9.74587057-34.84136133 0-9.79912482 5.81607714-16.69481002 5.97327521-7.07715824 15.09038144-7.07715824 7.38800195 0 12.418126 5.4439654 5.1873221 5.4439654 8.8027194 16.15042315 4.4013597 12.70257517 9.43149304 21.23145321 5.1873128 8.5288673 14.46160779 14.15429499 9.27429498 5.4439654 24.36467641 5.4439654 20.74926982 0 33.6389714-11.06939309 13.04689034-11.25085536 13.04689034-27.94566538 0-13.2469728-7.07361513-21.41291551-6.91642638-8.34741576-18.07701438-12.70258592-11.00340856-4.35517017-29.55199853-9.2547272-24.83624269-6.71422292-41.65572839-15.60602554-16.66229694-9.07327566-26.56535626-24.49782816-9.74587057-15.60602553-9.74587057-38.65213388 0-21.95731313 10.3746349-39.01505847 10.37463492-17.05774534 30.0235648-26.131021 19.6489299-9.2547272 46.21428616-9.2547272 21.2208454 0 36.62560435 6.1698253 15.56195702 5.98835226 25.77940317 16.1504124 10.21744615 9.98059785 14.93318336 21.04999093 4.71574653 11.06938234 4.71574653 21.5943778 0 9.61767327-5.97327521 17.42068067-5.81608645 7.6215451-14.61880585 7.6215451-8.0167663 0-12.26092793-4.53663245-4.08698219-4.7181055-8.95991747-15.24309022-6.28765272-15.06162792-15.09038143-23.40904367-8.8027194-8.5288673-28.29446053-8.5288673-18.07701439 0-29.2376024 9.2547272-11.00340855 9.07327566-11.00340855 21.95731313 0 7.98448043 3.77259536 13.7913704 3.77259536 5.80690072 10.3746349 9.98059785 6.60203956 4.17370788 13.36127717 6.53276062 6.7592283 2.359042 22.32118532 6.8956852 19.49174114 5.26249236 35.21088691 11.61377995 15.87633453 6.35128759 26.87973378 15.42456325 11.16059732 9.07326491 17.29106129 23.0460976 6.28765272 13.7913704 6.28765272 33.9340284z" />
      </svg>
    );
  }
}