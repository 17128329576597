// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IContributions,IStores,IStoresInject} from '../../../../../Types/interfaces';
//import SocialMedia from '../../../../Controls/Event/SocialMedia/socialmedia';
// ***********************************
interface IProps extends IStoresInject {data: IContributions[]}
interface IState extends IStoresInject {extended: boolean, showMoreHover: boolean}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyContributionss extends React.Component<IProps,IState> {
  //***********************
  constructor(props: IProps) {
    super(props);
    this.state={
      extended: false,
      showMoreHover: false
    }
  }
  //***********************
  handleShowMore = () =>{
    this.setState({extended:!this.state.extended});
  }
  //***********************
  enterShowMore = () =>{
    this.setState({showMoreHover:true});
  }
  //***********************
  leaveShowMore = () =>{
    this.setState({showMoreHover:false});
  }
  //***********************
  render() {
    const {as,proto,data}=this.props;
    const maxContributions=5;

    const ownerStyle={fontSize: '1.1em',margin: '10px 0'};
    const headerDivStyle={
      fontWeight: as!.bold,fontFamily: 'Roboto Slab',
      fontSize: '.9em',color: proto!.textColorDark
    };

    const showMoreStyle={fontSize: '.7em',marginLeft: '10px', cursor:'pointer', textDecoration:this.state.showMoreHover? 'underline':'none'};
    const sectionStyle={fontSize: '.8em',marginLeft: '10px'};

    let contributions=[];

    for(let i: number=0;i<data.length&&i<maxContributions;i++) {
      contributions.push(<div style={sectionStyle}>{data[i].Title}</div>);
    }

    let extendable: boolean=(data.length>maxContributions);
    let extendedContributions=[];

    if(extendable) {
      for(let i: number=maxContributions;i<data.length;i++) {
        extendedContributions.push(<div style={sectionStyle}>{data[i].Title}</div>);
      }
      if(this.state.extended) {
        extendedContributions.push(<div 
          style={showMoreStyle} 
          onClick={this.handleShowMore}
          onMouseEnter={this.enterShowMore}
          onMouseLeave={this.leaveShowMore}
        >Show Less...</div>);
      }
    }

    return (
      <div style={ownerStyle}>
        <div style={headerDivStyle}>Notable Contributions</div>
        {contributions}
        {
          extendable?
            this.state.extended?
              <div>{extendedContributions}</div>
              :
              <div 
                style={showMoreStyle} 
                onClick={this.handleShowMore}
                onMouseEnter={this.enterShowMore}
                onMouseLeave={this.leaveShowMore}
              >Show All...</div>
            :
            ''
        }
      </div>
    );
  }
  //***********************
}
// ***********************************