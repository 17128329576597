// ***********************************
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../Types/interfaces';
import Settings from './Settings/settings';
import BodyExtended from './Body/body';
import Legend from '../../Controls/Legend/legend';
// ***********************************
interface IProps extends IStoresInject { hide:boolean; }
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class ViewExtended extends React.Component<IProps,{}> {
  //***********************
  render(){
    const { viewState }=this.props;
    let baseStyle=this.props.hide?{display:'none'}:{};
    return(
      <div id="defaultView" style={baseStyle}>
        <Settings/>
        <BodyExtended />
        <Legend genreList={viewState!.genreList}/>
      </div>
    );
  }
  //***********************
}
// ***********************************