// ***********************************
import * as React from 'react';
import {observable, action, computed} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../../Types/interfaces';
import Ribbon from './Ribbon/ribbon';
// ***********************************
interface IProps extends IStoresInject {
  width: number; 
  hide: boolean, 
}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Carousel extends React.Component<IProps,{}> {
  //***********************
  @observable private _initialized:boolean;
  @observable private _killed:boolean;
  @observable private _pages:JSX.Element[];
  @computed private get pages():JSX.Element[] {return this._pages;}
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct(){
    this._initialized=true;
    this._killed=false;
  }
  //***********************
  killswitch = ():void  =>{
    this._killed=true;
  }
  //***********************
  setPages = (pages:JSX.Element[]) => {
    this._pages=pages;
  }
  //***********************
  render() {

    if(this._killed){return('');}

    const {siteSettings,hide,as,proto,width}=this.props;
    const {pages}=this;

    if(hide||!this._initialized||!siteSettings!.detailFields.Screenshots) {return ('');}

    const ownerStyle={width: '100%', userSelect:as!.none};
    const headerDivStyle={
      fontWeight: as!.bold,
      fontFamily: 'Roboto Slab',
      fontSize: '1.1em',
      color: proto!.textColorDark
    };
    
    const pageControlStyle={ padding: '0 3px', borderStyle:'solid none solid none', borderWidth: '1px', borderColor: 'black', display:'inline-block', fontSize: '.8em', fontWeight:900};

    return (
      <div style={ownerStyle}>
        <div style={headerDivStyle}>Related Images</div>
        {pages&&pages.length>5?<div style={pageControlStyle}>PAGES: {pages}</div>:''}
        <Ribbon width={width-10} killSwitch={this.killswitch} setPages={this.setPages}/>
      </div>
    );
  }
  //***********************
}
// ***********************************