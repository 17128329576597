// ***********************************
import {inject,observer} from 'mobx-react';
import * as React from 'react';
import {IProgramImage,IStores,IStoresInject} from '../../../../../Types/interfaces';
import {action, observable} from 'mobx';
// ***********************************
interface IProps extends IStoresInject {width: number; compact: boolean;}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyImage extends React.Component<IProps,{}> {
  //***********************
  @observable private programImages: IProgramImage[]|null;
  private imageUri: string;
  private selectedHeight: number;
  private selectedWidth: number;
  private preferredTypes=['TTVP','SRSC','SNSC','SRBA','SNBA'];
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct(): void {
    this.programImages=null;
  }
  //***********************
  private initProgramImages(): void {
    const {siteSettings}=this.props;
    if(!this.programImages&&siteSettings!.detailFields.ProgramImage) {
      const {session,details}=this.props;
      session!.subscribeProgramImages(details!.programId,this.setProgramImages);
    }
  }
  //***********************
  @action private setProgramImages=(images: IProgramImage[]): void => {
    this.programImages=images;
  }
  //***********************
  selectImage(): void {
    
    const {width, compact}=this.props;
    const maxHeight:number=compact?140:400;
    if(!this.programImages) {return;}
    let img:IProgramImage|undefined;
    
    for (let i=0; i< this.preferredTypes.length && !img; i++){
      let code=this.preferredTypes[i];
      img=this.programImages!.find(image => ((compact&&image.ratio<1)||(!compact&&image.ratio>1)) && image.OriginalWidth>width && image.SubTypeCode===code);
    }
    if(!img) {
      for (let i=0; i< this.preferredTypes.length && !img; i++){
        let code=this.preferredTypes[i];
        img=this.programImages!.find(image => image.SubTypeCode===code);
      }
    }
    if(!img) {return;}

    this.imageUri=img.Uri;
    this.selectedHeight=img.OriginalHeight<maxHeight?img.OriginalHeight:maxHeight;
    this.selectedWidth=this.selectedHeight/img.ratio;
    
    if(this.selectedWidth>width){
      this.selectedWidth=width;
      this.selectedHeight=width*img.ratio;
      this.imageUri+='?w='+this.selectedWidth+'&h='+this.selectedHeight;
    }
  }
  //***********************
  componentWillMount() {this.initProgramImages();}
  //***********************
  render() {
    this.selectImage();
    
    const {as,siteSettings,details,masterStyles,viewState, compact}=this.props;

    if(!siteSettings!.detailFields.ProgramImage||!details!.ready) {return ('');}
    // const boxStyle={
    //   ...masterStyles!.flexCenter,
    //   height: this.selectedHeight,
    //   width:this.selectedWidth,
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center',
    //   overflow: as!.hidden,
    //   backgroundImage: `url(${this.imageUri})`,
    //   content: `url(${this.imageUri})`,
    //   boxShadow: viewState!.isMicrosoftBrowser?'':`0 0 10px 10px ${'white'} inset`,
    // };
    const boxStyle={
      ...masterStyles!.flexCenter,
      height: this.selectedHeight,
      width:this.selectedWidth,
      backgroundSize: 'cover',
      backgroundPosition:'center',
      overflow:as!.hidden,
      backgroundImage: `url(${this.imageUri})`,
      boxShadow: viewState!.isMicrosoftBrowser?'':`0 0 10px 10px ${'white'} inset`,
      margin:compact?'none':'auto'
    };

    return (<div style={boxStyle} />);
  }
  //***********************
}
// ***********************************