/* tslint:disable:no-bitwise */
import { observable, computed, action } from 'mobx';
import { ISiteColors, IStores } from '../Types/interfaces';
//***********************
export default class Prototype {
  //***********************
  //@ts-ignore:unread variable
  private _parent: IStores;
  @observable private _protoTypeMode: boolean;
  @observable private _initialized: boolean;
  @observable private _staticColors: ISiteColors;

  @observable private _forceGridMaxHeight: boolean;
  @observable private _forceGridMinHeight: boolean;
  @observable private _maxGridHeight: number;
  @observable private _minGridHeight: number;

  @computed public get protoTypeMode(): boolean { return this._protoTypeMode; }
  
  @computed public get initialized(): boolean { return this._initialized; }
  @computed public get colorHover(): string { return this.staticColors.ControlHover; }
  @computed public get colorBorder(): string { return this.staticColors.Border; }
  @computed public get colorControl(): string { return this.staticColors.Control; }
  @computed public get colorControlLight(): string { return this.staticColors.ControlLight; }
  @computed public get colorWindow(): string { return this.staticColors.Window; }
  @computed public get colorButton(): string { return this.staticColors.Button; }
  @computed public get textColorDark(): string { return this.staticColors.TextDark; }
  @computed public get textColorLight(): string { return this.staticColors.TextLight; }

  @computed public get forceGridMaxHeight(): boolean { return this._forceGridMaxHeight; }
  @computed public get forceGridMinHeight(): boolean { return this._forceGridMinHeight; }
  @computed public get maxGridHeight(): number { return this._maxGridHeight; }
  @computed public get minGridHeight(): number { return this._minGridHeight; }
  @computed public get protoTop(): number { return this._protoTypeMode?22:0; }

  public get staticColors(): ISiteColors { return this._staticColors; }
  public set protoTypeMode(value: boolean) { this.setProtoTypeMode(value); }

  // setters
  public set forceGridMaxHeight(val:boolean) { this.setForceGridMaxHeight(val); }
  public set forceGridMinHeight(val:boolean) { this.setForceGridMinHeight(val); }
  public set maxGridHeight(val:number) { this.setMaxGridHeight(val); }
  public set minGridHeight(val:number) { this.setMinGridHeight(val); }
  // ***********************************
  constructor() {
    this._construct();
    this.setStaticColors();
    this.setDefaults();
  }
  // ***********************************
  @action private _construct(){
    this._initialized = false;
    this._protoTypeMode=this.getBoolLast('protoTypeMode', false);
    this._forceGridMaxHeight = false;
    this._forceGridMinHeight = false;
    this._maxGridHeight = 120;
    this._minGridHeight = 120;
  }
  // ***********************************
  @action public initialize(parent: IStores): void {
    this._parent = parent;
    this._initialized = true;
  }
  // ***********************************
  @action public setStaticColors() {
    this._staticColors = {
      ControlHover: '#3090FF',
      Border: '#D5D5D5',
      ControlLight: '#C0C0C0',
      Control: '#606060',
      Window: '#FFFFFF',
      Button: '#EAEAEA',
      TextDark: '#202020',
      TextLight: '#E0E0E0',
      HdBack: '#000000',
      HdText: '#FFFF00',
      NewBack: '#009900',
      NewText: '#FFFFFF',
      RatingBack: '#707070',
      RatingText: '#FFFFFF',
      CcBack: '#FFFFFF',
      CcText: '#000000',
      RepeatBack: '#4880c4',
      RepeatText: '#FFFFFF'
    };
  }
  // ***********************************
  @action public setDefaults(): void { }
  // ***********************************
  public genreShade(color: string): string {
    let percent: number = 1.5;
    return this.shadeColor(color, percent);
  }
  // ***********************************
  public hoverableColor(hover: boolean, light: boolean = false): string {
    return hover ? this.colorHover : light ? this.colorControlLight : this.colorControl;
  }
  // ***********************************
  private shadeColor(color: string, percent: number): string {
    let f = parseInt(color.slice(1), 16);
    let t = percent < 0 ? 0 : 255;
    let p = percent < 0 ? percent * -1 : percent;
    let R=f>>16;
    // eslint-disable-next-line no-mixed-operators
    let G = f >> 8 & 0x00FF;
    let B = f & 0x0000FF;
    return '#' + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
  }
  // ***********************************
  @action private setForceGridMaxHeight(val: boolean) {
    this._forceGridMaxHeight = val;
  }
  // ***********************************
  @action private setForceGridMinHeight(val: boolean) {
    this._forceGridMinHeight = val;
  }
  // ***********************************
  @action private setMaxGridHeight(val: number) {
    this._maxGridHeight = val;
  }
  // ***********************************
  @action private setMinGridHeight(val: number) {
    this._minGridHeight = val;
  }
  // ***********************************
  @action private setProtoTypeMode(val:boolean){
    this._protoTypeMode=val;
    this.setBool('protoTypeMode', val);
    this.setStaticColors();
  }
  // ***********************************
  private setItem(type: string, val: string) {
    localStorage.setItem(type, val);
  }
  // ***********************************
  private setBool(type: string, val: boolean) {
    this.setItem(type, val ? 'true' : 'false');
  }
  // ***********************************
  //@ts-ignore:unread variable
  private setInt(type: string, val: number) {
    this.setItem(type, val.toString());
  }
  // ***********************************
  private getItemLast(type: string, defaultVal: string): string {
    let result = localStorage.getItem(type);
    return result ? result : defaultVal;
  }
  // ***********************************
  private getBoolLast(type: string, defaultVal: boolean): boolean {
    let result: string = this.getItemLast(type, '');
    return result ? result === 'true' : defaultVal;
  }
  // ***********************************
  //@ts-ignore:unread variable
  private getIntLast(type: string, defaultVal: number): number {
    let result: string = this.getItemLast(type, defaultVal.toString());
    return parseInt(result, 0);
  }
  // ***********************************
}