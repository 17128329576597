// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject,IGenreColorSet} from '../../../Types/interfaces';
import LegendItem from './LegendItem/legenditem';
import {GridColorStyle} from '../../../Types/enums';
// ***********************************
interface IProps extends IStoresInject {genreList: IGenreColorSet[];}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Legend extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {siteSettings,proto}=this.props;
    if(siteSettings!.genreColorStyle===GridColorStyle.None) {return (<div />);}
    let legends: {}[]=[];
    for(let i=0;i<this.props.genreList.length;i++) {
      let col=this.props.genreList[i];
      if(col) {
        legends.push(
          <LegendItem
            key={'LegendItem'+i}
            legendColor={col.BackgroundColor}
            borderColor={proto!.colorBorder}
            textColor={proto!.textColorDark}
            genreText={col.DisplayGenre}
          />
        );
      }
    }
    let bw=legends.length>0? '1px':'0';
    let style={borderColor: proto!.colorBorder,borderWidth: bw,borderStyle: 'solid none none none',width: '100%'};
    return (
      <div style={style}>
        {legends}
      </div>
    );
  }
  //***********************
}