import * as React from 'react';
// ***********************************
interface IProps {
  color: string;
  height: string;
  width: string;
}
// ***********************************
export default class LeftArrowVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.height,
      width: this.props.width,
      fill:this.props.color
    };
    return (
      <svg focusable="false" xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.height} width={this.props.width} viewBox="0 0 255 255">
        <path 
          d="m 200.994,4.716 0.064,-0.064 C 194.129,-1.546 182.974,-1.546 176.077,4.62 L 52.856,115.859 c -6.929,6.198 -6.929,16.241 0,22.407 l 0.636,0.381 122.585,110.953 c 6.897,6.198 18.052,6.198 24.981,0 l 0.127,-0.191 c 3.305,-2.86 5.403,-6.865 5.403,-11.283 V 16.157 c 0,-4.544 -2.161,-8.581 -5.594,-11.441 z" 
        />
      </svg>
    );
  }
  //***********************
}