// ***********************************
export enum BrandingModeEnum_proto {
  None = 0,
  Minimal = 1,
  Verbose = 2
}
// ***********************************
export enum DetailsModeEnum {
  None = 0,
  InGrid = 1,
  InGridWithCast = 2,
  Popup = 3
}
// ***********************************
export enum BannerModeEnum_proto {
  None = 0,
  Standard = 1
}
// ***********************************
export enum ChannelImageEnum {
  None = 0,
  Private = 1,
  Public = 2
}
// ***********************************
export enum EventLinkTypeEnum {
  ProgramInfo = 1,
  LiveStream = 2,
  Twitter = 3,
  Facebook = 5,
  Instagram = 6,
  Pinterest = 7,
  LinkedIn = 999
}
// ***********************************
export enum ViewContext {
  Standard = 0,
  Daily = 1,
  Extended = 2,
  SearchResults = 3,
  z__Details = 4,
  AdvancedSearch = 5,
  Loading = 1000,
  Back = 1001,
  Close = 1002,
  Disabled=1003
}
// ***********************************
export enum TextBrandingStyle {
  None = 0,
  TextOnly = 1,
  TextAndLinks= 2
}
// ***********************************
export enum GridColorStyle {
  None = 0,
  Classic = 1,
  Edge = 2,
  Corner= 3,
  FlagCorner=4
}
// ***********************************
export enum CellType {
  Event = 0,
  EventStub = 1,
  Loader = 2,
  TimeHole = 3
}
// ***********************************
export enum FetchContentType{
  Url = 'application/x-www-form-urlencoded',
  Json= 'application/json'
}
// ***********************************
export enum DstOffset{
  None = 0,
  Forward = 1,
  Backward = 2,
}
// ***********************************
export enum CloseButtonAction{
  Back = 0,
  Close = 1,
}
// ***********************************