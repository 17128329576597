// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import TimeLine from '../../../../Scripts/timeline';
import ScheduleNav from './ScheduleNav/schedulenav';
import Row from './Row/row';
// ***********************************
interface IProps extends IStoresInject {}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyExtended extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {siteSettings,proto,lineupInfo,frame}=this.props;
    let interval=siteSettings!.timeBarRowInterval;
    let rows: JSX.Element[]=[];
    for(let i=1;i<frame!.dates.length;i++) {
      let rowDate=new TimeLine(frame!.minDate);
      let last: boolean=i+1===frame!.dates.length;
      rowDate.day+=i;
      rowDate.hour=frame!.hourIndex;
      rows.push(<Row key={'BodyExtendedRow'+i} dayNumber={i-1} channelIndex={lineupInfo!.channelIndex} date={rowDate} />);
      if(i%7===0&&!last) {rows.push(<ScheduleNav key={'BodyExtendedScheduleNav'+i} />);}
    }
    let themeStyle={
      borderColor: proto!.colorBorder
    };
    return (
      <div style={themeStyle}>
        <ScheduleNav listener={true} />
        {rows}
        {interval>0? <ScheduleNav />:''}
      </div>
    );
  }
  //***********************
}
// ***********************************