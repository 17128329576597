// ***********************************
import {IStores} from '../Types/interfaces';
import {computed,observable,action} from 'mobx';
import {GridColorStyle} from '../Types/enums';
// ***********************************
export default class MasterStyles {
  //***********************
  private _parent: IStores;
  @observable private _initialized: boolean;
  @computed public get initialized(): boolean {return this._initialized;}
  public get noSelect(): {} {return this.getNoSelect();}
  public get flexCenter(): {} {return this.getFlexCenter();}
  public get marginAuto(): {} {return this.getMarginAuto();}
  public get flexCenterInline(): {} {return this.getFlexCenterInline();}
  public get ttvLogo(): {} {return this.getTtvLogoStyle();}
  public get awLogo(): {} {return this.getAwLogoStyle();}
  public get ttvLogoParams(): {height: number,width: number} {return this.getTtvLogoParams();}
  public get awLogoParams(): {height: number,width: number} {return this.getAwLogoParams();}
  public get inlineMiddle(): {} {return this.getInlineMiddle();}
  public get btn(): {} {return this.getBtnStyle();}
  public get flexGrow(): {} {return {flex: 1};}
  public get hdMarker(): {} {return this.getHdStyle();}
  public get ratingMarker(): {} {return this.getRatingStyle();}
  public get newMarker(): {} {return this.getNewStyle();}
  public get ccMarker(): {} {return this.getCcStyle();}
  public get eiMarker(): {} {return this.getEiStyle();}
  public get ADMarker(): {} {return this.getADStyle();}
  public get repeatMarker(): {} {return this.getRepeatStyle();}
  public get timeMarker(): {} {return this.getTimeStyle();}
  public get schedNav(): {} {return this.getSchedNavStyle();}
  public get h1(): {} {return this.getH1();}
  public get componentBase(): {} {return {margin: '4px 0 0 15px',fontSize: 13,...this.noSelect,...this.inlineMiddle};}
  public get componentNoMargin(): {} {return {fontSize: 13,...this.noSelect,...this.inlineMiddle};}
  public get dayStyle(): {} {return {color: '#C0C0C0',fontFamily: 'Anton, Impact',fontSize: '40px',float: 'right',marginRight: '10px',letterSpacing: 1.5};}
  //***********************
  constructor() {
    this._construct();
  }
  //***********************
  @action private _construct() {
    this._initialized=false;
  }
  //***********************
  @action public initialize(parent: IStores): void {
    this._parent=parent;
    this._initialized=true;
  }
  //***********************
  private getLogoStyle(): {} {
    return {
      padding: '2px 5px 2px 0',
      display: 'inline-block',
      verticalAlign: 'middle',
    };
  }
  //***********************
  private getBtnStyle(): {} {
    return {
      width: '40px',
      height: '28px',
      borderStyle: 'solid',
      borderWidth: '1px',
      cursor: 'pointer'
    };
  }
  //***********************
  private getEventFlagStyle(): {} {
    let fontSize=9;
    return {
      zIndex: 4,
      fontFamily: 'Verdana',
      display: 'inline-block',
      margin: '0 0 0 2px',
      padding: '0 2px 0 2px',
      fontSize: fontSize+'px',
      borderRadius: '3px',
      whiteSpace: 'no-wrap'
    };
  }
  //***********************
  private getEiFlagStyle(): {} {
    let fontSize=8;
    return {
      zIndex: 4,
      position: 'relative',
      top: -1,
      fontFamily: 'Verdana',
      display: 'inline-block',
      margin: '-1px 0 0 2px',
      padding: '0 2px 1px 1px',
      fontSize: fontSize+'px',
      borderRadius: '3px',
      whiteSpace: 'no-wrap',
      fontWeight: 'bold'
    };
  }
  //***********************
  private getEventFlagBorder(): {} {
    return {
      borderStyle: 'solid',
      borderWidth: '1px'
    };
  }
  //***********************
  private getHdStyle(): {} {
    let {proto}=this._parent;
    return {
      ...this.getEventFlagStyle(),
      ...this.getEventFlagBorder(),
      fontWeight: this._parent.as.bold,
      backgroundColor: proto.staticColors.HdBack,
      borderColor: proto.staticColors.HdBack,
      color: proto.staticColors.HdText
    };
  }
  //***********************
  private getRatingStyle(): {} {
    let {proto}=this._parent;
    return {
      ...this.getEventFlagStyle(),
      ...this.getEventFlagBorder(),
      fontWeight: this._parent.as.bold,
      backgroundColor: proto.staticColors.RatingBack,
      borderColor: proto.staticColors.RatingBack,
      color: proto.staticColors.RatingText
    };
  }
  private getSchedNavStyle(): {} {
    return {
      display: 'flex',
      verticalAlign: 'middle',
      minHeight: '24px',
      width: '100%',
      whiteSpace: 'nowrap',
      borderWidth: '1px',
      overflow: 'hidden',
      flexGrow:0,
      flexBasis:'24px'
    };
  }
  //***********************
  private getNewStyle(): {} {
    let {proto}=this._parent;
    return {
      ...this.getEventFlagStyle(),
      ...this.getEventFlagBorder(),
      fontWeight: this._parent.as.bold,
      backgroundColor: proto.staticColors.NewBack,
      borderColor: proto.staticColors.NewBack,
      color: proto.staticColors.NewText
    };
  }
  //***********************
  private getCcStyle(): {} {
    let {proto}=this._parent;
    return {
      ...this.getEventFlagStyle(),
      ...this.getEventFlagBorder(),
      fontWeight: 'bold',
      backgroundColor: proto.staticColors.CcBack,
      borderColor: proto.staticColors.CcText,
      color: proto.staticColors.CcText
    };
  }
  //***********************
  private getEiStyle(): {} {
    let {proto}=this._parent;
    return {
      ...this.getEiFlagStyle(),
      ...this.getEventFlagBorder(),
      fontWeight: 'bold',
      backgroundColor: '#dddddd',
      borderColor: '#505050',
      color: proto.staticColors.CcText
    };
  }
  //***********************
  private getADStyle(): {} {
    return {
      position:'relative',
      top: '2px',
      paddingLeft: '2px'
    };
  }
  //***********************
  private getRepeatStyle(): {} {
    let {proto}=this._parent;
    return {
      ...this.getEventFlagStyle(),
      ...this.getEventFlagBorder(),
      backgroundColor: proto.staticColors.RepeatBack,
      borderColor: proto.staticColors.RepeatBack,
      color: proto.staticColors.RepeatText
    };
  }
  private getTimeStyle(): {} {
    return {
      zIndex: 4,
      fontFamily: 'Verdana',
      display: 'inline-block',
      margin: '0 2px',
      borderRadius: '3px',
      whiteSpace: 'no-wrap'
    };
  }
  //***********************
  private getFlexCenter(): {} {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };
  }
  //***********************
  private getFlexCenterInline(): {} {
    return {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    };
  }
  //***********************
  private getMarginAuto(): {} {
    return {
      margin: 'auto',
    };
  }
  //***********************
  private getNoSelect(): {} {
    return {
      userSelect: 'none',
      msUserSelect: 'none',
      MozUserSelect: 'none',
      KhtmlUserSelect: 'none',
      WebkitUserSelect: 'none',
      WebkitTouchCallout: 'none'
    };
  }
  //***********************
  private getInlineMiddle(): {} {
    return {
      display: 'inline-block',
      verticalAlign: 'middle'
    };
  }
  //***********************
  public getMainLinkStyle(hover: boolean): {} {
    let {proto,viewState}=this._parent;
    let fontSize='9px';
    switch(viewState.mediaState) {
      case 0: case 1: case 2: break;
      case 3: case 4: fontSize='10px'; break;
      case 5: case 6: case 7: fontSize='11px'; break;
      case 8: fontSize='12px'; break;
      default: break;
    }
    return {
      cursor: 'pointer',
      fontFamily: 'Verdana',
      fontSize: fontSize,
      fontWeight: 'bold',
      color: hover? proto.staticColors.ControlHover:proto.staticColors.Control,
      WebkitFontSmoothing: 'antialiased'
    };
  }
  //***********************
  private getH1(): {} {
    let {viewState}=this._parent;
    let fontSize='14px';
    switch(viewState.mediaState) {
      case 0: case 1: case 2: break;
      case 3: case 4: fontSize='16px'; break;
      case 5: case 6: case 7: fontSize='18px'; break;
      case 8: fontSize='20px'; break;
      default: break;
    }
    return {
      fontSize: fontSize,
      fontWeight: 'bold'
    };
  }
  //***********************
  private getTtvLogoStyle(): {} {
    let {viewState}=this._parent;
    let esb=this._parent.siteSettings.enableScrollingBody;
    let width=(esb?.75:1)*140;
    switch(viewState.mediaState) {
      case 0: case 1: case 2: width=(esb?.75:1)*90; break;
      default: break;
      case 8: case 7: width=(esb?.75:1)*190; break;
    }
    return {
      ...this.getLogoStyle(),
      width: width.toString()+'px',
    };
  }
  //***********************
  private getAwLogoStyle(): {} {
    let {viewState}=this._parent;
    let esb=this._parent.siteSettings.enableScrollingBody;
    let width=(esb?.75:1)*170;
    switch(viewState.mediaState) {
      case 0: case 1: case 2: width=(esb?.75:1)*120; break;
      default: break;
      case 8: case 7: width=(esb?.75:1)*220; break;
    }
    return {
      ...this.getLogoStyle(),
      width: width.toString()+'px',
    };
  }
  //***********************
  private getTtvLogoParams(): {height: number,width: number} {
    let {viewState}=this._parent;
    let esb=this._parent.siteSettings.enableScrollingBody;
    let width=(esb?.75:1)*140;
    switch(viewState.mediaState) {
      case 0: case 1: case 2: width=(esb?.75:1)*90; break;
      default: break;
      case 8: case 7: width=(esb?.75:1)*190; break;
    }
    return {width: width,height: width*.2436974789915966};
  }
  //***********************
  private getAwLogoParams(): {height: number,width: number} {
    let {viewState}=this._parent;
    let esb=this._parent.siteSettings.enableScrollingBody;
    let width=(esb?.75:1)*170;
    switch(viewState.mediaState) {
      case 0: case 1: case 2: width=(esb?.75:1)*120; break;
      default: break;
      case 8: case 7: width=(esb?.75:1)*220; break;
    }
    return {width: width,height: width*.1742043551088777};
  }
  //***********************
  public getGenreStyle(genreColor: string): {} {
    let {siteSettings}=this._parent;
    let clear: string='rgba(255,0,0,0)';
    switch(siteSettings.genreColorStyle) {
      default: case GridColorStyle.None: return {};
      case GridColorStyle.Classic: return {
        marginLeft: '-100%',
        minHeight: '100%',
        minWidth: '100%',
        backgroundColor: genreColor
      };
      case GridColorStyle.Edge: return {marginLeft: '-20px',minHeight: '100%',minWidth: '20px',backgroundImage: `linear-gradientlinear-gradient(90deg, ${clear}, ${genreColor})`};
      case GridColorStyle.Corner: return {marginLeft: '-25px',minHeight: '25px',minWidth: '25px',backgroundImage: `linear-gradient(45deg, ${clear} 50%, ${genreColor})`};
      case GridColorStyle.FlagCorner: return {marginLeft: '-40px',minHeight: '70px',minWidth: '40px',backgroundImage: `linear-gradient(60.25deg, ${clear} 50%, ${genreColor})`};
    }
  }
  //***********************
}