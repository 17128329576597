// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IContributor,IStores,IStoresInject} from '../../../../../Types/interfaces';
import Image from './bodyimage';
import BodyContributionss from './bodycontributions';
import BodyAwards from './bodyawards';
import BodyRelated from './bodyrelated';
//import Carousel from './Carousel/carousel';
// ***********************************
interface IProps extends IStoresInject {width: number; padding: number; data?: IContributor}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyCore extends React.Component<IProps,{}> {
  //***********************
  @observable private compactImage: boolean;
  componentWillMount() {this.componentWillUpdate();}
  //***********************
  @action componentWillUpdate() {
    const {viewState}=this.props;
    this.compactImage=viewState!.mediaState<3;
  }
  //***********************
  render() {
    const {as,proto,masterStyles,width,padding,data}=this.props;

    const ownerStyle={
      ...masterStyles!.inlineMiddle,
      width: '100%',
      display: 'flex',
      flexDirection: this.compactImage? as!.column:as!.row
    };
    const headerDivStyle={
      fontWeight: as!.bold,fontFamily: 'Roboto Slab',
      fontSize: '1.4em',color: proto!.textColorDark
    };

    const subHeaderDivStyle={
      fontWeight: as!.bold,fontFamily: 'Roboto Slab',
      fontSize: '.9em',color: proto!.textColorDark,marginLeft: '-5px'
    };

    const mainSectionStyle={marginLeft: '10px',display: 'flex'};
    const sectionStyle={};
    const subSectionStyle={marginTop:'10px'};
    const rolesStyle={color: 'SteelBlue',fontSize: '.7em',marginLeft: '10px',marginBottom: '10px'};
    const descriptionStyle={fontSize: '.9em'};
    const datesStyle={fontSize: '.8em',paddingBottom: '5px'};
    const flexDivStyle={flex: this.compactImage? '100%':'50%'};
    const imgWidth=Math.ceil(this.compactImage? width-(padding*2):(width/2)-(padding*2));
    const name: string=data!.FullName;

    //const dateOfDeath: string='';
    const placeOfBirth: string=data!.BirthPlace? `${data!.FirstName} was born ${data!.DateOfBirth? data!.DateOfBirth:''} in ${data!.BirthPlace}.`:'';

    let roleList: string[]=[];
    let roles: string='';
    for(let i=0;i<data!.Contributions.length;i++) {
      let contribution: string=data!.Contributions[i].Type.toUpperCase();
      if(roleList.indexOf(contribution)>-1) {continue;}
      roleList.push(contribution);

      // transform or strip ambiguous contribution roles
      switch(contribution) {
        case 'VOICE OF': contribution='VOICE ACTOR'; break;
        case 'PARTICIPANT': continue;
      }
      if(roles.length>0) {roles+=' • '}
      roles+=contribution;
    }

    let origins='';
    let family='';
    let trade='';
    let acclaim='';
    let trivia=[];
    let quotes=[];

    for(let i=0;i<data!.TextItems.length;i++) {
      let item=data!.TextItems[i];
      switch(item.Type) {
        default: continue;
        case 'origins': origins+=`${origins.length>0? ' ':''}${item.Value}`; break;
        case 'family': family+=`${family.length>0? ' ':''}${item.Value}`; break;
        case 'trade': trade+=`${trade.length>0? ' ':''}${item.Value}`; break;
        case 'acclaim': acclaim+=`${acclaim.length>0? ' ':''}${item.Value}`; break;
        case 'trivia': trivia.push(<div style={{...descriptionStyle,padding: '5px 0'}}>{item.Value}</div>); break;
        case 'quotes': quotes.push(<div style={{...descriptionStyle,padding: '5px 0'}}>{item.Value}</div>); break;
      }
    }

    let dob=data!.DateOfBirth? `(${(data!.DateOfBirth.split(',')[1]+','+data!.DateOfBirth.split(',')[2]).trim()})`:'';

    return (
      <div>
        <div style={ownerStyle}>
          <div style={flexDivStyle}>
            <span style={headerDivStyle}>{name}</span><span style={datesStyle}>&nbsp;{dob}</span>
            <div style={rolesStyle}>{roles}</div>
            <div style={mainSectionStyle}>
              <div style={flexDivStyle}>
                {this.compactImage? <Image width={imgWidth} compact={true} images={data!.Images} />:''}
                {placeOfBirth.length>0||origins.length>0? <div style={subSectionStyle}>
                  <span style={subHeaderDivStyle}>Origins</span>
                  {placeOfBirth?<div style={{...descriptionStyle,padding: '5px 0'}}>{placeOfBirth}</div>:''}
                  <div style={{...descriptionStyle,padding: '5px 0'}}>{origins}</div>
                </div>:''}
                {family.length>0? <div style={subSectionStyle}>
                  <span style={subHeaderDivStyle}>Family</span>
                  <div style={{...descriptionStyle,padding: '5px 0'}}>{family}</div>
                </div>:''}
                {trade.length>0? <div style={subSectionStyle}>
                  <span style={subHeaderDivStyle}>Trade</span>
                  <div style={{...descriptionStyle,padding: '5px 0'}}>{trade}</div>
                </div>:''}
                {acclaim.length>0? <div style={subSectionStyle}>
                  <span style={subHeaderDivStyle}>Acclaim</span>
                  <div style={{...descriptionStyle,padding: '5px 0'}}>{acclaim}</div>
                </div>:''}
                {trivia.length>0? <div style={subSectionStyle}>
                  <span style={subHeaderDivStyle}>Trivia</span>
                  {trivia}
                </div>:''}
                {quotes.length>0? <div style={subSectionStyle}>
                  <span style={subHeaderDivStyle}>Quotes</span>
                  {quotes}
                </div>:''}
              </div>
            </div>
            <div style={sectionStyle}><BodyContributionss data={data!.Contributions} /></div><br />
            <div style={sectionStyle}><BodyAwards data={data!.Awards} /></div><br />
            <div style={sectionStyle}><BodyRelated data={data!.RelatedMaterial} name={data!.FullName} /></div>
          </div>
          {this.compactImage? '':<div style={flexDivStyle}><Image width={imgWidth} compact={false} images={data!.Images} /></div>}
        </div>
        <br />
        {/* <div style={sectionStyle}><Carousel width={width-(padding*2)} hide={this.compactImage} /></div> */}
      </div>
    );
  }
  //***********************
}
// ***********************************