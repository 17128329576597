import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../../Types/interfaces';
import StereoVector from '../../../../../SVG/Ratings/stereo';
// ***********************************
interface IProps extends IStoresInject {active:boolean, vectorSize: number;}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class StereoIndicator extends React.Component<IProps,{}> {
  //***********************
  render() {
    if(!this.props.active) { return (''); }
    
    const {vectorSize}=this.props;

    const ratingDivStyle={
      display: 'inline-block', 
      borderRadius:'3px', 
      marginRight:'4px', 
      height: vectorSize+'px', 
      width: vectorSize+'px'
    };

    return (
      <div style={ratingDivStyle}><StereoVector size={vectorSize} /></div>
    );
  }
  //***********************
}
// ***********************************