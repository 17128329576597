// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IContributorAwards,IStores,IStoresInject} from '../../../../../Types/interfaces';
//import SocialMedia from '../../../../Controls/Event/SocialMedia/socialmedia';
// ***********************************
interface IProps extends IStoresInject {data: IContributorAwards[]}
interface IState extends IStoresInject {extended: boolean,showMoreHover: boolean}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyAwards extends React.Component<IProps,IState> {
  //***********************
  constructor(props: IProps) {
    super(props);
    this.state={
      extended: false,
      showMoreHover: false
    }
  }
  //***********************
  handleShowMore=() => {
    this.setState({extended: !this.state.extended});
  }
  //***********************
  enterShowMore=() => {
    this.setState({showMoreHover: true});
  }
  //***********************
  leaveShowMore=() => {
    this.setState({showMoreHover: false});
  }
  //***********************
  render() {
    const {as,proto,data}=this.props;
    const maxAwards=3;

    const ownerStyle={fontSize: '1.1em',margin: '10px 0'};
    const headerDivStyle={
      fontWeight: as!.bold,fontFamily: 'Roboto Slab',
      fontSize: '.9em',color: proto!.textColorDark
    };
    
    const showMoreStyle={fontSize: '.7em',marginLeft: '10px',cursor: 'pointer',textDecoration: this.state.showMoreHover? 'underline':'none'};
    const gradeStyle={fontSize: '.8em',marginLeft: '10px'};
    const nameStyle={fontSize: '.6em',marginLeft: '16px',marginBottom: '4px'};

    let awards=[];

    for(let i: number=0;i<data.length&&i<maxAwards;i++) {
      let {AwardName,Category,Grade,Year}=data[i];

      let grade: string='';
      switch(Grade) {
        default: grade=''; break;
        case 'nominated': grade='- Nominated'; break;
        case 'winner': grade='- Winner'; break;
      }

      awards.push(<div style={gradeStyle}>{`${ Category } ${ grade }`}</div>);
      awards.push(<div style={nameStyle}>{`${ AwardName } - ${ Year }`}</div>);
    }

    let extendable: boolean=(data.length>maxAwards);
    let extendedAwards=[];
    if(extendable) {
      for(let i: number=maxAwards;i<data.length;i++) {
        let {AwardName,Category,Grade,Year}=data[i];

        let grade: string='';
        switch(Grade) {
          default: grade=''; break;
          case 'nominated': grade='- Nominated'; break;
          case 'winner': grade='- Winner'; break;
        }
  
        extendedAwards.push(<div style={gradeStyle}>{`${ Category } ${ grade }`}</div>);
        extendedAwards.push(<div style={nameStyle}>{`${ AwardName } - ${ Year }`}</div>);
      }
      if(this.state.extended) {
        extendedAwards.push(<div 
          style={showMoreStyle} 
          onClick={this.handleShowMore}
          onMouseEnter={this.enterShowMore}
          onMouseLeave={this.leaveShowMore}
        >Show Less...</div>);
      }
    }

    if(awards.length===0) {return '';}

    return (
      <div style={ownerStyle}>
        <div style={headerDivStyle}>Awards and Recognitions</div>
        {awards}
        {
          extendable?
            this.state.extended?
              <div>{extendedAwards}</div>
              :
              <div 
                style={showMoreStyle} 
                onClick={this.handleShowMore}
                onMouseEnter={this.enterShowMore}
                onMouseLeave={this.leaveShowMore}
              >Show All...</div>
            :
            ''
        }

      </div>
    );
  }
  //***********************
}
// ***********************************
