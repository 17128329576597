// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../Types/interfaces';
import {ViewContext} from '../../Types/enums';
import LoaderLarge from '../Controls/Loaders/LoaderLarge/loaderlarge';
import ViewStandard from './Standard/standard';
import ViewDaily from './Daily/daily';
import ViewExtended from './Extended/extended';
import ViewSearchResults from './SearchResults/searchresults';
import ViewDetails from './Details/details';
import ViewCast from './Cast/cast';
import Header from '../Header/header';
import Footer from '../Footer/footer';
import Proto from '../Controls/Proto/proto';
import Banner from '../Controls/Banner/banner';
import Debug from '../Debug/debug';
import Notices from '../Controls/Notices/notices';
// ***********************************
interface IProps extends IStoresInject {}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Views extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {siteSettings,viewState,proto,as}=this.props;

    const {viewDetails, viewCast}=viewState!;
    let esb=siteSettings!.enableScrollingBody&&viewState!.viewMode===ViewContext.Standard;

    let view: {}={};
    switch(viewState!.viewMode) {
      case ViewContext.Standard: view=<ViewStandard hide={viewDetails} />; break;
      case ViewContext.Daily: view=<ViewDaily hide={viewDetails} />; break;
      case ViewContext.Extended: view=<ViewExtended hide={viewDetails} />; break;
      case ViewContext.SearchResults: view=<ViewSearchResults hide={viewDetails} />; break;
      case ViewContext.Loading:
        return (<LoaderLarge flex={100} last={false} />);
      default: return (<div style={{fontFamily: 'Arial Black',fontSize: '16px',margin: '10px'}}>site disabled / not configured / {viewState!.viewMode}</div>);
    }

    let flexStyle=esb? {display: 'flex',flexDirection: as!.column, height: viewState!.padBorder?'80vh':'100vh'}:{};
    console.log("Notices dynamic check: "+siteSettings!.dynamic);
    let hideNotices:boolean=siteSettings!.dynamic||siteSettings!.siteId===77214;
    return (
      <div style={{}}>
        <div style={{...flexStyle}}>
          {proto!.protoTypeMode&&!viewState!.padBorder? <Proto />:''}
          {proto!.protoTypeMode&&!viewState!.padBorder&&!siteSettings!.enableScrollingBody? <div style={{height: `${proto!.protoTop}px`}}>&nbsp;</div>:''}
          {viewState!.isMobile&&siteSettings!.enableAds? <div style={{height: (viewState!.bannerHeight+2)+'px'}}>&nbsp;</div>:''}
          {siteSettings!.dynamic?'':<Header />}
          {hideNotices?'':<Notices />}
          {siteSettings!.dynamic?'':<Banner placement="header" pageIndex={0}/>}
          {view}
          {viewDetails? 
            (viewCast ? <ViewCast />:<ViewDetails />):''
          }

          {siteSettings!.dynamic?'':<Banner placement="footer" pageIndex={0}/>}
          {siteSettings!.dynamic?'':<Footer />}
          {viewState!.isMobile&&siteSettings!.enableAds? <div style={{height: (viewState!.bannerHeight+2)+'px'}}>&nbsp;</div>:''}
          {viewState!.padBorder? '':<Debug />}
        </div>
      </div>
    );
  }
  //***********************
}
// ***********************************
