// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../../Types/interfaces';
import StarRatingVector from '../../../../../SVG/Ratings/starrating';
// ***********************************
interface IProps extends IStoresInject {starRating: number;}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class StarRating extends React.Component<IProps,{}> {
  //***********************
  //***********************
  active: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    const {siteSettings,starRating}=this.props;
    this.active=siteSettings!.detailFields.StarRating&&starRating>-1;
  }
  //***********************
  render() {
    const {siteSettings,viewState,masterStyles,starRating,as}=this.props;
    if(!siteSettings!.gridFields.TVRatings||!this.active) {return ('');}

    let ratingWidth=75;

    switch(viewState!.mediaState) {
      case 0: case 1: case 2:
        ratingWidth=55;
        break;
      case 3:
        ratingWidth=65;
        break;
      case 4: case 5: default:
        ratingWidth=75;
        break;
    }

    const ratingStyle={...masterStyles!.inlineMiddle,marginLeft: '5px',position: as!.relative,bottom: (ratingWidth/9).toString()+'px'};

    return (
      <div style={ratingStyle}><StarRatingVector width={ratingWidth} rating={starRating} /></div>
    );
  }
  //***********************
}
// ***********************************