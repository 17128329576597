export const styles={
  ddlListStyle: {
    padding: '2px 5px',minWidth: '80px',position: 'absolute' as 'absolute',marginLeft: '12px',
    marginTop: '-9px',textAlign: 'left' as 'left',backgroundColor: 'white',color: '#505050',maxHeight: '260px',
    overflowY: 'scroll' as 'scroll',zIndex: 4
  },
  spanStyle: {
    whiteSpace: 'nowrap' as 'nowrap',display: 'inline-block',maxWidth: '250px',overflow: 'hidden' as 'hidden',
    textOverflow: 'ellipsis',verticalAlign: 'middle'
  },
  ddlStyleBase: {cursor: 'pointer',textAlign: 'right' as 'right',whiteSpace: 'nowrap' as 'nowrap',fontSize: '1.1em'},
  ddlDisplayTextStyleBase: {paddingRight: '5px',marginBottom: '-3px',borderStyle: 'none solid none none',borderWidth: '1px',borderColor: '#505050'},
  ddlItemName: {padding: '0 5px 0 3px'},
  ddlItemDesc: {color: '#909090',fontSize: '.8em',padding: '0 5px 0 10px'},
  ddlItemDisabled: {color: '#DDDDDD',cursor: 'default'},
  ddlItem: {width: '100%',fontSize: '1em',maxWidth: '280px',padding: '2px 0',color: '#000000'},
  ddlItemSelected: {color: 'white',backgroundColor: '#3090FF'}
}