import * as React from 'react';
// ***********************************
interface IProps {
  color: string;
  height: string;
  width: string;
  alt?: boolean;
}
// ***********************************
export default class SearchVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.height,
      width: this.props.width,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.height} width={this.props.width}  viewBox="0 0 330 330">
        <path fill={this.props.color} d="M325.606 304.394L223.328 202.117c16.707-21.256 26.683-48.041 26.683-77.111C250.011 56.077 193.934 0 125.005 0 56.077 0 0 56.077 0 125.006 0 193.933 56.077 250.01 125.005 250.01c29.07 0 55.855-9.975 77.11-26.681l102.278 102.277c2.929 2.93 6.768 4.394 10.607 4.394s7.678-1.464 10.606-4.394c5.859-5.857 5.859-15.355 0-21.212zM30 125.006C30 72.619 72.619 30 125.005 30c52.387 0 95.006 42.619 95.006 95.005 0 52.386-42.619 95.004-95.006 95.004C72.619 220.01 30 177.391 30 125.006z"/>
      </svg>
    );
  }
  //***********************
}
// ***********************************