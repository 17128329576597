import * as React from 'react';
// ***********************************
interface IProps {
  color: string;
  height: string;
  width: string;
}
// ***********************************
export default class RightArrowVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.height,
      width: this.props.width,
      fill: this.props.color
    };
    return (
      <svg focusable="false" xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.height} width={this.props.width} viewBox="0 0 255 255">
        <path 
          d="M 200.732,116.268 200.065,115.887 79.101,4.648 C 72.3,-1.55 61.239,-1.55 54.438,4.648 L 54.343,4.87 c -3.242,2.86 -5.308,6.865 -5.308,11.346 v 222.477 c 0,4.545 2.161,8.581 5.498,11.442 l -0.064,0.064 c 6.833,6.198 17.862,6.198 24.663,0 L 200.764,138.706 c 6.738,-6.133 6.738,-16.208 -0.032,-22.438 z"
        />
      </svg>
    );
  }
  //***********************
}
// ***********************************