// ***********************************
import * as React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { IEventLink, IStores, IStoresInject } from '../../../../../Types/interfaces';
import { EventLinkTypeEnum } from '../../../../../Types/enums';
import FacebookVector from '../../../../SVG/Social/facebook';
import TwitterVector from '../../../../SVG/Social/twitter';
import InstagramVector from '../../../../SVG/Social/instagram';
import LiveStreamVector from '../../../../SVG/Social/livestream';
import WebLinkVector from '../../../../SVG/Social/weblink';
import PinterestVector from '../../../../SVG/Social/pinterest';
import LinkedInVector from '../../../../SVG/Social/linkedin';
// ***********************************
interface IProps extends IStoresInject {
   link: IEventLink; 
   size: number; 
  }
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class SocialMediaBtn extends React.Component<IProps, {}> {
  //***********************
  @observable private hover: boolean;
  @observable private active: boolean;
  private mouseUpBinding:any;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  @action _construct(){
    this.hover = false;
    this.active = false;
    this.mouseUpBinding=this.handleMouseUp.bind(this);
  }
  //***********************
  handleClick = (e: React.FormEvent<HTMLElement>): boolean => {
    const {link}=this.props;
    e.stopPropagation();
    if(link.Uri) { window.open(link.Uri, '_blank'); }
    return false;
  }
  //***********************
  @action handleMouseEnter = (): void => { this.hover = true; };
  @action handleMouseLeave = (): void => { this.hover = false; };
  //***********************
  @action handleMouseDown = (): void => { this.active = true; };
  @action handleMouseUp = (): void => { this.active = false; };
  //***********************
  componentDidMount() { window.addEventListener('mouseup', this.mouseUpBinding, false); }
  componentWillUnmount(){ window.removeEventListener('mouseup', this.mouseUpBinding, false); }
  //***********************
  getVector(): JSX.Element | null {
    const { link, size } = this.props;
    switch (link.Type) {
      case EventLinkTypeEnum.Facebook: return <FacebookVector size={size} />;
      case EventLinkTypeEnum.Twitter: return <TwitterVector size={size} />;
      case EventLinkTypeEnum.Instagram: return <InstagramVector size={size} />;
      case EventLinkTypeEnum.Pinterest: return <PinterestVector size={size} />;
      case EventLinkTypeEnum.LinkedIn: return <LinkedInVector size={size} />;
      case EventLinkTypeEnum.LiveStream: return <LiveStreamVector size={size} fill="#000000" />;
      case EventLinkTypeEnum.ProgramInfo: return <WebLinkVector size={size} />;
      default: return null;
    }
  }
  //***********************
  render() {
    const { link, size, as } = this.props;
    let vector = this.getVector();
    if (!vector) { return (<div />); }

    let itemSize = this.hover ? size : size - 2;
    itemSize = this.active ? size - 2 : itemSize;

    let baseStyle = {
      overflow: as!.hidden,
      display: 'inline-block',
      borderRadius: '4px',
      transition: 'margin .1s, height .1s, width .1s, border-radius .1s'
    };

    let itemStyle = {
      ...baseStyle,
      margin: this.hover && !this.active ? '1px' : '2px',
      cursor: this.hover && !this.active ? 'pointer' : 'default',
      height: itemSize + 'px',
      width: itemSize + 'px'
    };
    return (
      <div
        style={itemStyle}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        title={link.Text}
      >
        {vector}
      </div>
    );
  }
  //***********************
}
// ***********************************
