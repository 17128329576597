import * as React from 'react';
// ***********************************
interface IProps {
  color: string;
  width: number;
}
// ***********************************
export default class MoreContentVector extends React.Component<IProps, {}> {
  //***********************
  height:number;
  constructor(props:IProps){
    super(props);
    this.height=this.props.width*9.481481481481481;
  }
  render() {
    let style={
      fill:this.props.color,
      width: this.props.width,
      height: this.height
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg"  style={style} width={this.props.width} height={this.height} viewBox="0 0 54 512">
        <g transform="translate(-229)">
          <circle cx="77" cy="256" r="27" transform="rotate(90 256 256)"/>
          <circle cx="256" cy="-256" r="27" transform="rotate(90)"/>
          <circle cx="435" cy="256" r="27" transform="rotate(90 256 256)"/>
        </g>
      </svg>
    );
  }
  //***********************
  // <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.height} width={this.props.width} viewBox="0 0 50 210">
  //   <path d="M35 0H15C6.716 0 0 6.716 0 15v20c0 8.284 6.716 15 15 15h20c8.284 0 15-6.716 15-15V15c0-8.284-6.716-15-15-15zm0 80H15C6.716 80 0 86.716 0 95v20c0 8.284 6.716 15 15 15h20c8.284 0 15-6.716 15-15V95c0-8.284-6.716-15-15-15zm0 80H15c-8.284 0-15 6.716-15 15v20c0 8.284 6.716 15 15 15h20c8.284 0 15-6.716 15-15v-20c0-8.284-6.716-15-15-15z"/>      
  // </svg>

  //<path d="M115 0H95c-8.284 0-15 6.716-15 15v20c0 8.284 6.716 15 15 15h20c8.284 0 15-6.716 15-15V15c0-8.284-6.716-15-15-15zM115 80H95c-8.284 0-15 6.716-15 15v20c0 8.284 6.716 15 15 15h20c8.284 0 15-6.716 15-15V95c0-8.284-6.716-15-15-15zM115 160H95c-8.284 0-15 6.716-15 15v20c0 8.284 6.716 15 15 15h20c8.284 0 15-6.716 15-15v-20c0-8.284-6.716-15-15-15z"/>
}