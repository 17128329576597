// ***********************************
import {inject,observer} from 'mobx-react';
import * as React from 'react';
import {IContributorImage, IStores,IStoresInject} from '../../../../../Types/interfaces';
// ***********************************
interface IProps extends IStoresInject {width: number; compact: boolean; images: IContributorImage[]}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyImage extends React.Component<IProps,{}> {
  //***********************
  private imageUri: string;
  private imageHeight: number;
  private imageWidth: number;
  //***********************
  constructor(props: IProps) {
    super(props);
    this.imageUri=this.props.images[0]?this.props.images[0].URL:'';
  }
  //***********************
  selectImage(): void {
    let ratio=1080/1440;
    this.imageHeight=480;
    this.imageWidth=this.imageHeight*ratio;
  }
  //***********************
  render() {
    this.selectImage();
    const {as,masterStyles,viewState,compact}=this.props;

    const boxStyle={
      ...masterStyles!.flexCenter,
      height: this.imageHeight,
      width:this.imageWidth,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      overflow: as!.hidden,
      backgroundImage: `url(${this.imageUri})`,
      boxShadow: viewState!.isMicrosoftBrowser? '':`0 0 10px 10px ${'white'} inset`,
      margin: compact? 'none':'auto'
    };

    return (<div style={boxStyle} />);
  }
  //***********************
}
// ***********************************