// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IContributor, IStores,IStoresInject} from '../../../Types/interfaces';
import BodyCast from './Body/body';
import {DetectIE} from '../../../Scripts/common';
import ScreenShot from './ScreenShot/screenshot';
import LoaderLarge from '../../Controls/Loaders/LoaderLarge/loaderlarge';
// ***********************************
interface IProps extends IStoresInject { }
interface IState {
  dataFetched: boolean;
  contributorData?: IContributor;
  noData: boolean;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class ViewCast extends React.Component<IProps,IState> {
  //***********************
  constructor(props: IProps) {
    super(props);
    this.state={ 
      dataFetched: false,
      contributorData: undefined,
      noData: false
    } 
  }
  //***********************
  componentDidMount() {
    let {fetcher,details, viewState,lineupInfo}=this.props;
    // let channelId: number=10034;
    // let programId: number=32891553;
    // let personId: number=512;

    let channelId: number=lineupInfo!.currentChannel.ChannelId;
    let programId: number=details!.programId;
    let personId: string=viewState!.castId;

    let fetchUrl=`data/contributor/${ channelId }/${ programId }/${ personId }`;
    fetcher!.fetch<IContributor>(fetchUrl,this.handleContributorData,false);
  }
  //***********************
  handleContributorData=(data: IContributor) => {
    if(data.Exception) { this.setState({dataFetched: true, noData: true}) }
    else { this.setState({dataFetched: true,contributorData: data}); }
    
  }
  //***********************
  componentWillUnmount(){ 
    let {siteSettings,viewState}=this.props;
    document.body.style.overflow=DetectIE()>10&&siteSettings!.enableScrollingBody?'hidden':'';
    viewState!.invalidateBanners();
  }
  //***********************
  render() {
    if(!this.state.dataFetched) {
      return (<LoaderLarge flex={100} last={false} />);
    }
    const {as,viewState,masterStyles,siteSettings,proto}=this.props;
    let baseStyle={
      backgroundColor:'#CCCCCC',
      borderTop:siteSettings!.enableScrollingBody?'':`solid 1px ${proto!.colorBorder}`
    };
    let detailStyle={
      ...masterStyles!.flexCenter,
      flexDirection:as!.column
    };
    return (
      <div style={baseStyle}>
        <div style={detailStyle}>
          {viewState!.screenShot?<ScreenShot />:''}
          <BodyCast data={this.state.contributorData} width={viewState!.rawWidth} noData={ this.state.noData } />
        </div>
      </div>
    );
  }
  //***********************
}
// ***********************************
