// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../Types/interfaces';
import {AutoTextColor} from '../../../../../Scripts/common';
import HourNav from '../../../../Controls/HourNav/hournav';
import ExtendedBar from './ExtendedBar/extendedbar';
// ***********************************
interface IProps extends IStoresInject {listener?: boolean;}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class ScheduleNav extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {siteSettings,proto,masterStyles}=this.props;
    let esb: boolean=siteSettings!.enableScrollingBody;
    let schedStyle={
      ...masterStyles!.schedNav,
      borderStyle: `${esb?'none':'solid'} none none`,
      fontSize: '16px',
      backgroundColor: proto!.colorButton,
      backgroundBlendMode: 'luminosity',
      borderColor: proto!.colorBorder,
      color: AutoTextColor(proto!.colorControlLight,proto!.textColorDark,proto!.textColorLight),
      filter: 'none'
    };

    return (
      <div style={schedStyle} >
        <ExtendedBar />
        <HourNav listener={this.props.listener? this.props.listener:false} />
      </div>
    );
  }
  //***********************
}
// ***********************************
