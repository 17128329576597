import * as React from 'react';
// ***********************************
interface IProps {
  color: string;
  height: string;
  width: string;
  alt?: boolean;
}
// ***********************************
export default class SearchAdvVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.height,
      width: this.props.width,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.height} width={this.props.width}  viewBox="0 0 330 330">
        <g fill={this.props.color}>
          <path d="M325.606 304.394L223.328 202.117c16.707-21.256 26.683-48.041 26.683-77.111C250.011 56.077 193.934 0 125.005 0 56.077 0 0 56.077 0 125.006 0 193.933 56.077 250.01 125.005 250.01c29.07 0 55.855-9.975 77.11-26.681l102.278 102.277c2.929 2.93 6.768 4.394 10.607 4.394s7.678-1.464 10.606-4.394c5.859-5.857 5.859-15.355 0-21.212zM30 125.006C30 72.619 72.619 30 125.005 30c52.387 0 95.006 42.619 95.006 95.005 0 52.386-42.619 95.004-95.006 95.004C72.619 220.01 30 177.391 30 125.006z"/>
          <path d="M167.50632 99.525375l7.21715-10.152957c1.34559-1.896034 1.16208-4.464855-.48929-6.116238l-4.34252-4.342529-4.34254-4.34253c-1.65138-1.467897-4.2202-1.712547-6.05508-.366974l-10.15294 7.217162c-4.22023-2.446496-8.62391-4.34253-13.27225-5.504615l-2.07952-12.293641c-.36698-2.263007-2.38534-3.914392-4.64833-3.914392h-12.23249c-2.26301 0-4.2202 1.651385-4.64834 3.914392l-2.07951 12.293641c-4.70952 1.162085-9.29669 3.058119-13.578051 5.688102l-10.091795-7.155999c-1.896034-1.345573-4.464855-1.162085-6.116238.489299l-4.342531 4.34253-4.342528 4.342528c-1.590223 1.590223-1.834872 4.220206-.489298 6.11624l7.278323 10.275266c-2.446497 4.22021-4.220206 8.74622-5.443454 13.27224l-12.232479 1.9572c-2.263007.36698-3.914392 2.38533-3.914392 4.64835v12.23246c0 2.26302 1.651385 4.2202 3.914392 4.64835l12.354805 2.07952c1.223248 4.58718 3.05812 9.05204 5.565777 13.21108l-7.156001 10.09178c-1.34557 1.89604-1.162084 4.46487.489301 6.11624l4.342529 4.34254 4.34253 4.34254c1.590222 1.5902 4.220204 1.83486 6.116238.48927l10.275271-7.2783c4.220201 2.44649 8.746221 4.2202 13.272231 5.44344l2.07952 12.17131c.36697 2.26301 2.38534 3.91439 4.64834 3.91439H129.58563c2.26302 0 4.22022-1.65138 4.64836-3.91439l2.07951-12.35479c4.46486-1.16209 8.86854-2.99697 12.90526-5.38229l10.15296 7.21716c1.89603 1.34556 4.46486 1.16208 6.11623-.4893l4.34253-4.34254 4.34254-4.34253c1.59023-1.59021 1.83486-4.22019.4893-6.11622l-7.21716-10.15297c2.4465-4.22019 4.34253-8.6239 5.5046-13.27223l12.29365-2.07953c2.263-.36696 3.91439-2.38533 3.91439-4.64834l.0613-6.1774v-6.11623c0-2.26302-1.6514-4.22022-3.91438-4.64834l-12.29365-2.07954c-1.16209-4.64833-3.05811-9.05263-5.5046-13.272215zm-23.73101 46.115815c-11.19273 11.19271-29.35795 11.19271-40.55066 0-11.192711-11.19271-11.192711-29.35794 0-40.55066 11.19271-11.192705 29.35793-11.192705 40.55066 0 11.19272 11.19272 11.19272 29.35856 0 40.55066z"/>
        </g>
      </svg>
    );
  }
  //***********************
}
// ***********************************