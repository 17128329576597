import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
}
// ***********************************
export default class EIVector extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {size}=this.props;
    let style={
      height: size,
      width: size,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={size} width={size} viewBox="0 0 256 256">
        <g transform="translate(23 -20.5)">
          <circle cx="105" cy="148.5" r="128"/>
          <circle cx="105" cy="148.5" r="115" fill="#e6e6e6"/>
          <path d="M98.952 28.39h12.095v240.22H98.952z"/>
          <path d="M45.344 112.44c-11.466 0-21.14 4.05-29.022 12.154-7.882 8.103-11.824 18.19-11.824 30.261 0 12.072 3.913 21.965 11.74 29.682 7.828 7.717 18.08 11.576 30.758 11.576 8.71 0 16.068-1.763 22.076-5.29 6.064-3.584 11.438-9.51 16.123-17.778l-6.035-3.72c-4.465 6.945-8.82 11.74-13.064 14.386-4.245 2.646-8.736 3.969-13.477 3.969-6.614 0-12.045-2.563-16.289-7.69-4.19-5.181-6.366-13.201-6.531-24.06H84.12c-.165-9.977-1.846-18.08-5.043-24.309-3.142-6.284-7.58-11.052-13.312-14.305-5.678-3.252-12.485-4.877-20.422-4.877zm1.57 7.523c4.52 0 8.24 1.763 11.162 5.29 2.922 3.474 4.383 8.408 4.383 14.802 0 3.197-.525 5.402-1.572 6.615-1.048 1.213-2.784 1.818-5.21 1.818H29.8c.386-9.536 1.984-16.673 4.795-21.414 2.866-4.74 6.973-7.111 12.32-7.111z" />
          <path d="M159.47 74.24c-3.748 0-6.945 1.323-9.591 3.969-2.646 2.59-3.969 5.76-3.969 9.508 0 3.693 1.323 6.862 3.969 9.508 2.646 2.645 5.843 3.968 9.592 3.968 3.693 0 6.862-1.294 9.508-3.884 2.645-2.646 3.968-5.844 3.968-9.592s-1.323-6.917-3.968-9.508c-2.646-2.646-5.815-3.969-9.508-3.969zm13.147 43.076l-34.396 1.573v7.523h3.472c2.867 0 5.1.771 6.698 2.315 1.653 1.543 2.48 3.722 2.48 6.533v44.812c0 3.142-.579 5.348-1.736 6.615-1.103 1.268-3.031 1.903-5.787 1.903h-5.127v7.69h46.468v-7.69h-5.209c-2.315 0-4.051-.47-5.209-1.406-1.102-.993-1.654-2.371-1.654-4.135z" />
        </g>
      </svg>
    );
  }
}