// ***********************************
import * as React from 'react';
import {action} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import ScheduleNav from './ScheduleNav/schedulenav';
import Row from './Row/row';
import ShadowRow from './ShadowRow/shadowrow';
import {swipedetect} from '../../../../Scripts/swipe';
import {WheelUp} from '../../../../Scripts/common';
import InGridAd from'./InGridAd/ad'
// ***********************************
interface IProps extends IStoresInject {}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyStandard extends React.Component<IProps,{}> {
  //***********************
  private _id: number;
  private _wheelEventType: string;
  private _threshold: number=150;
  private _nextScroll: number=0;
  private _scrollWait: number=250;
  private _scrollBy: number=5;
  private _adIndex:number=0;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._wheelEventType='onwheel' in document.createElement('div')? 'wheel':'mousewheel';
    this.initMaxRenderRows();
  }
  //***********************
  //*********************************************************************
  //***********************
  get adIndex():number{
    const {viewState}=this.props;
    return viewState!.uniqueIndexer;
  }
  @action initMaxRenderRows() {
    const {frame}=this.props;
    frame!.lastRenderRow=frame!.renderRowCount;
  }
  @action incLastRenderRow(el: HTMLDivElement|null=null) {
    const {frame}=this.props;
    let dt=new Date();
    if(dt.getTime()>=this._nextScroll) {
      let max=this.props.lineupInfo!.channels.length;
      frame!.lastRenderRow=(frame!.lastRenderRow+this._scrollBy)<max? frame!.lastRenderRow+this._scrollBy:max;
      this._nextScroll=dt.getTime()+this._scrollWait;
    }
  }
  //***********************
  @action decLastRenderRow(el: HTMLDivElement|null=null) {
    const {frame}=this.props;
    let dt=new Date();
    if(dt.getTime()>=this._nextScroll) {
      let min=frame!.renderRowCount;
      frame!.lastRenderRow=(frame!.lastRenderRow-this._scrollBy>min)? frame!.lastRenderRow-this._scrollBy:min;
      this._nextScroll=dt.getTime()+this._scrollWait;
    }
  }
  //***********************
  updateMaxRenderRows=() => {this.initMaxRenderRows();}
  //***********************
  componentDidMount() {
    let elem=document.getElementById('defaultView');
    swipedetect(elem,(swipedir: string) => {
      let scroll=false;
      let scrollUp=false;
      switch(swipedir) {
        case 'up':
          scroll=true;
          break;
        case 'down':
          scroll=true;
          scrollUp=true;
          break;
        default: break;
      }
      if(scroll) {
        let el=document.getElementById('primaryViewDiv') as HTMLDivElement;
        if(siteSettings!.enableScrollingBody&&el) {this.checkGridScroll(el,scrollUp);}
        else {this.checkPageScroll(scrollUp);}
      }
    });

    const {siteSettings}=this.props;
    this._id=this.props.lineupInfo!.subscribeLineupChange(this.updateMaxRenderRows);
    if(siteSettings!.enableScrollingBody) {
      document.getElementById('primaryViewDiv')!.addEventListener(this._wheelEventType,this.handleGridWheel);
    }
    else {
      window.addEventListener(this._wheelEventType,this.handlePageWheel);
      this.checkPageScroll();
    }
  }
  //***********************
  componentWillUnmount() {
    const {siteSettings}=this.props;
    this.props.lineupInfo!.unSubscribeLineupChange(this._id);
    if(siteSettings!.enableScrollingBody) {
      document.getElementById('primaryViewDiv')!.removeEventListener(this._wheelEventType,this.handleGridWheel);
    }
    else {
      window.removeEventListener(this._wheelEventType,this.handlePageWheel);
    }
  }
  //***********************
  handlePageWheel=(e: WheelEvent) => {
    let wheelUp=WheelUp(e,this._wheelEventType);
    this.checkPageScroll(wheelUp);
  }
  //***********************
  handleGridWheel=(e: WheelEvent) => {
    let el=document.getElementById('primaryViewDiv') as HTMLDivElement;
    if(el) {
      let wheelUp=WheelUp(e,this._wheelEventType);
      this.checkGridScroll(el,wheelUp);
    }
  }
  //***********************
  scrollDown=() => {this.incLastRenderRow();}
  scrollUp=() => {this.decLastRenderRow();}
  //***********************
  checkPageScroll(scrollUp: boolean=true) {
    if(scrollUp) {
      let nearTop=(window.scrollY<this._threshold);
      if(nearTop) {this.decLastRenderRow();}
    }
    else {
      let nearBottom=(window.innerHeight+Math.ceil(window.pageYOffset))>=(document.body.scrollHeight-this._threshold);
      if(nearBottom) {this.incLastRenderRow();}
    }
  }
  //***********************
  checkGridScroll(el: HTMLDivElement,scrollUp: boolean=true) {
    if(scrollUp) {
      let nearTop=(el.scrollTop<this._threshold);
      if(nearTop) {this.decLastRenderRow();}
    }
    else {
      let nearBottom=el.scrollTop>=el.scrollHeight-(el.offsetHeight+this._threshold);
      if(nearBottom) {this.incLastRenderRow();}
    }
  }
  //***********************
  //*********************************************************************
  //***********************
  render() {
    const {lineupInfo,siteSettings,frame}=this.props;
    if(lineupInfo!.lineupIndex===-1) {return ('');}
    let interval=siteSettings!.timeBarRowInterval;
    let adInterval=siteSettings!.inGridAdInterval;
    let adOff=siteSettings!.inGridAdOffset;
    let internalNavRow: boolean=false;
    let inGridAdRow: boolean=false;
    let rows: any[]=[];
    let renderStart=frame!.getRenderStart();
    if(lineupInfo!.channels.length>0) {
      for(let i: number=renderStart;i<frame!.lastRenderRow&&i<lineupInfo!.channels.length;i++) {
        rows.push(<Row key={'Row'+i} channelIndex={i} firstIndex={renderStart} />);
        if(interval>0&&interval<9999) {
          internalNavRow=(i+1)%interval===0&&i+1<frame!.lastRenderRow&&i+1<lineupInfo!.channels.length;
          if(internalNavRow&&!siteSettings!.enableScrollingBody) {rows.push(<ScheduleNav key={'ScheduleNav'+i} />);}
        }
        if(siteSettings!.inGridAdInterval>0) {
          inGridAdRow=(i+1-adOff)%adInterval===0&&i+1<frame!.lastRenderRow&&i+1<lineupInfo!.channels.length;
          if(inGridAdRow){rows.push(<InGridAd index={this.adIndex} borderTop={true} />);}
        }
      }
    }
    return (
      <div>
        {renderStart>0? <ShadowRow scrollUp={true} click={this.scrollUp} />:''}
        {rows}
        {frame!.lastRenderRow<lineupInfo!.channels.length? <ShadowRow scrollUp={false} click={this.scrollDown} />:''}
      </div>
    );
  }
  //***********************
}
// ***********************************