import '../loader.css';
// ***********************************
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../../Types/interfaces';
// ***********************************
interface IProps extends IStoresInject {
  flex: number;
  last: boolean;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class LoaderLarge extends React.Component<IProps, {}>{
  //***********************
  render() {
    const { proto, flex, masterStyles, as } = this.props;
    let style = {
      ...masterStyles!.noSelect,
      paddingTop: '200px',
      paddingBottom: '200px',
      boxSizing: as!.borderBox,
      borderColor: proto!.colorBorder,
      borderRightStyle: this.props.last ? as!.none : as!.solid,
      flex: flex.toString() + '%',
      borderWidth: '1px',
      display: 'flex',
      alignItems: as!.center,
      justifyContent: as!.center,
      width: '100%'
    };
    return (
      <div style={style}>
        <div className="loader-large" />
        <div className="loader-large-text">LOADING</div>
      </div>
    );
  }
  //***********************
}