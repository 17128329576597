// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../Types/interfaces';
import SocialMedia from '../../../../Controls/Event/SocialMedia/socialmedia';
// ***********************************
interface IProps extends IStoresInject {}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyLinks extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {as,proto,details}=this.props;

    let prog=details!.program;
    if(prog.Links.length<1){return('');}

    const ownerStyle={fontSize: '1.1em',margin: '10px 0'};
    const headerDivStyle={
      fontWeight: as!.bold,fontFamily: 'Roboto Slab',
      fontSize: '1.1em',color: proto!.textColorDark
    };
    const sectionStyle={marginLeft: '10px'};

    return (
      <div style={ownerStyle}>
        <div style={headerDivStyle}>Related Links</div>
        <div style={sectionStyle}><SocialMedia item={prog} large={true} /></div>
      </div>
    );
  }
  //***********************
}
// ***********************************