// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../Types/interfaces';

// ***********************************
interface IProps extends IStoresInject {}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Proto extends React.Component<IProps,{}> {
  private protoDiv: HTMLDivElement;
  //***********************
  componentDidMount() {
    if(!this.protoDiv) {return;}
    this.protoDiv.innerHTML='<marquee behavior="alternate" direction="left" scrollamount="1">'+
      '<div style="border-radius: 2px;background:linear-gradient(#ff0000,#cc0000,#990000); color: white; '+
      'display:inline-block; padding: 1px 4px; user-select:none;">PROTOTYPE&nbsp;&nbsp;MODE</div></marquee>';
  }
  //***********************
  componentDidUpdate() {
    this.componentDidMount();
  }
  //***********************
  render() {
    const {siteSettings,as}=this.props;
    let esb=siteSettings!.enableScrollingBody;

    const protoStyle={
      position: esb? as!.static:as!.fixed,width: '100%',height: '22px',minHeight: '22px',fontFamily: 'Work Sans',padding: '1px 1px 1px 10px',
      background: 'linear-gradient(#555555, #bbbbbb,#888888)',boxSizing: as!.borderBox,fontSize: '1.5em',zIndex: 99999,
      fontWeight: as!.bold,borderBottom: '1px solid #555555',flexGrow: 0,flexBasis: 'auto'
    };

    return (
      <div style={protoStyle} ref={(el) => {this.protoDiv=el!;}} />
    );
    //***********************
  }
  // ***********************************
}
