import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../../Types/interfaces';
import EiVector from '../../../../../SVG/Ratings/ei';
// ***********************************
interface IProps extends IStoresInject {active:boolean; vectorSize: number; min: number; max:number;}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class EiIndicator extends React.Component<IProps,{}> {
  //***********************
  active: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    const {siteSettings,active}=this.props;
    this.active=siteSettings!.detailFields.EIDisplay&&active;
  }
  //***********************
  render() {
    if(!this.active) { return (''); }
    const {vectorSize}=this.props;

    const ratingDivStyle={
      display: 'inline-block', 
      borderRadius:'3px', 
      marginTop:'10px',
      height: vectorSize+'px', 
      width: vectorSize+'px'
    };

    return (
      <div style={ratingDivStyle}>
        <EiVector size={vectorSize} />
      </div>
    );
  }
  //***********************
}
// ***********************************