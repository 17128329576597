// string constants
export default class AbsoluteStyles {
  public get column() {return 'column' as 'column';}
  public get row() {return 'row' as 'row';}
  public get bold() {return 'bold' as 'bold';}
  public get normal() {return 'normal' as 'normal';}
  public get hidden() {return 'hidden' as 'hidden';}
  public get visible() {return 'visible' as 'visible';}
  public get absolute() {return 'absolute' as 'absolute';}
  public get center() {return 'center' as 'center';}
  public get flexStart() {return 'flex-start' as 'flex-start';}
  public get relative() {return 'relative' as 'relative';}
  public get ellipsis() {return 'ellipsis' as 'ellipsis';}
  public get italic() {return 'italic' as 'italic';}
  public get fixed() {return 'fixed' as 'fixed';}
  public get left() {return 'left' as 'left';}
  public get right() {return 'right' as 'right';}
  public get static() {return 'static' as 'static';}
  public get borderBox() {return 'border-box' as 'border-box';}
  public get none() {return 'none' as 'none';}
  public get solid() {return 'solid' as 'solid';}
  public get breakWord(){return 'break-word' as 'break-word';}
  public get noWrap(){return 'nowrap' as 'nowrap';}
  public get scroll(){return 'scroll' as 'scroll';}
  public get auto(){return 'auto' as 'auto';}
}