import * as React from 'react';
// ***********************************
interface IProps {
  height: number;
  width: number;
}
// ***********************************
export default class TitanTvVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height={this.props.height} width={this.props.width} viewBox="0 0 357 87">
        <path fill="#E1251B" d="M346.5 13.5L258.5.9c-3.4-.5-6.7.5-9.3 2.7-2.6 2.2-4 5.4-4 8.8v63.4c0 3.4 1.5 6.6 4 8.8 2.1 1.9 4.9 2.9 7.7 2.9.5 0 1.1 0 1.7-.1l88-12.6c5.8-.8 10-5.8 10-11.6V25.1c0-5.8-4.3-10.7-10.1-11.6z"/>
        <path fill="#011E41" d="M56.1 60.8V27.6h15v33.2h-15zM74.3 27.6v6.6h17.9v26.6h14.9V38l-10.4-3.8H125v-6.6M2.3 27.6v6.6h17.8v26.6h15V38l-10.4-3.8H53v-6.6M147.4 34.2v-.1l2.1 3.8 9.1 16.4 1.4 2.5 2.2 4h15.6l-20.4-33.2h-19.9l-4.1 6.6"/>
        <path fill="#011E41" d="M150.8 48.1h-11.1l5.6-10.1h-14.2l-13.9 22.8h15.6l3.6-6.5h17.8"/>
        <g>
          <path fill="#011E41" d="M188.2 38h-8v22.8h14.3V44.4"/>
          <path fill="#011E41" d="M220.5 27.6v22.9l-21.9-22.9h-18.4v6.6h9.6l4.7 4.7 11.4 11.4 3 3 7.4 7.5h18.4V27.6"/>
        </g>
        <path fill="#FFF" d="M333.6 27.6l-2.2 4L330 34l-9.1 16.4-1.9 3.5-14.8-26.3h-59v6.6h16v26.6h15V38l-10.4-3.8H292.6l14.2 23.2 2.1 3.4h19.9l20.4-33.2"/>
      </svg>
    );
  }
  //***********************
}
// ***********************************