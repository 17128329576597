// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {AutoTextColor} from '../../../../Scripts/common';
import {IStores,IStoresInject,IEvent} from '../../../../Types/interfaces';
import {GridColorStyle} from '../../../../Types/enums';
import TimeLine from '../../../../Scripts/timeline';
import Share from './Share/share';
import ADVector from '../../../SVG/Ratings/ad';
// ***********************************
interface IProps extends IStoresInject {
  showImage: boolean;
  channelIndex: number;
  item: IEvent;
  running: boolean;
  hover: boolean;
  flex: number;
  fontSize: number;
  debug?: any;
  now?: boolean;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class EventDetails extends React.Component<IProps,{}> {
  //***********************
  scaleCard(hover: boolean) {
    const {viewState}=this.props;
    switch(viewState!.mediaState) {
      case 0: case 1: return hover? 44:40;
      case 2: case 3: return hover? 54:50;
      case 4: case 5: return hover? 64:60;
      case 6: case 7: return hover? 74:70;
      case 8: default: return hover? 84:80;
    }
  }
  //***********************
  debug(): any {
    const {item,flex,debug,proto,siteSettings}=this.props;

    let genreBg=siteSettings!.genreColorStyle===GridColorStyle.Classic;
    let colors=siteSettings!.colors;
    let colSet=colors.getColorForEvent(item);
    let textCol=genreBg? AutoTextColor(colSet.BackgroundColor,'#202020','#E0E0E0'):proto!.textColorDark;

    let display=Math.round(flex*100)/100;
    return (
      <div style={{color: textCol}}>
        <div>{'Flex: '+display.toString()+'%'}</div>
        <div>{'Duration: '+item.DurationMinutes}</div>
        <div>{'DurationInFrame: '+this.props.debug}</div>
        <div>{'Show Time: '+new TimeLine(item.startTime).toTimeString()+' - '+new TimeLine(item.endTime).toTimeString()}</div>
        <div>{'Color: '+textCol+' on '+colSet.BackgroundColor}</div>
        {debug? <div>{'Duration: '}<b>{debug.toString()}</b></div>:''}
      </div>
    );
  }
  //***********************
  getCardRatio(value: number) {
    let ratioBase=4/3;
    return Math.ceil(value*ratioBase);
  }
  //***********************
  buildCast(): string {
    const {item,siteSettings}=this.props;
    let result: string='';
    let maxCast=Math.min(siteSettings!.maxCast,item!.Cast.length);
    for(let i=0;i<maxCast;i++) {
      result+=result.length>0? ',':'';
      result+=item!.Cast[i].Name;
    }
    return '';
  }
  //***********************  
  render() {
    let debug=false;
    if(debug) {return this.debug();}
    const {item,proto,siteSettings,masterStyles,hover,fontSize,showImage,as}=this.props;

    let genreBg=siteSettings!.genreColorStyle===GridColorStyle.Classic;
    let colors=siteSettings!.colors;
    let colSet=colors.getColorForEvent(item);
    if(colSet===null) {colSet=colors.emptyColSet;}
    let textCol=genreBg? AutoTextColor(colSet.BackgroundColor,'#202020','#E0E0E0'):proto!.textColorDark;

    let baseStyle={fontSize: fontSize.toString()+'px',fontFamily: siteSettings!.defaultFont};

    let headerStyle={fontWeight: 800,color: textCol};
    let titleStyle={...headerStyle,fontSize: '16.5em',lineHeight: '1em',margin: '2px'};
    let timeStyle={...masterStyles!.timeMarker,fontSize: '12em',color: textCol};
    let episodeTitleStyle={...headerStyle,fontSize: '14em',lineHeight: '.98em',marginTop: '3px'};
    let descStyle={
      color: textCol,
      textOverflow: as!.ellipsis,
      fontSize: '16em',lineHeight: '.98em',margin: '3px 0'
    };
    let castStyle={color: textCol};
    let scWidth=this.scaleCard(hover);
    let scHeight=this.getCardRatio(scWidth);
    let cardStyle={
      borderRadius: siteSettings!.cardRoundness+'px',width: scWidth+'px',height: scHeight+'px',
      verticalAlign: 'top',float: as!.left,margin: this.props.hover? '-1px 3px -1px 0':'2px 5px 2px 2px',
      transition: 'all .2s'
    };
    let cardWrapperStyle={
      margin: '2px',float: as!.left,display: 'inline-block',
      height: this.getCardRatio(this.scaleCard(true)),width: this.scaleCard(true)
    };
    let hdStyle={...masterStyles!.hdMarker};
    let ratingStyle={...masterStyles!.ratingMarker};
    let newStyle={...masterStyles!.newMarker};
    let ccStyle={...masterStyles!.ccMarker};
    let eiStyle={...masterStyles!.eiMarker};
    let repeatStyle={...masterStyles!.repeatMarker};
    let adStyle={  ...masterStyles!.ADMarker};

    //future language handling
    let txtTitle=item!.Title.length>0? item!.Title[0].Text:null;
    let suppressShare=!siteSettings!.gridFields.SocialMediaLinks||txtTitle==='No Programming Available';
    let txtTimeStart=new TimeLine(item.startTime).toTimeString()+' - ';
    let txtTimeEnd=new TimeLine(item.endTime).toTimeString();
    let txtEpisodeTitle=item!.EpisodeTitle&&item!.EpisodeTitle.length>0? item!.EpisodeTitle[0].Text:null;
    let txtDescription=item!.Description&&item!.Description.length>0? item!.Description[0].Text:null;
    let cast=item!.Cast&&item!.Cast.length>0? this.buildCast():null;

    let nowStyle={
      color: 'white',padding: '0 20px',fontSize: '12em',fontFamily: 'Poppins',
      fontStyle: as!.italic,background: `linear-gradient(darkgreen,forestgreen,darkgreen)`,
      letterSpacing: '2px',margin: '3px',borderRadius: '3px',
      border: '1px solid forestgreen',display: 'inline-block'
    };

    return (
      <div style={baseStyle}>
        {this.props.now? <div style={nowStyle}>Airing Now!</div>:''}
        {siteSettings!.showProgramImages&&showImage?
          <div style={cardWrapperStyle}><img style={cardStyle} src={item!.ShowCardUri} alt="" /></div>
          :
          ''
        }
        <div style={titleStyle}>
          {
            suppressShare?'':
            <div style={{float: as!.right,margin: '-3px -2px 0 0'}}><Share size={16} eventId={item.EventId} channelIndex={this.props.channelIndex} /></div>
          }
          {txtTitle}
        </div>
        <div>
          {siteSettings!.gridFields.Time&&item.EventId!==0? <span style={timeStyle}>{txtTimeStart}</span>:''}
          {siteSettings!.gridFields.Time&&item.EventId!==0? <span style={timeStyle}>{txtTimeEnd}</span>:''}
          {siteSettings!.gridFields.HDIndicator&&item!.Flags&&item!.Flags.HD? <span style={hdStyle}>HD</span>:''}
          {siteSettings!.gridFields.TVRatings&&item!.Rating&&item!.Rating!.TV? <span style={ratingStyle}>{item!.Rating.TV}</span>:''}
          {siteSettings!.gridFields.MovieRating&&item!.Rating&&item!.Rating!.Movie? <span style={ratingStyle}>{item!.Rating.Movie}</span>:''}
          {siteSettings!.gridFields.NewRepeatStatus&&item!.Flags&&item!.Flags.NewRepeat==='N'? <span style={newStyle}>New</span>:''}
          {siteSettings!.gridFields.NewRepeatStatus&&item!.Flags&&item!.Flags.NewRepeat==='R'? <span style={repeatStyle}>Repeat</span>:''}
          {siteSettings!.gridFields.CCIndicator&&item!.Flags&&item!.Flags.CC? <span style={ccStyle}>CC</span>:''}
          {siteSettings!.gridFields.EIIndicator&&item!.Flags&&item!.Flags.EI? <span style={eiStyle}>e|i</span>:''}
          {siteSettings!.gridFields.VisuallyImpairedIndicator&&item!.Flags&&item!.Flags.AD ? <span style={adStyle}><ADVector height={11} /></span>:''}
        </div>
        {siteSettings!.gridFields.EpisodeTitle&&txtEpisodeTitle? <div style={episodeTitleStyle} > {txtEpisodeTitle}</div>:''}
        {siteSettings!.gridFields.Description&&txtDescription? <div style={descStyle}>{txtDescription}</div>:''}
        {siteSettings!.gridFields.Cast&&cast? <span style={castStyle}>{cast}</span>:''}
      </div>
    );
  }
  //***********************
}