import {IStores,ILoadParams} from '../Types/interfaces';
import SiteSettings from './sitesettings';
import LineupInfo from './lineupinfo';
import ViewState from './viewstate';
import Frame from './frame';
import Prototype from './prototype';
import Schedule from './schedule';
import MasterStyles from './masterstyles';
import Fetcher from './fetcher';
import Search from './search';
import Details from './details';
import Session from './session';
import AbsoluteStyles from '../Stores/absolutestyles';

export default function Init(params: ILoadParams,callBack: any): IStores {
  let result: IStores;
  let fetcher=new Fetcher();
  let search=new Search();
  let details=new Details();
  let siteSettings: SiteSettings,viewState: ViewState;
  let theme: Prototype,frame: Frame,lineupInfo: LineupInfo;
  let schedule: Schedule,masterStyles: MasterStyles, session:Session;

  let initState=() => {
    viewState.initialize(result);
    theme.initialize(result);
    frame.initialize(result);
    masterStyles.initialize(result);
    schedule.initialize(result);
    lineupInfo.initialize(result,params);
    fetcher.initialize(result);
    search.initialize(result);
    details.initialize(result);
    session.initialize(result);
    siteSettings.initialize(result);
    callBack(result);
  };

  siteSettings=new SiteSettings(params,initState,fetcher);
  viewState=new ViewState(params);
  theme=new Prototype();

  frame=new Frame();
  lineupInfo=new LineupInfo();

  schedule=new Schedule(params);
  masterStyles=new MasterStyles();
  session = new Session();
  result={
    siteSettings: siteSettings,
    viewState: viewState,
    lineupInfo: lineupInfo,
    frame: frame,
    proto: theme,
    schedule: schedule,
    masterStyles: masterStyles,
    fetcher: fetcher,
    search: search,
    details: details,
    session:session,
    as: new AbsoluteStyles()
  };
  return result;
}