// ***********************************
import {action,observable} from 'mobx';
import {inject,observer} from 'mobx-react';
import * as React from 'react';
import {IProgramImage,IStores,IStoresInject} from '../../../../../Types/interfaces';
// ***********************************
interface IProps extends IStoresInject {}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class ImageDebug extends React.Component<IProps,{}> {
  //***********************
  @observable private programImages: IProgramImage[]|null;
  @observable private show: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct(): void {
    this.programImages=null;
    this.show=false;
  }
  //***********************
  private initProgramImages(): void {
    const {proto}=this.props;
    if(!this.programImages&&proto!.protoTypeMode) {
      const {session,details}=this.props;
      session!.subscribeProgramImages(details!.programId,this.setProgramImages);
    }
  }
  //***********************
  @action private setProgramImages=(images: IProgramImage[]): void => {
    this.programImages=images;
  }
  //***********************
  @action componentWillUpdate() {this.initProgramImages();}
  //***********************
  componentWillMount() {this.componentWillUpdate();}
  //***********************
  @action click = ():void => {this.show=!this.show;};
  //***********************
  renderImages(): JSX.Element[] {
    const {masterStyles}=this.props;
    let imgDivStyle={...masterStyles!.inlineMiddle, padding: '5px' };
    let result: JSX.Element[]=[];
    for(let i=0; this.programImages&&i< this.programImages.length;i++) {
      let element=this.programImages[i];
      result.push(
        <div style={imgDivStyle} key={'ImageDebugDiv'+i}>
          <img src={element.Uri} alt="" />
          <div>{element.OriginalWidth+'x'+element.OriginalHeight}</div>
          <div>{element.ratio}</div>
          <div>{element.SubTypeCode}</div>
          <div>{element.Type}</div>
        </div>
      );
    }
    return result;
  }
  //***********************
  render() {
    const {proto}=this.props;
    if(!this.programImages){return ('');}
    if(this.show&&proto!.protoTypeMode) {
      return (
        <div>
          <div onClick={this.click}>Hide Images</div>
          <div>{this.renderImages()}</div>
        </div>
      );
    }
    else {
      return (<div onClick={this.click}>Show Images</div>);
    }
  }
  //***********************
}
// ***********************************
