// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import {ISearchDisplayEvent} from '../../../../Types/interfaces';
import LoaderSmall from '../../../Controls/Loaders/LoaderSmall/loadersmall';
//import SocialMedia from '../SocialMedia/socialmedia';
// ***********************************
interface IProps extends IStoresInject {
  item: ISearchDisplayEvent;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class SearchEvent extends React.Component<IProps,{}> {
  //***********************
  @observable private hover: boolean;
  @observable private showImage: boolean;
  @observable private imageLoaded: boolean;
  imageUrl: string='';
  image: HTMLImageElement;
  displayTime:string;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
    this.componentWillUpdate();
  }
  //***********************
  @action private _construct(){
    this.hover=false;
    this.showImage=true;
    this.imageLoaded=false;
  }
  //***********************
  componentWillMount() {
    this.componentWillUpdate();
  }
  //***********************
  componentWillUpdate() {
    this.preloadImage(this.props.item.ImageUri);
  }
  //***********************
  @action preloadImage(imageUrl: string) {
    if(!this.props.siteSettings!.showProgramImagesSearch) {
      this.imageLoaded=true;
      return;
    }
    if(this.imageUrl===imageUrl) {return;}
    this.imageLoaded=false;
    this.imageUrl=imageUrl;
    this.image=new Image();
    this.image.onload=this.imageFound;
    this.image.onerror=this.imageNotFound;
    this.image.src=imageUrl;
  }
  //***********************
  imageFound=(): void => {this.setShowImage(true);};
  imageNotFound=(): void => {this.setShowImage(false);};
  imageError=(): void => {this.setShowImage(false);};
  @action setShowImage(showImage: boolean): void {
    this.showImage=showImage;
    this.imageLoaded=true;
  }
  //***********************
  handleClick=(e: React.FormEvent<HTMLElement>): boolean => {
    e.stopPropagation();
    const {viewState,item}=this.props;
    viewState!.setDetails(item.EventId,item!.ProgramId,item.ChannelIndex!,item.ChannelName,this.displayTime);
    return false;
  }
  //***********************
  @action handleMouseEnter=(): void => {this.hover=true;};
  @action handleMouseLeave=(): void => {this.hover=false;};
  //***********************
  render() {
    const {proto,item,siteSettings,masterStyles,as}=this.props;

    let boxColor=proto!.colorButton;
    let images=siteSettings!.showProgramImagesSearch;

    let title=item.Title.toUpperCase();
    let channelName=item.ChannelName;
    let channelNumber=item.ChannelNumber;
    let eventDate=item.EventDate;
    let eventImgSrc=item.ImageUri;
    let eventDesc=item.Description;
    let episodeTitle=item.EpisodeTitle;

    this.displayTime=item.StartTime+ ' - '+item.EndTime;

    const imgDown=this.hover||!this.showImage||!images;
    const borderStyle={border: '0px solid '+proto!.colorBorder};
    const baseStyle={
      display: 'inline-flex',cursor: 'pointer',backgroundColor: boxColor,padding: '10px',
      height: images?'200px':'120px',width: '240px',marginRight: '15px',marginBottom: '15px',textAlign: as!.left,
      ...borderStyle
    };
    const descDivStyle={
      fontSize: '1.1em',height: (!images||imgDown)? 'auto':'0',width: '100%',color: '#202020',
      overflow: as!.hidden, paddingTop: (imgDown)? '5px':'0'
    };

    let colorStr=this.hover? '#000010':'#404040';
    const titleStyle={
      color: colorStr,fontSize: '15px',fontWeight: as!.bold,overflow: as!.hidden,textOverflow: 'ellipsis',
      whiteSpace: as!.noWrap,width: '240px',textDecoration: this.hover? 'underline':'none'
    };
    const leftListStyle={color: colorStr,fontSize: '11px',fontWeight: as!.bold};
    const rightListStyle={color: colorStr,fontSize: '11px',fontWeight: as!.bold,textAlign: as!.right};
    const detailStyle={display: 'flex',flexDirection: as!.column,padding: '3px'};
    const episodeTitleStyle={lineHeight: '1em',fontSize: '12px',fontWeight: as!.bold,padding: '0 5px 5px 5px',overflow: as!.hidden,textOverflow: as!.ellipsis,width: '200px'};
    const descTextStyle={lineHeight: '1em',padding: '0 5px 5px 5px',overflow: as!.hidden};
    const dateTimeStyle={display: 'flex',flexGrow: 1};

    let hdStyle={...masterStyles!.hdMarker};
    let ratingStyle={...masterStyles!.ratingMarker};
    let newStyle={...masterStyles!.newMarker};
    let ccStyle={...masterStyles!.ccMarker};
    let eiStyle={...masterStyles!.eiMarker};
    let repeatStyle={...masterStyles!.repeatMarker};
    let imageDivStyle={paddingTop: '5px',height: images?'170px':'70px',overflow: as!.hidden};
    let imageStyle={
      height: '140px',width: '240px',backgroundImage: `url(${eventImgSrc})`,boxShadow: `0 0 4px 4px ${boxColor} inset`,
      backgroundSize: 'cover',backgroundPosition: this.hover? 'top':'center'
    };
    if(!this.imageLoaded) {return (<div style={baseStyle}><LoaderSmall flex={100} last={true} /></div>);}

    return (
      <div style={{...baseStyle,flexDirection: 'column'}} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleClick}>
        <div style={{display: 'flex',flexGrow: 1}}>
          <div style={detailStyle}>
            <div style={titleStyle}>{title}</div>
          </div>
        </div>
        <div style={dateTimeStyle}>
          <div style={{...detailStyle,flexGrow: 1}}>
            <div style={{flexGrow: 1}} />
            <div style={leftListStyle}>{eventDate}</div>
            <div style={leftListStyle}>{this.displayTime}</div>
          </div>
          <div style={detailStyle}>
            <div style={rightListStyle}>
              {siteSettings!.gridFields.HDIndicator&&item!.Flags&&item!.Flags.HD? <span style={hdStyle}>HD</span>:''}
              {siteSettings!.gridFields.TVRatings&&item!.Rating&&item!.Rating!.TV? <span style={ratingStyle}>{item!.Rating.TV}</span>:''}
              {siteSettings!.gridFields.MovieRating&&item!.Rating&&item!.Rating!.Movie? <span style={ratingStyle}>{item!.Rating.Movie}</span>:''}
              {siteSettings!.gridFields.NewRepeatStatus&&item!.Flags&&item!.Flags.NewRepeat==='N'? <span style={newStyle}>New</span>:''}
              {siteSettings!.gridFields.NewRepeatStatus&&item!.Flags&&item!.Flags.NewRepeat==='R'? <span style={repeatStyle}>Repeat</span>:''}
              {siteSettings!.gridFields.CCIndicator&&item!.Flags&&item!.Flags.CC? <span style={ccStyle}>CC</span>:''}
              {siteSettings!.gridFields.EIIndicator&&item!.Flags&&item!.Flags.EI? <span style={eiStyle}>e|iC</span>:''}
            </div>
            <div style={{flexGrow: 1}} />{/* <SocialMedia item={event} /></div> */}
            <div style={rightListStyle}>{channelName}&nbsp;&#8226;&nbsp;{channelNumber}</div>
          </div>
        </div>
        <div style={imageDivStyle}>
          <div style={descDivStyle}>
            {episodeTitle.length>0? <div style={episodeTitleStyle} title={episodeTitle}>{episodeTitle}</div>:''}
            <div style={descTextStyle}>{eventDesc}</div>
          </div>
          {images&&this.showImage? <div style={imageStyle} />:''}
        </div>
      </div>
    );
  }
  //***********************
}