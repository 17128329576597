import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
}
// ***********************************
export default class HDVector extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {size}=this.props;
    let style={
      height: size,
      width: size,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={size} width={size} viewBox="0 0 900 900">
        <path d="M0 0h900v900H0z"/>
        <path fill="#fff" d="M107.92512 515.15977l-49.7681 270.79781h712.00034c40.07091 0 72.3507-38.5118 72.35075-86.31894v-98.15993c0-47.80708-32.27989-86.31894-72.35075-86.31894z"/>
        <path d="M231.69609 557.51216l-8.51658 32.43738h71.80619l-42.74811 161.65564h65.39101l42.5803-161.65563h70.80455l8.51657-32.43738H236.98314zm211.25192 0l30.88065 194.09302h68.73155l134.92541-194.09302h-64.89018l-88.65862 128.42335-15.76301-128.42335h-59.93875z"/>
        <path fill="#fff" d="M186.50741 130.08035c-23.49032 113.90592-46.91767 227.82628-70.33222 341.7492h113.62612c9.58203-46.44602 19.30455-88.40407 28.7636-134.87742h90.94917c-9.2447 44.96052-18.54255 89.90923-27.75264 134.87742h113.62613c23.94153-116.20678 47.84619-232.42175 71.5845-348.67361H393.66696c-8.29458 40.1208-16.72821 80.21059-25.1101 120.31185h-90.94775c7.94799-38.61347 15.86941-81.68939 23.77671-120.31185H187.9202l-.9138 4.47845zm495.256-7.21293c-39.36399.68956-78.86596.0864-118.28197.28852-22.06606 116.41167-44.12347 232.8259-66.13559 349.25062 86.73017-.62398 185.01686 8.05332 262.45543-29.28452 83.15456-55.72221 109.8199-180.42781 51.21478-269.187-30.12503-38.83435-82.65179-53.78211-129.25265-51.06762zm5.05152 87.85362c26.31051-2.41865 50.94165 19.20676 48.90793 45.00876 6.53358 49.30974-11.72428 111.21417-63.91868 126.22649-16.59312 3.22087-34.7267 2.81391-52.24499 2.59665 10.88778-57.65544 21.77531-115.3109 32.66293-172.96634 11.5845.019 23.16582.35036 34.59281-.86556z"/>
      </svg>
    );
  }
}