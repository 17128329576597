import * as React from 'react';
// ***********************************
interface IProps {
  color:string;
  height: string;
  width: string;
}
// ***********************************
export default class PrintVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg"  height={this.props.height} width={this.props.width} viewBox="0 0 489 489">
        <path fill={this.props.color} d="M108.05 130.2V25.7c0-9.2 7.4-16.6 16.6-16.6h239.4c9.2 0 16.6 7.4 16.6 16.6v104.6M364.15 479.7h-239.4c-9.2 0-16.6-7.4-16.6-16.6V252h272.7v211.2c-.1 9.1-7.5 16.5-16.7 16.5z"/>
        <path d="M48.55 382.8h50.5v80.4c0 14.2 11.5 25.7 25.7 25.7h239.4c14.2 0 25.7-11.5 25.7-25.7v-80.4h50.5c22.3 0 40.4-18.1 40.4-40.4V161.5c0-22.3-18.1-40.4-40.4-40.4h-50.6V25.7c0-14.2-11.5-25.7-25.7-25.7h-239.4c-14.2 0-25.7 11.5-25.7 25.7v95.5h-50.4c-22.3 0-40.4 18.1-40.4 40.4v180.8c0 22.3 18.1 40.4 40.4 40.4zm323.2 80.3c0 4.2-3.4 7.5-7.5 7.5h-239.5c-4.2 0-7.5-3.4-7.5-7.5V261h254.5v202.1zM117.15 25.7c0-4.2 3.4-7.5 7.5-7.5h239.4c4.2 0 7.5 3.4 7.5 7.5v95.5h-254.4V25.7zm-90.8 135.8c0-12.3 10-22.2 22.2-22.2h391.8c12.3 0 22.2 10 22.2 22.2v180.9c0 12.3-10 22.2-22.2 22.2h-50.5V252c0-5-4.1-9.1-9.1-9.1h-272.6c-5 0-9.1 4.1-9.1 9.1v112.7h-50.5c-12.3 0-22.2-10-22.2-22.2v-181z"/>
        <path d="M77.75 199.9c2.4 0 4.7-1 6.4-2.7 1.7-1.7 2.6-4 2.6-6.4s-1-4.7-2.6-6.4c-1.7-1.7-4-2.7-6.4-2.7s-4.7 1-6.4 2.7c-1.7 1.7-2.7 4-2.7 6.4s1 4.7 2.7 6.4c1.7 1.7 4 2.7 6.4 2.7zM314.65 356.8h-140.4c-5 0-9.1 4.1-9.1 9.1s4.1 9.1 9.1 9.1h140.5c5 0 9.1-4.1 9.1-9.1s-4.2-9.1-9.2-9.1zM314.65 413h-140.4c-5 0-9.1 4.1-9.1 9.1s4.1 9.1 9.1 9.1h140.5c5 0 9.1-4.1 9.1-9.1-.1-5.1-4.2-9.1-9.2-9.1zM314.65 300.6h-140.4c-5 0-9.1 4.1-9.1 9.1s4.1 9.1 9.1 9.1h140.5c5 0 9.1-4.1 9.1-9.1s-4.2-9.1-9.2-9.1z"/>
      </svg>
    );
  }
  //***********************
}