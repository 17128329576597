import * as React from 'react';
// ***********************************
interface IProps {
  size: number;
  rating: string;
}
// ***********************************
export default class TvRatingVector extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {rating,size}=this.props;
    let style={height: size,width: size};
    let d: string='';
    switch(rating) {
      default:
        const err: string='tv rating vector \''+rating+'\' not found';
        throw new Error(err);
      case '':
        return '';
      case 'TV-14':
        d='M34.055 14.242v2.039H23.719v9.477h-7.266v-9.477H6.07v-2.039h27.985zm24.433 0h7.5L54.21 25.758h-6.819L35.707 14.242h7.711l7.5 8.742 7.57-8.742zM9.756 38.227v-3.789c2.63-.078 4.471-.195 5.522-.352 1.676-.247 3.04-.742 4.092-1.484.721-.508 1.267-1.185 1.637-2.031.214-.508.321-.885.321-1.133h6.973v28.32h-8.555V38.227h-9.99zm54.586 13.437h-4.805v6.094h-8.174v-6.094H34.547v-4.863l15.615-17.188h9.375v17.695h4.805v4.356zm-12.979-4.355V35.11L40.76 47.309h10.603z'; break;
      case 'TV-G':
        d='M34.055 14.242v2.039H23.719v9.477h-7.266v-9.477H6.07v-2.039h27.985zm24.433 0h7.5L54.21 25.758h-6.819L35.707 14.242h7.711l7.5 8.742 7.57-8.742zM44.522 37.953c-.57-1.966-1.962-3.34-4.175-4.121-1.237-.43-2.612-.645-4.126-.645-2.897 0-5.277.874-7.142 2.622-1.863 1.748-2.795 4.376-2.795 7.885 0 3.535 1.009 6.037 3.027 7.505 2.018 1.469 4.312 2.203 6.885 2.203 2.522 0 4.59-.579 6.201-1.737s2.604-2.675 2.979-4.552h-8.325v-4.805h14.99v15.449h-4.98l-.757-3.594c-1.449 1.361-2.751 2.32-3.906 2.878-1.986.972-4.428 1.458-7.324 1.458-4.77 0-8.676-1.32-11.719-3.963-3.174-2.654-4.761-6.284-4.761-10.891 0-4.658 1.603-8.393 4.81-11.204 3.206-2.811 7.446-4.216 12.72-4.216 4.573 0 8.248.928 11.022 2.783 2.775 1.855 4.366 4.17 4.773 6.943h-7.397z'; break;
      case 'TV-MA':
        d='M34.055 14.242v2.039H23.719v9.477h-7.266v-9.477H6.07v-2.039h27.985zm24.433 0h7.5L54.21 25.758h-6.819L35.707 14.242h7.711l7.5 8.742 7.57-8.742zM27.475 28.969h10.171v28.789h-6.587V38.285c0-.56.008-1.344.022-2.354.016-1.009.023-1.787.023-2.334l-6.41 24.16h-6.87l-6.364-24.16c0 .547.008 1.325.023 2.334.015 1.009.022 1.794.022 2.354v19.473H4.919V28.969h10.286l6.157 22.637 6.113-22.637zm24.034 0h7.998l11.968 28.789H63.81l-2.231-5.918H49.12l-2.293 5.918h-7.394l12.076-28.789zm-.466 17.91h8.664l-4.273-11.328-4.391 11.328z'; break;
      case 'TV-PG':
        d='M34.055 14.242v2.039H23.719v9.477h-7.266v-9.477H6.07v-2.039h27.985zm24.433 0h7.5L54.21 25.758h-6.819L35.707 14.242h7.711l7.5 8.742 7.57-8.742zM29.477 45.297c-1.98 1.406-4.809 2.109-8.483 2.109H13.95v10.352H6.927V28.969h14.524c3.349 0 6.019.742 8.01 2.227 1.99 1.484 2.985 3.783 2.985 6.895 0 3.397-.99 5.8-2.969 7.206zm-5.396-10.371c-.895-.638-2.148-.957-3.762-.957H13.95v8.477h6.369c1.613 0 2.867-.345 3.762-1.035.896-.689 1.344-1.783 1.344-3.281 0-1.498-.448-2.566-1.344-3.204zm34.427 3.027c-.535-1.966-1.844-3.34-3.924-4.121-1.163-.43-2.455-.645-3.879-.645-2.723 0-4.961.874-6.712 2.622-1.752 1.748-2.628 4.376-2.628 7.885 0 3.535.948 6.037 2.846 7.505 1.896 1.469 4.054 2.203 6.472 2.203 2.371 0 4.314-.579 5.829-1.737s2.447-2.675 2.8-4.552h-7.825v-4.805h14.09v15.449h-4.682l-.711-3.594c-1.362 1.361-2.586 2.32-3.672 2.878-1.867.972-4.162 1.458-6.885 1.458-4.483 0-8.155-1.32-11.016-3.963-2.983-2.654-4.475-6.284-4.475-10.891 0-4.658 1.506-8.393 4.521-11.204 3.014-2.811 6.999-4.216 11.956-4.216 4.3 0 7.753.928 10.362 2.783 2.608 1.855 4.104 4.17 4.486 6.943h-6.953z'; break;
      case 'TV-Y':
        d='M34.055 14.242v2.039H23.719v9.477h-7.266v-9.477H6.07v-2.039h27.985zm24.433 0h7.5L54.21 25.758h-6.819L35.707 14.242h7.711l7.5 8.742 7.57-8.742zM44.07 28.969h8.521L40.555 46.963v10.795h-7.52V46.963L20.584 28.969h8.862l7.471 12.539 7.153-12.539z'; break;
      case 'TV-Y7':
        d='M34.055 14.242v2.039H23.719v9.477h-7.266v-9.477H6.07v-2.039h27.985zm24.433 0h7.5L54.21 25.758h-6.819L35.707 14.242h7.711l7.5 8.742 7.57-8.742zm-28.76 14.727h8.521L26.212 46.963v10.795h-7.52V46.963L6.241 28.969h8.862l7.471 12.539 7.154-12.539zm35.376 5.195c-1.044.82-2.495 2.282-4.354 4.385-1.858 2.104-3.416 4.274-4.672 6.514-.994 1.758-1.892 3.906-2.69 6.445s-1.198 4.622-1.198 6.25h-7.227c.212-5.078 2.297-10.358 6.255-15.84 2.558-3.398 4.699-5.768 6.427-7.109H40.006l.098-5h25v4.355z'; break;
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} width={size} height={size} viewBox="0 0 72 72">
        <path d="M0 0h72v72H0z" />
        <path fill="#FFF" d={d} />
      </svg>
    );
  }
  //***********************
}
// ***********************************