// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../../../../Types/interfaces';
import MoreLeftVector from '../../../../../../../SVG/moreleft';
import MoreRightVector from '../../../../../../../SVG/moreright';
import {DetectIE} from '../../../../../../../../Scripts/common';
// ***********************************
interface IProps extends IStoresInject {
  enabled: boolean,
  click: () => void;
  offset: string;
  thumbSize: number;
  hover: boolean;
}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class RibbonButton extends React.Component<IProps,{}> {
  //***********************
  @observable private _hover: boolean;
  @observable private _mouseDown: boolean;
  private _mouseDownId:number;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct(): void {
    this._hover=false;
    this._mouseDown=false;
    this._mouseDownId=-1;
  }
  //***********************
  @action handleMouseEnter=(): void => {this._hover=true;}
  //***********************
  @action handleMouseLeave=(): void => {this._hover=false;}
  //***********************
  @action handleMouseDown=(): void => {
    this._mouseDown=true;
    this._mouseDownId=window.setTimeout(()=>{this.doMouseDown(250)},250);
  }
  //***********************
  @action handleMouseUp=(): void => {
    this._mouseDown=false;
    clearTimeout(this._mouseDownId);
    this._mouseDownId=-1;
  }
  //***********************
  doMouseDown = (speed:number) => {
    if(this._mouseDown){ 
      this.props.click();  
      this._mouseDownId=window.setTimeout(this.doMouseDown,speed);
    }
  }
  //***********************
  @action handleClick=(): void => {
    if(!this.props.enabled) {return;}
    if(this._mouseDown){
      this.handleMouseUp()
      return;
    }
    this.props.click();
  }
  //***********************  
  @action componentDidMount(){
    window.addEventListener('mouseup',this.handleMouseUp);
  }
  //***********************
  @action componentWillUnmount(){
    window.addEventListener('mouseup',this.handleMouseUp);
  }
  //***********************
  @action componentDidUpdate(){
    if(!this.props.enabled){
      this._hover=false;
      this._mouseDown=false;
    }
  }
  //***********************

  render() {
    const {as,offset,thumbSize,hover,enabled}=this.props;

    let ie:boolean=DetectIE()<12;

    let margin=5;

    let ownerStyle={
      margin: `${margin}px 0`,
      position: as!.relative,width: '25px',height: `${thumbSize-(margin*2)}px`,backgroundColor: this._hover&&enabled?'#00000099':'inherit',zIndex: enabled?9998:-1,
      display: 'inline-flex',verticalAlign: 'middle',cursor: this._hover&&enabled?'pointer':'default'
    };

    const sectionStyle={flex: 100,padding: '0 7px',opacity: hover&&enabled? 1:0, margin: 'auto'};

    let hoverColor=this._hover?(ie?'#ffffff':'#ffffffff'):(ie?'#cccccc':'#ccccccdd');

    return (
      <div 
        style={ownerStyle} 
        onMouseEnter={this.handleMouseEnter} 
        onMouseLeave={this.handleMouseLeave} 
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
      >
        <div style={sectionStyle} >
          {offset==='left'? <MoreLeftVector color={hoverColor} width={12} />:''}
          {offset==='right'? <MoreRightVector color={hoverColor} width={12} />:''}
        </div>
      </div>
    );
  }
  //***********************
}
// ***********************************