import * as React from 'react';
// ***********************************
interface IProps {
  color: string;
  height: string;
  width: string;
}
// ***********************************
export default class DownArrowVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.height,
      width: this.props.width,
      fill: this.props.color
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.height} width={this.props.width} viewBox="0 0 124 124">
        <path 
          d="M117.979 8.58275h-112c-5.3 0-8 8.72602-4.2 13.9071l56 76.3527c2.3 3.13593 6.1 3.13593 8.401 0l56-76.3527c3.799-5.18108 1.099-13.9071-4.201-13.9071z"
        />
      </svg>
    );
  }
  //***********************
}