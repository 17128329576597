/* tslint:disable */
export function swipedetect(el: HTMLElement | null, callback: (id: string) => void) {

    let touchsurface = el;
    let swipedir: string;
    let startX: number;
    let startY: number;
    let distX: number;
    let distY: number;
    let threshold: number = 50; //required min distance traveled to be considered swipe
    let restraint: number = 150; // maximum distance allowed at the same time in perpendicular direction
    let allowedTime: number = 500; // maximum time allowed to travel that distance
    let elapsedTime: number;
    let startTime: number;
    let handleswipe = callback || function (swipedir: string) { }

    touchsurface!.addEventListener('touchstart', function (e: TouchEvent) {
        var touchobj = e.changedTouches[0];
        swipedir = 'none';
        distX = 0;
        startX = touchobj.pageX;
        startY = touchobj.pageY;
        startTime = new Date().getTime(); // record time when finger first makes contact with surface
    }, { capture: true, passive: true } as any);

    touchsurface!.addEventListener('touchmove', function (e: TouchEvent) {
    }, { capture: true, passive: true } as any);

    touchsurface!.addEventListener('touchend', function (e: TouchEvent) {
        var touchobj = e.changedTouches[0];
        distX = touchobj.pageX - startX; // horizontal swipe length
        distY = touchobj.pageY - startY; // vertical swipe length
        elapsedTime = new Date().getTime() - startTime; // swipe time
        if (elapsedTime <= allowedTime) { 
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) { // horizontal swipe
                swipedir = (distX < 0) ? 'left' : 'right'; // neg is left
                e.preventDefault();
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) { // vertical swipe
                swipedir = (distY < 0) ? 'up' : 'down'; // neg is up
                e.preventDefault();
            }
        }
        handleswipe(swipedir);
    }, false);
}