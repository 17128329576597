import {action,observable,computed} from 'mobx';
import {IStores,ISessionMedia,IProgramImage} from '../Types/interfaces';
// ***********************************
export default class Session {
  //***********************
  //@ts-ignore:unread variable
  private _parent: IStores;
  @observable private _initialized: boolean;
  @observable private _ready: boolean;

  private _sessionMedia: ISessionMedia[];

  @computed public get initialized(): boolean {return this._initialized;}
  @computed public get ready(): boolean {return this._ready;}
  public get sessionMedia(): ISessionMedia[] {return this._sessionMedia;}
  //***********************
  constructor() {
    this._construct();
    this._sessionMedia=[];
  }
  //***********************
  @action _construct() {
    this._initialized=false;
  }
  //***********************
  @action public initialize(parent: IStores): void {
    this._parent=parent;
    this._initialized=true;
  }
  //***********************
  public subscribeProgramImages(programId: number,callback: (p: IProgramImage[]|null) => void): void {
    let sessionMedia=this._sessionMedia.find(obj => obj.programId===programId);
    if(sessionMedia) {
      if(sessionMedia.programImages) {callback(sessionMedia.programImages);}
      else {sessionMedia.callbacks.push(callback);}
      return;
    }
    let newSessionMedia={} as ISessionMedia;
    newSessionMedia.callbacks=[callback];
    newSessionMedia.programId=programId;
    this._sessionMedia.push(newSessionMedia);
    let fetchUrl=`data/images/${this._parent.lineupInfo.currentChannel.ChannelId}/${programId}`;
    this._parent.fetcher.fetchForId<IProgramImage[]>(programId,fetchUrl,this.processLoad,false);
  }
  //***********************
  public processLoad=(data: IProgramImage[],programId: number): void => {
    let sessionMedia=this._sessionMedia.find(obj => obj.programId===programId);
    if(!sessionMedia) {
      const err: string='missing sessionMedia';
      throw new Error(err);
    }
    sessionMedia.programImages=data;
    sessionMedia.programImages.forEach(obj => {obj.ratio=(parseFloat((obj.OriginalHeight/obj.OriginalWidth).toFixed(2)));});
    sessionMedia.callbacks.forEach!(callback => callback(sessionMedia!.programImages));
    sessionMedia.callbacks=[];
    if(this._sessionMedia.length>5){ this._sessionMedia.splice(0,1); }
  }
  //***********************
}