// ***********************************
import GridColors from './gridcolors';
import {IConfigSettings,ISiteSettings,IGridEventFields,IBranding,ILoadParams,IDetailEventFields,IStores,IAdData,IAdJson,IColorMatch} from '../Types/interfaces';
import {ViewContext,GridColorStyle} from '../Types/enums';
import TimeLine from '../Scripts/timeline';
import Fetcher from './fetcher';
import {DetectIE,IsJson} from '../Scripts/common';

// ***********************************
export default class SiteSettings {
  //***********************
  private _sessionId: string;
  //@ts-ignore:unread variable
  private _parent: IStores;
  private _dynamic: boolean;
  private _dynamicLineupId: string;
  private _dynamicCurrentTime: string;
  private _dynamicObservesDst: boolean;
  private _dynamicUtcOffset: number;
  private _initialized: boolean;
  private _loaded: boolean;
  private _settingsLoaded: boolean;
  private _adCodeLoaded: boolean;
  private _environment: string;
  private _adminUrl: string;
  private _canDebug: boolean;
  private _hostAddress: string;
  private _timeOffset: number;
  private _serverTime: TimeLine;
  private _callBack: any;
  private _siteId: number;
  private _colors: GridColors;
  private _startingLineupId: string;
  private _startingView: ViewContext;
  private _maxGridHours: number;
  private _gridFields: IGridEventFields;
  private _detailFields: IDetailEventFields;
  private _enableViews: boolean;
  private _enableSearch: boolean;
  private _enableEiSearch: boolean;
  private _enableLineups: boolean;
  private _enableAds: boolean;
  private _enableDetails: boolean;
  private _branding: IBranding;
  //@ts-ignore:unread variable
  //private _brandingStyle: TextBrandingStyle;
  private _eiSearchText: string;
  private _timeBarRowInterval: number;
  private _inGridAdInterval: number;
  private _inGridAdOffset: number;
  private _showChannelNumbers: boolean;
  private _showChannelImages: boolean;
  private _showProgramImages: boolean;
  private _showProgramImagesSearch: boolean;
  private _genreColorStyle: GridColorStyle;
  private _showTvEverywhere: boolean;
  private _tvEverywhere: string;
  private _siteEmailAddress: string;

  private _useCustomStartTime: boolean;
  private _customStartTime: TimeLine;
  private _enableScrollingBody: boolean;
  private _showHamburger: boolean;
  private _defaultFont: string='Ubuntu,Arial, sans-serif'

  // banner mode parsed from ad code
  private _bannerMode: IAdJson;
  //--------------------------------------------------------

  // hardcodes - might need to be configurable at some point
  private _maxCast: number;
  private _cardRoundness: number;
  private _uiRoundness: number;
  private _channelsPerDataChunk: number;
  //--------------------------------------------------------
  private _colorMatch: IColorMatch[];
  //--------------------------------------------------------
  private get fetchUrl(): string {return `data/sitesettings/${this._siteId}`;}
  private get fetchBannerUrl(): string {return `data/cfg/${this._siteId}`;}
  //--------------------------------------------------------
  public get dynamic(): boolean {return this._dynamic;}
  public get proto_enablePrintButton(): boolean {return false;}
  public get sessionId(): string {return this._sessionId;}
  public get environment(): string {return this._environment;}
  public get adminUrl(): string {return this._adminUrl;}
  public get hostAddress(): string {return this._hostAddress;}
  public get canDebug(): boolean {return this._canDebug;}
  public get colors(): GridColors {return this._colors;}
  public get siteId(): number {return this._siteId;}
  public get loaded(): boolean {return this._loaded;}
  public get startingLineupId(): string {return this._startingLineupId;}
  public get startingView(): ViewContext {return this._startingView;}
  public get maxGridHours(): number {return this._maxGridHours;}
  public get gridFields(): IGridEventFields {return this._gridFields;}
  public get detailFields(): IDetailEventFields {return this._detailFields;}
  public get enableViews(): boolean {return this._enableViews;}
  public get enableSearch(): boolean {return this._enableSearch;}
  public get enableEiSearch(): boolean {return this._enableEiSearch;}
  public get enableLineups(): boolean {return this._enableLineups;}
  public get enableAds(): boolean {return this._enableAds;}
  public get enableDetails(): boolean {return this._enableDetails;}
  public get branding(): IBranding {return this._branding;}
  public get eiSearchText(): string {return this._eiSearchText;}
  public get timeBarRowInterval(): number {return this._timeBarRowInterval;}
  public get inGridAdInterval(): number {return this._inGridAdInterval;}
  public get inGridAdOffset(): number {return this._inGridAdOffset;}
  public get showChannelNumbers(): boolean {return this._showChannelNumbers;}
  public get showChannelImages(): boolean {return this._showChannelImages;}
  public get showProgramImages(): boolean {return this._showProgramImages;}
  public get showProgramImagesSearch(): boolean {return this._showProgramImagesSearch;}
  public get genreColorStyle(): GridColorStyle {return this._genreColorStyle;}
  public get showTvEverywhere(): boolean {return this._showTvEverywhere;}
  public get tvEverywhere(): string {return this._tvEverywhere;}
  public get siteEmailAddress(): string {return this._siteEmailAddress;}

  public get useCustomStartTime(): boolean {return this._useCustomStartTime;}
  public get showHamburger(): boolean {return this._showHamburger;}
  public get customStartTime(): TimeLine {return this._customStartTime;}
  public get enableScrollingBody(): boolean {return DetectIE()>10&&this._initialized&&this._enableScrollingBody;}
  public get defaultFont(): string {return this._defaultFont;}
  public get bannerMode(): IAdJson {return this._bannerMode;}
  // hardcodes - might need to be configurable at some point
  public get maxCast(): number {return this._maxCast;}
  public get cardRoundness(): number {return this._cardRoundness;}
  public get uiRoundness(): number {return this._uiRoundness;}
  public get channelsPerDataChunk(): number {return this._channelsPerDataChunk;}
  public get channelsPerGridRow(): number {return this._channelsPerDataChunk-5;}
  //--------------------------------------------------------
  public get timeOffset(): number {return this._timeOffset;}
  public get serverTime(): TimeLine {return this.getServerTime();}
  //***********************
  public get colorMatch(): IColorMatch[] {return this._colorMatch;}
  //***********************
  constructor(pageParams: ILoadParams,callBack: any,fetcher: Fetcher) {
    this._sessionId='';
    this._dynamic=pageParams.dynamic;
    if(this._dynamic) {console.log("Guides: dynamic confirmed");}
    this._siteId=pageParams.siteId;
    this._dynamicLineupId=pageParams.lineupId;
    this._dynamicCurrentTime=pageParams.currentTime;
    this._dynamicObservesDst=pageParams.observesDst;
    this._dynamicUtcOffset=pageParams.utcOffset;
    this._callBack=callBack;
    this._loaded=false;
    this._adCodeLoaded=false;
    this._settingsLoaded=false;
    this._initialized=false;
    this._colorMatch=pageParams.colorMatch? pageParams.colorMatch:[];
    this._showHamburger=pageParams.showHamburger;
    fetcher.fetch<ISiteSettings>(this.fetchUrl,this.set,pageParams.debug,this.config);
    fetcher.fetch<IAdData>(this.fetchBannerUrl,this.setAdData,pageParams.debug);
  }
  //***********************
  public initialize(parent: IStores) {
    this._parent=parent;
    this._initialized=true;
  }
  //***********************
  config=(n: IConfigSettings): void => {
    this._canDebug=n.Debuggable;
    this._adminUrl=n.AdminAppUrl;
    this._environment=n.Environment;
    this._hostAddress=n.HostAddress;
    this._sessionId=n.SessionId;
  }
  //***********************
  set=(data: ISiteSettings): void => {
    if(this.dynamic) {
      data=this.getDynamicDefaults();
      data.CurrentTime=this._dynamicCurrentTime;
      console.log("Guides: dynamic site enabled");
    }
    if(data.SiteId!==this._siteId) {
      const err: string='SiteId Mismatch';
      throw new Error(err);
    }

    if(!data.EnableSite) {return;}

    this._colors=new GridColors(data.GridColors);
    this._serverTime=new TimeLine(data.CurrentTime);
    this._timeOffset=new TimeLine().valueOf()-this._serverTime.valueOf();

    this._useCustomStartTime=data.GridStartHour>-1;
    if(this.useCustomStartTime) {
      let pt: Date=new Date(Date.now());
      pt.setHours(19);
      this._customStartTime=new TimeLine(pt);
    }
    let eiSearch=this._enableSearch&&data.ShowEISearch;
    console.log("Dynamic: "+this.dynamic);

    this._showProgramImagesSearch=data.ShowProgramImages;
    this._inGridAdInterval=-1;
    this._inGridAdOffset=-1;
    this._startingLineupId=data.StartingLineupId;
    this._startingView=data.StartingView;
    this._maxGridHours=data.MaxGridMinutes/60;
    this._gridFields=data.GridEventFields;
    this._gridFields.EIIndicator=true;
    this._detailFields=data.DetailEventFields;
    this._enableViews=data.EnableViews;
    this._enableScrollingBody=data.EnableScrollingBody;
    this._enableSearch=data.EnableSearch;
    this._enableEiSearch=eiSearch;
    this._enableLineups=data.EnableLineups;
    this._enableAds=data.EnableAds;
    this._enableDetails=data.EnableDetails;
    this._branding=data.Branding;
    this._branding.AntennaWebLogo=false;
    this._eiSearchText=data.EISearchText;
    this._timeBarRowInterval=data.TimeBarRowInterval;
    this._showChannelNumbers=data.ShowChannelNumbers;
    this._showChannelImages=data.ShowChannelImages;
    this._showProgramImages=data.ShowProgramImages;
    this._genreColorStyle=data.GridColorStyle;
    this._showTvEverywhere=data.ShowTVeverywhereLink;
    this._tvEverywhere=data.TVeverywhereLink;
    this._siteEmailAddress=(data.SiteEmailAddress&&data.SiteEmailAddress.length>0)? data.SiteEmailAddress:'info@titantv.com';
    // hardcodes - might need to be configurable at some point
    this._maxCast=2;
    this._cardRoundness=6;
    this._uiRoundness=6;
    this._channelsPerDataChunk=50;
    this._settingsLoaded=true;
    this.doCallback();
  }
  //***********************
  setAdData=(data: IAdData): void => {
    this._bannerMode=IsJson(data.HeaderCode);
    this._adCodeLoaded=true;
    this.doCallback();
  }
  //***********************
  private doCallback() {
    if(this._settingsLoaded&&this._adCodeLoaded) {
      this._loaded=true;
      this._callBack();
    }
  }
  //***********************
  private getServerTime(): TimeLine {
    let timeVal=new TimeLine().valueOf()-this._timeOffset;
    return new TimeLine(timeVal);
  }
  //***********************
  private getDynamicDefaults(): ISiteSettings {
    return ({
      "CurrentTime": "2020-12-31T12:47:26-05:00",
      "SiteId": this._siteId,
      "EnableSite": true,
      "StartingLineupId": this._dynamicLineupId,
      "StartingView": 0,
      "MaxGridMinutes": 180,
      "GridEventFields": {
        "Time": true,
        "EpisodeTitle": true,
        "Description": true,
        "Cast": false,
        "EIIndicator": true,
        "HDIndicator": true,
        "CCIndicator": true,
        "NewRepeatStatus": false,
        "MovieRating": true,
        "TVRatings": true,
        "ChainedEpisodeInfo": false,
        "HearingImpairedIndicator": true,
        "VisuallyImpairedIndicator": true,
        "SocialMediaLinks": true
      },
      "EnableDetails": false,
      "DetailEventFields": {
        "Time": false,
        "ProgramImage": false,
        "SeriesDescription": false,
        "Channel": false,
        "EIDisplay": false,
        "HDIndicator": false,
        "CCIndicator": false,
        "CCExtendedInfo": false,
        "StereoIndicator": false,
        "DolbyIndicator": false,
        "AudioExtendedInfo": false,
        "NewRepeatStatus": false,
        "MovieRating": false,
        "TVRatings": false,
        "StarRating": false,
        "Advisories": false,
        "ProgramGenres": false,
        "ProductionYear": false,
        "OriginalAirDate": false,
        "Season": false,
        "Cast": false,
        "OtherAirings": false,
        "Screenshots": false,
        "ChainedEpisodeInfo": false,
        "ExtendedCastAndCrewInfo": false
      },
      "EnableSearch": true,
      "EnableAds": false,
      "EnableLineups": false,
      "EnableViews": true,
      "Branding": {
        "TitanTVLogo": false,
        "AntennaWebLogo": false,
        "TitanTVFooterLink": false,
        "CorporateFooterLink": false,
        "CorporateFooterEmail": false,
        "CorporateSocialMedia": false
      },
      "TextBrandingStyle": 0,
      "EISearchText": "",
      "ShowEISearch": false,
      "TimeBarRowInterval": 10,
      "ShowChannelNumbers": true,
      "ShowChannelImages": true,
      "ShowProgramImages": false,
      "GridColors": [],
      "GridColorStyle": 0,
      "ShowTVeverywhereLink": false,
      "TVeverywhereLink": "",
      "GridStartHour": -1,
      "EnableScrollingBody": false,
      "SiteEmailAddress": ""
    });
  }
  //***********************
}
// ***********************************