// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import Search from '../../../Controls/Search/search';
import Print from '../../../Controls/Print/print';
import BackButton from '../../../Controls/Navigation/backbutton';
// ***********************************
interface IProps extends IStoresInject {}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Settings extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {siteSettings,proto}=this.props;
    let border: boolean=siteSettings!.enableScrollingBody;
    
    let baseStyle={
      minHeight:'62px',
      borderColor: proto!.colorBorder,
      borderWidth: '1px',
      borderStyle: `${border?'none':'solid'} none ${border?'solid':'none'} none`,
      padding: '8px'
    };

    return (
      <div style={baseStyle}>
        <Print />
        <BackButton />
        <Search />
      </div>
    );
  }
  //***********************
}
// ***********************************