// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../Types/interfaces';
import {GPT} from './GPT/gpt';
import {FreestarAd} from './Freestar/freestarad';
// ***********************************
interface IProps extends IStoresInject {placement: string, pageIndex: number, dynamic?:boolean, adIndex?:number}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BannerG extends React.Component<IProps,{}> {
  //***********************
  private objElement: HTMLIFrameElement;
  private id: number;
  private get footer(): boolean {return (this.props.placement==='footer');}
  get bannerUrl(): string {return this.getBannerUrl();}
  state={ gptReady: false }
  //***********************
  getBannerUrl(): string {
    const {siteSettings,viewState,placement}=this.props;
    const {viewMode,bannerWidth,bannerHeight}=viewState!;
    let bannerSrc=`${ siteSettings!.hostAddress }data/adbanner/${ siteSettings!.siteId }/${ viewMode }/${ placement }/`;
    bannerSrc+=`${ bannerWidth }/${ bannerHeight }`;
    return bannerSrc;
  }
  //***********************
  initIframe(el: HTMLIFrameElement|null): void {
    if(el) {this.objElement=el;}
  }
  //***********************
  invalidate=(): void => {
    if(this.objElement) {
      this.objElement.src='';
      this.objElement.src=this.bannerUrl;
      this.setState({temp: Math.random()});
    }
  }
  //***********************
  componentDidMount() {
    this.id=this.props.viewState!.subscribeBanner(this.invalidate);
    const {googletag: {cmd}}=window;
    if(cmd) {
      cmd.push(() => this.setState({gptReady: true}));
    }
  }
  //***********************
  componentWillUnmount() {this.props.viewState!.unSubscribeBanner(this.id);}
  //***********************
  render() {
    //if(this.renderCt!==this.renderIndex){this.renderIndex=this.renderCt;}
    const {proto,siteSettings,viewState,masterStyles,as,pageIndex}=this.props;
    if(!this.props.dynamic&&!siteSettings!.enableAds) {return ('');}
    const {isMobile,bannerWidth,bannerHeight}=viewState!;
    let esb: boolean=siteSettings!.enableScrollingBody;
    const mobileStyle=this.footer? {bottom: '0'}:{top: '0'};
    let heightStyle={
      minHeight: bannerHeight+(isMobile? 2:10)+'px',
      flex: '0 1 auto',
      borderColor: proto!.colorBorder,
      borderStyle: `solid none ${ esb? 'solid':'none' } none`,
      borderWidth: isMobile? '0':'1px',
    };
    let ownerStyle={
      ...masterStyles!.flexCenter,
      // backgroundColor: isMobile?'black':proto!.colorWindow,
      backgroundColor: proto!.colorWindow,
      padding: isMobile? '0':'5px 0',
      overflow: as!.hidden,
      boxSizing: as!.borderBox,
      position: isMobile? as!.fixed:as!.static,
      zIndex: isMobile? 99999:0,
      ...mobileStyle
    };
    let objectStyle={
      boxSizing: as!.borderBox,
      height: bannerHeight+'px',
      width: bannerWidth+'px',
    };
    if(!siteSettings!.bannerMode) {return '';}
    if(!siteSettings!.bannerMode.isGpt) {
      return (
        <div style={heightStyle}>
          <div style={ownerStyle}>
            <iframe ref={(el) => {this.initIframe(el)}} style={objectStyle} frameBorder="0" title="8675309" />
          </div>
        </div>
      );
    }
    let adIndex=this.footer? 1:this.props.adIndex!? this.props.adIndex!:0;
    if(!siteSettings!.bannerMode.isFreestar) {
      const {state: {gptReady}}=this;
      return (
        <div>
          {gptReady&&<GPT adIndex={adIndex} pageIndex={pageIndex} width={bannerWidth} height={bannerHeight} />}
        </div>
      );
    }
    return (
      <div>
        <FreestarAd adIndex={adIndex} pageIndex={pageIndex} width={bannerWidth} height={bannerHeight}></FreestarAd>
      </div>
    );


  }
  //***********************
}