// ***********************************
import * as React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../../Types/interfaces';
import LeftArrowVector from '../../../../Application/SVG/leftarrow';
import RightArrowVector from '../../../../Application/SVG/rightarrow';
// ***********************************
interface IProps extends IStoresInject {
  index: number;
  flex: string;
  offsetHalf: boolean;
  offsetVal: number;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class HourSegment extends React.Component<IProps, {}> {
  //***********************
  @observable leftHover: boolean;
  @observable rightHover: boolean;
  //***********************
  @action handleLeftMouseEnter = ():void => { this.leftHover = true; };
  @action handleLeftMouseLeave = ():void => { this.leftHover = false; };
  @action handleRightMouseEnter = ():void => { this.rightHover = true; };
  @action handleRightMouseLeave = ():void => { this.rightHover = false; };
  //***********************
  handleBackClick = (): void => {
    const { frame } = this.props;
    frame!.decrementHours();
  }
  //***********************
  handleForwardClick = (): void => {
    const { frame } = this.props;
    frame!.incrementHours();
  }
  //***********************
  render() {
    const { frame, proto, as } = this.props;
    const firstSegment:boolean=(this.props.index===0)&&!this.props.offsetHalf;
    const lastSegment:boolean=(this.props.index-this.props.offsetVal+1===frame!.windowSize)&&this.props.offsetHalf;
    const baseStyle = { display: 'flex', justifyContent: as!.flexStart, alignItems: as!.center, 
      borderColor: proto!.colorBorder, borderStyle: 'none none none '+(firstSegment?'none':'solid'), borderWidth: '1px', 
      flex: this.props.flex, backgroundColor:'inherit',height:'24px'
    };
    const leftCol = (this.leftHover ? proto!.colorHover : proto!.colorControl);
    const rightCol = (this.rightHover ? proto!.colorHover : proto!.colorControl);

    const leftStyle = { cursor: 'pointer', visibility: frame!.boundHourPast ? as!.hidden : as!.visible, paddingLeft: '2px', paddingTop: '2px' };
    const rightStyle = { cursor: 'pointer', visibility: frame!.boundHourFuture ? as!.hidden : as!.visible, paddingRight: '2px', paddingTop: '2px'};

    const lBtn=<div style={leftStyle} onClick={this.handleBackClick} onMouseEnter={this.handleLeftMouseEnter} onMouseLeave={this.handleLeftMouseLeave}><LeftArrowVector color={leftCol} width="16" height="16" /></div>;
    const rBtn=<div style={rightStyle} onClick={this.handleForwardClick} onMouseEnter={this.handleRightMouseEnter} onMouseLeave={this.handleRightMouseLeave}><RightArrowVector color={rightCol} width="16" height="16" /></div>;

    const labelStyle={ width: '100%', display: 'flex', justifyContent: as!.flexStart, paddingLeft: '5px' };
    return (
      <div style={baseStyle}>
        {firstSegment?lBtn:''}
        <div style={labelStyle}>
          {frame!.modHours(this.props.index, this.props.offsetHalf)}
        </div>
        {lastSegment?rBtn:''}
      </div>
    );
  }
  //***********************
}