// ***********************************
import {action,computed,observable} from 'mobx';
import {ILineupInfo,IChannel,IChannelInfo,IStores,ILoadParams,IBaseChannel,IDictionary,ICallback,IEvent,IDay} from '../Types/interfaces';
// ***********************************
export default class LineupInfo {
  private _lineupCacheId: number;
  private _parent: IStores;
  private _lineupChangeCallbacks: ICallback[];
  @observable private _initialized: boolean;
  @observable private _lineupLoaded: boolean;
  @observable private _channelsLoaded: boolean;
  @observable private _lineupIndex: number;
  @observable private _channelIndex: number;
  @observable private _lineups: ILineupInfo[];
  @observable private _channels: IChannel[];
  @observable private _channelMap: IDictionary<IChannel>;
  @observable private _siteId: number;
  @observable private _dynamicLineupId: string;
  private get _fetchLineupUrl(): string {return `data/lineups/${this._siteId}`;}
  private get _fetchChannelsUrl(): string {return `data/channels/${this._siteId}/${this._lineups[this._lineupIndex].LineupId}`;}
  public get lineupCacheId(): number {return this._lineupCacheId;}
  @computed public get initialized(): boolean {return this._initialized;}
  @computed public get lineupLoaded(): boolean {return this._lineupLoaded;}
  @computed public get channelsLoaded(): boolean {return this._channelsLoaded;}
  @computed public get currentLineup(): ILineupInfo {return this.lineups[this._lineupIndex];}
  @computed public get currentChannel(): IChannel {return this._channels[this._channelIndex];}
  @computed public get lineups(): ILineupInfo[] {return this._lineups;}
  @computed public get channels(): IChannel[] {return this._channels;}
  @computed public get lineupIndex(): number {return this._lineupIndex;}
  @computed public get channelIndex(): number {return this._channelIndex;}
  //***********************
  constructor() {
    this._construct();
  }
  //***********************
  @action _construct() {
    this._channels=[];
    this._channelMap={};
    this._lineupIndex=-1;
    this._channelIndex=0;
    this._initialized=false;
    this._lineupLoaded=false;
    this._channelsLoaded=false;
    this._lineupChangeCallbacks=[];
  }
  //***********************
  @action public initialize(parent: IStores,params: ILoadParams): void {
    this._parent=parent;
    this._siteId=params.siteId;
    this._dynamicLineupId=params.lineupId;
    if(!params.dynamic){ parent.fetcher.fetch<ILineupInfo[]>(this._fetchLineupUrl,this.setLineup,params.debug); }
    else{
      const lineupInfo:ILineupInfo[]=[];
      lineupInfo.push({
        LineupCacheId:-1, 
        LineupId: params.lineupId, 
        LineupName: 'AntennaWeb Lineup Name',
        UTCOffset:-6,
        ObservesDST: true,
        Description: params.lineupAddress
      });
      this.setLineup(lineupInfo);
    }
    this._initialized=true;
  }
  //***********************
  @action setLineup=(data: ILineupInfo[]): void => {
    this._lineups=data;
    this._lineupLoaded=true;
  }
  //***********************
  @action getChannelText=(channel: IChannel): string => {
    let result: string='';
    let scn=this._parent.siteSettings.showChannelNumbers;
    if(channel.RFChannel===-1) { result=''; }
    else {
      if(channel.MajorChannel>0&&channel.MinorChannel>0) {result=channel.MajorChannel+'.'+channel.MinorChannel;}
      else {result=channel.RFChannel.toString();}
    }
    result=scn? result:channel.Network;
    if(result.length>0){ result+=' - '; }
    result+=channel.CallSign;
    return result;
  }
  //***********************
  @action setChannels=(data: IChannelInfo): void => {
    this._lineupCacheId=data.LineupCacheId;
    this._channels=data.Channels;
    this._channels.forEach(channel => {
      channel.Days=[];
      channel.Days.push({DayNumber: 0,Shows: []});
      let key='CHA'+channel.ChannelIndex.toString();
      this._channelMap[key]=channel;
    });
    this._channelsLoaded=true;
    this._parent.schedule.loadFrame();
  }
  //***********************
  public subscribeLineupChange(callback: () => void): number {
    let cb={} as ICallback;
    cb.id=this._parent.viewState.uniqueIndexer;
    cb.process=callback;
    this._lineupChangeCallbacks.push(cb);
    return cb.id;
  }
  //***********************
  public unSubscribeLineupChange(bannerId: number): void {
    let index=this._lineupChangeCallbacks.findIndex(banner => banner.id===bannerId);
    this._lineupChangeCallbacks.splice(index);
  }
  //***********************
  private toDoOnLineupChange(): void {
    for(let i: number=0;i<this._lineupChangeCallbacks.length;i++) {
      let process=this._lineupChangeCallbacks[i].process;
      process();
    }
  }
  //***********************
  @action public setLineupIndex=(index: number): void => {
    if(this.lineupIndex===index) {return;}
    this._lineupIndex=index;
    this._channelIndex=0;
    this.forceGetChannels();
    this.toDoOnLineupChange();
  }
  //***********************
  @action public forceGetChannels(): void {
    this._channelsLoaded=false;
    this._parent.schedule.clearFetchCache();
    this._channels=[];
    this._channelMap={};
    const debug=this._parent.viewState!.debug;
    this._parent.fetcher.fetch<IChannelInfo>(this._fetchChannelsUrl,this.setChannels,debug);
  }
  //***********************
  @action public setChannelLineupEvents(channel: IBaseChannel,index: number): void {
    let key: string='CHA'+channel.ChannelIndex.toString();
    for(let i=0;i<channel.Days.length;i++) {

      if(!this._channelMap[key].Days[i+index]) {
        this._channelMap[key].Days[i+index]={} as IDay;
        this._channelMap[key].Days[i+index].Shows=[];
      }
      let showsLocal=this._channelMap[key].Days[i+index].Shows;
      let showsRemote=channel.Days[i].Shows;

      let eventIndex=showsLocal.findIndex(event => event.startTime>=showsRemote[0].startTime);
      if(eventIndex===-1) {showsLocal.push(...showsRemote);}
      else {showsLocal.splice(eventIndex,0,...showsRemote);}

      let obj: any={};
      for(let j=0,len=showsLocal.length;j<len;j++) {
        obj[showsLocal[j].startTime]=showsLocal[j];
      }

      showsLocal=[];
      for(let item in obj) {
        if(obj.hasOwnProperty(item)) {
          showsLocal.push(obj[item]);
        }
      }
      if(showsLocal.find(show => show.EventId===0)) {
        let newShowsLocal=[];
        let evt: IEvent|null=null;
        for(let idx=0;idx<showsLocal.length;idx++) {
          let item=showsLocal[idx];
          if(item.EventId===0) {
            if(!evt) {evt=item;}
            else {
              if(item.StartTime===evt.EndTime) {
                evt.EndTime=item.EndTime;
                evt.endTime=item.endTime;
                evt.DurationMinutes+=item.DurationMinutes;
              }
              else {
                newShowsLocal.push(evt);
                evt=item;
              }
            }
          }
          else {
            if(evt!==null) {
              newShowsLocal.push(evt);
              evt=null;
            }
            newShowsLocal.push(item);
          }
        }
        if(evt!==null) {newShowsLocal.push(evt);}
        showsLocal=newShowsLocal;
      }
      this._channelMap[key].Days[i+index].Shows=showsLocal;
    }
  }
  //***********************

  //***********************
  @action initLineupIndex=(lineupId: string): void => {
    for(let i=0;i<this._lineups.length;i++) {
      if(this.lineups[i].LineupId===lineupId) {
        this.setLineupIndex(i);
      }
    }
    if(this._lineupIndex===-1) {
      const err: string='invalid lineupId';
      throw new Error(err);
    }
  }
  //***********************
  @action setChannelIndex=(index: number): void => {
    if(this.channelIndex===index) {return;}
    this._channelIndex=index;
  }
  //***********************
  private _channelByIndex(index: number): IChannel|null {
    for(let i=0;i<this._channels.length;i++) {
      if(this._channels[i].ChannelIndex===index) {return this._channels[i];}
    }
    return null;
  }
  //***********************
  public getChannelName(index: number): string {
    let chan=this._channelByIndex(index);
    if(chan) {return chan.CallSign;}
    return 'CHANNEL_NOT_FOUND';
  }
  //***********************
  public getChannelNumber(index: number): string {
    let chan=this._channelByIndex(index);
    if(chan) {
      if(chan.RFChannel===-1) {return '';}
      if(chan.MajorChannel>0&&chan.MinorChannel>0) {return chan.MajorChannel+'.'+chan.MinorChannel;}
      return chan.RFChannel.toString();
    }
    return 'CHANNEL_NOT_FOUND';
  }
  //***********************
}
// ***********************************