// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../Types/interfaces';
import {ViewContext} from '../../../Types/enums';
import NavButton from './NavButton/navbutton';
// ***********************************
interface IProps extends IStoresInject { 
  enabled:boolean;
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class CloseCastButton extends React.Component<IProps,{}> {
  //***********************
  keyDownBinding:any;
  //***********************
  constructor (props:IProps) {
    super(props);
    this.keyDownBinding=this.handleKeyDown.bind(this);
  }
  //***********************
  componentDidMount() { window.addEventListener('keydown',this.keyDownBinding,false); }
  componentWillUnmount() { window.removeEventListener('keydown',this.keyDownBinding,false); }
  //***********************
  handleKeyDown=(e: React.KeyboardEvent<HTMLElement>) => {
    if(e.keyCode===27) {
      this.handleClick();
      return false;
    }
    return true;
  }
  //***********************
  handleClick=(): void => {
    const {viewState}=this.props;
    if(viewState!.screenShot) {return viewState!.setScreenShot();}
    viewState!.viewCast=false;
  }
  //***********************
  render() {

    const {masterStyles,enabled, as}=this.props;
    let baseStyle={...masterStyles!.componentBase, visibility:enabled?as!.visible:as!.hidden};
    return (
      <div style={baseStyle}>
        <NavButton title={'Close'} viewContext={ViewContext.Close} handleClick={this.handleClick} color="#993333" svgColor="#ffffff"/>
      </div>
    );
  }
  //***********************
}