// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../Types/interfaces';
import StarRating from '../VectorLabels/StarRating/starrating';
import BodyCast from './bodycast';
import BodyRating from './bodyratings';
import BodyImage from './bodyimage';
import BodyLinks from './bodylinks';
import Carousel from './Carousel/carousel';
// ***********************************
interface IProps extends IStoresInject {width: number; padding: number;}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyCore extends React.Component<IProps,{}> {
  //***********************
  @observable private compactImage: boolean;
  componentWillMount() {this.componentWillUpdate();}
  //***********************
  @action componentWillUpdate() {
    const {viewState}=this.props;
    this.compactImage=viewState!.mediaState<3;
  }
  //***********************
  render() {
    const {as,proto,details,siteSettings,masterStyles,width,padding}=this.props;

    let fields=siteSettings!.detailFields;

    let prog=details!.program;
    let chan=details!.displayChannel;
    let time=details!.displayTime;

    const timeEnable=fields.Time;
    const chanEnable=fields.Channel;
    const ceiEnabled=fields.ChainedEpisodeInfo&&prog.NumberOfParts>0&&prog.PartNumber>0;
    const yearEnabled=fields.ProductionYear&&prog.ShowType==='Movie'&&prog.Year&&prog.Year>0;
    const seasonEnabled=fields.Season&&prog.SeasonNumber>0&&prog.SeasonEpisodeNumber>0;

    const ownerStyle={
      ...masterStyles!.inlineMiddle,
      width: '100%',
      display: 'flex',
      flexDirection: this.compactImage? as!.column:as!.row
    };
    const headerDivStyle={
      fontWeight: as!.bold,fontFamily: 'Roboto Slab',
      fontSize: '1.4em',color: proto!.textColorDark
    };

    const mainSectionStyle={marginLeft: '10px',display: 'flex'};
    const sectionStyle={};

    const episodeTitleStyle={fontWeight: as!.bold,fontSize: '1.1em',...masterStyles!.inlineMiddle};
    const descriptionStyle={fontSize: '.9em'};
    const eiStyle={fontSize: '.7em', fontWeight:as!.bold, fontStyle:as!.italic };
    const seasonStyle={fontSize: '.7em',...masterStyles!.inlineMiddle,marginLeft: '5px'};
    const timeChanStyle={fontSize: '.8em',paddingBottom: '5px'};

    const title: string=(prog.Title[0]? prog.Title[0].Text:'')+(yearEnabled? ' ('+prog.Year+')':'');
    const timeChannel: string=(chanEnable? chan:'')+(chanEnable&&timeEnable? ' - ':'')+(timeEnable? time:'');
    let episodeTitle: string=prog.EpisodeTitle[0]? prog.EpisodeTitle[0].Text:'';

    let description: string=prog.Description[0]? prog.Description[0].Text:'';
    description=ceiEnabled? (description+' (Part '+prog.PartNumber+' of '+prog.NumberOfParts+')'):description;

    let eiText=`Educational or informational for ages ${prog.EIFloor} to ${prog.EICeiling}`;

    const flexDivStyle={flex: this.compactImage? '100%':'50%'};

    const imgWidth=Math.ceil(this.compactImage? width-(padding*2):(width/2)-(padding*2));

    return (
      <div>
        <div style={ownerStyle}>
          <div style={flexDivStyle}>
            <div style={headerDivStyle}>{title}<StarRating starRating={prog.Rating.Star} /></div>
            <div style={timeChanStyle}>{timeChannel}</div>
            <div style={mainSectionStyle}>
              <div style={flexDivStyle}>
                {this.compactImage? <BodyImage width={imgWidth} compact={true} />:''}
                {episodeTitle.length>0? <div style={episodeTitleStyle}>{episodeTitle}</div>:''}
                {seasonEnabled? <div style={seasonStyle}>{details!.seasonEpisode}</div>:''}
                <div style={{...descriptionStyle,padding: '5px 0 15px 0'}}>{description}</div>
                {prog.Flags.EI? <div style={{...eiStyle,padding: '5px 0 15px 0'}}>{eiText}</div>:''}
                <div style={{...descriptionStyle,fontWeight: as!.bold}}>{'Running time: '+prog.durationDisplay}</div>
              </div>
            </div>
            <div style={sectionStyle}><BodyLinks /></div>
            <div style={sectionStyle}><BodyRating /></div>
            <div style={sectionStyle}><BodyCast /></div>
          </div>
          {this.compactImage? '':<div style={flexDivStyle}><BodyImage width={imgWidth} compact={false} /></div>}
        </div>
        <br />
        <div style={sectionStyle}><Carousel width={width-(padding*2)} hide={this.compactImage} /></div>
      </div>
    );
  }
  //***********************
}
// ***********************************