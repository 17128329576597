// ***********************************
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../Types/interfaces';
import { ViewContext } from '../../../Types/enums';
import NavButton from './NavButton/navbutton';
// ***********************************
interface IProps extends IStoresInject { }
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class Navigation extends React.Component<IProps, {}> {
  //***********************
  handleClick = (viewContext:ViewContext):void => {
    const{viewState}=this.props;
    if(viewState!.viewMode!==viewContext){viewState!.setViewMode(viewContext, true);}
  }
  //***********************
  render(){
    const{ viewState, masterStyles }=this.props;
    let baseStyle={ ...masterStyles!.componentBase };
    return(
      <div style={baseStyle}>
        {viewState!.viewMode!==ViewContext.Standard?<NavButton title={'Return to Channel listings'} viewContext={ViewContext.Standard} handleClick={this.handleClick}/>:''}
        {viewState!.viewMode===ViewContext.Extended?<NavButton title={'Daily View'} viewContext={ViewContext.Daily} handleClick={this.handleClick}/>:''}
        {viewState!.viewMode===ViewContext.Daily?<NavButton title={'14 Day View'} viewContext={ViewContext.Extended} handleClick={this.handleClick}/>:''}
      </div>
    );
  }
  //***********************
}