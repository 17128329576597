// ***********************************
import * as React from 'react';
import {action,observable} from 'mobx';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../Types/interfaces';
import SearchVector from '../../SVG/search';
import SearchAdvVector from '../../SVG/searchadv';
import SearchEiVector from '../../SVG/searchei';
// ***********************************
interface IProps extends IStoresInject {}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Search extends React.Component<IProps,{}> {
  //***********************
  @observable searchHover: boolean;
  @observable advSearchHover: boolean;
  @observable eiSearchHover: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.searchHover=false;
    this.advSearchHover=false;
    this.eiSearchHover=false;
  }
  //***********************
  @action searchMouseEnter=(): void => {this.searchHover=true;};
  @action advSearchMouseEnter=(): void => {this.advSearchHover=true;};
  @action eiSearchMouseEnter=(): void => {this.eiSearchHover=true;};
  //***********************
  @action searchMouseLeave=(): void => {this.searchHover=false;};
  @action advSearchMouseLeave=(): void => {this.advSearchHover=false;};
  @action eiSearchMouseLeave=(): void => {this.eiSearchHover=false;};
  //***********************
  handleSearchChange=(e: React.FormEvent<HTMLInputElement>): void => {
    const {viewState}=this.props;
    viewState!.setSearch(e.currentTarget.value);
  }
  //***********************
  handleSearchClick=(): void => {
    const {viewState}=this.props;
    viewState!.search();
  }
  //***********************
  handleAdvSearchClick=(): void => {
    const {viewState}=this.props;
    viewState!.advSearch();
  }
  //***********************
  handleEiSearchClick=(): void => {
    const {viewState}=this.props;
    viewState!.eiSearch();
  }
  //***********************
  handleKeyDown=(e: React.KeyboardEvent<HTMLInputElement>): void => {
    if(e.charCode===13) {
      this.handleSearchClick();
    }
  }
  //***********************
  render() {
    const {viewState,siteSettings,proto,masterStyles,as}=this.props;

    const {uiRoundness}=siteSettings!;
    let searchWidth: number=90+(viewState!.mediaState*30);
    const baseStyle={...masterStyles!.componentBase};
    const labelStyle={fontSize: '.75em'};
    let searchInputDivStyle={
      ...masterStyles!.inlineMiddle,
      ...masterStyles!.noSelect,
      maxHeight: '30px',
      boxSizing: as!.borderBox,
    };
    let searchInputStyle={
      textOverflow: 'ellipsis',
      padding: '1px 5px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: proto!.colorBorder,
      backgroundColor: proto!.colorWindow,
      borderTopLeftRadius: uiRoundness.toString()+(uiRoundness>0? 'px':''),
      borderBottomLeftRadius: uiRoundness.toString()+(uiRoundness>0? 'px':''),
      width: searchWidth.toString()+'px',
      fontSize: '1.5em'
    };
    let buttonStyle={
      ...masterStyles!.btn,
      ...masterStyles!.inlineMiddle,
      ...masterStyles!.flexCenterInline,
      maxHeight: '30px',
      position: as!.relative,
      right: '1px',
      marginRight: '1px',
      borderColor: proto!.colorBorder,
      backgroundColor: proto!.colorButton,
      borderTopRightRadius: uiRoundness.toString()+(uiRoundness>0? 'px':''),
      borderBottomRightRadius: uiRoundness.toString()+(uiRoundness>0? 'px':''),
    };
    let buttonRightStyle={
      ...masterStyles!.btn,
      ...masterStyles!.inlineMiddle,
      ...masterStyles!.flexCenterInline,
      maxHeight: '30px',
      marginRight: '2px',
      borderColor: proto!.colorBorder,
      backgroundColor: proto!.colorButton,
      borderRadius: uiRoundness+'px'
    };
    let searchColor=this.searchHover? proto!.colorHover:proto!.colorControl;
    let advSearchColor=this.advSearchHover? proto!.colorHover:proto!.colorControl;
    let eiSearchColor=this.eiSearchHover? proto!.colorHover:proto!.colorControl;
    return (
      <div style={baseStyle}>
        <div style={labelStyle}>Program Search</div>
        <div style={searchInputDivStyle}>
          <input
            type="text"
            placeholder="Search for programming"
            value={viewState!.searchValue}
            onChange={this.handleSearchChange}
            onKeyPress={this.handleKeyDown}
            style={searchInputStyle}
          />
        </div>
        <div title="Begin search" onClick={this.handleSearchClick} style={buttonStyle} onMouseEnter={this.searchMouseEnter} onMouseLeave={this.searchMouseLeave}>
          <SearchVector color={searchColor} width="19" height="19" />
        </div>
        {false? <div title="Advanced search" onClick={this.handleAdvSearchClick} style={buttonRightStyle} onMouseEnter={this.advSearchMouseEnter} onMouseLeave={this.advSearchMouseLeave}>
          <SearchAdvVector color={advSearchColor} width="19" height="19" />
        </div>:''}
        {this.props.siteSettings!.enableEiSearch? <div title={siteSettings!.eiSearchText} onClick={this.handleEiSearchClick} style={buttonRightStyle} onMouseEnter={this.eiSearchMouseEnter} onMouseLeave={this.eiSearchMouseLeave}>
          <SearchEiVector color={eiSearchColor} width="19" height="19" />
        </div>:''}
      </div>
    );
  }
  //***********************
}