// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../Types/interfaces';
import Row from './Row/row';
import LoaderSmall from '../../../Controls/Loaders/LoaderSmall/loadersmall';
// ***********************************
interface IProps extends IStoresInject {}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyDaily extends React.Component<IProps,{}> {
  //***********************
  private _index: number=-1;
  //@ts-ignore: variable set by render that consumes observable to force re-render on change
  private _renderCount: number=0;
  private _scrollNeeded:boolean=false;
  //***********************
  get eventKey(): number {return ++this._index;}
  //***********************
  componentWillUpdate() {
    const {schedule}=this.props;
    schedule!.loadFrame();
  }
  //***********************
  componentDidUpdate() {
    if(!this._scrollNeeded){return;}
    let el=document.getElementById('dailyJustAired');
    if(el) {el.scrollIntoView();}
  }
  //***********************
  render() {
    const {siteSettings,viewState,lineupInfo,schedule,frame}=this.props;
    this._renderCount=schedule!.loadIndex;
    let rows: any[]=[];

    let startDate=frame!.dates[frame!.dateIndex];
    startDate.floorHour();

    let endDate=startDate.shiftDay(1);
    let durationUsed=0;
    let last:boolean;
    this._scrollNeeded=false;
    for(let i=0;lineupInfo!.channels[lineupInfo!.channelIndex]&&i<lineupInfo!.channels[lineupInfo!.channelIndex].Days[0].Shows.length;i++) {
      let channel=lineupInfo!.channels[lineupInfo!.channelIndex];
      let evt=channel.Days[0].Shows[i];

      if(evt.endTime<startDate.valueOf()) {continue;}
      if(evt.startTime>=endDate.valueOf()) {break;}

      last=true;
      if(i+1<lineupInfo!.channels[lineupInfo!.channelIndex].Days[0].Shows.length){
        let item=channel.Days[0].Shows[i+1];
        if(item.endTime<Date.now().valueOf()||item.startTime>Date.now().valueOf()) {last=false;}
        else{this._scrollNeeded=true;}
      }
      durationUsed+=evt.DurationMinutes;

      let colSet=siteSettings!.colors.getColorForEvent(evt);
      viewState!.addGenreList(colSet);

      rows.push(<Row key={'BodyDailyRow'+i} channel={channel} item={evt} index={i} last={last}/>);
    }
    if(durationUsed<1440) {rows.push(<LoaderSmall key={'LoaderSmall1'} flex={100} last={true} borderTop={true} />);}

    return (<div>{rows}</div>);
  }
  //***********************
}
// ***********************************