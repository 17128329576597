// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {observer,inject} from 'mobx-react';
import {PopupPosStr} from '../../../../../Scripts/common';
import ShareVector from '../../../../SVG/Social/share';
import {IStoresInject,IStores} from '../../../../../Types/interfaces';
import FacebookVector from '../../../../SVG/Social/facebook';
import TwitterVector from '../../../../SVG/Social/twitter';
//import InstagramVector from '../../../../SVG/Social/instagram';
// ***********************************
interface IProps extends IStoresInject {size: number; channelIndex: number; eventId: number;}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Share extends React.Component<IProps,{}> {
  //***********************
  @observable private hover: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.hover=false;
  }
  //***********************
  shareBtnUrl(target: string): string {
    //const {siteSettings,lineupInfo}=this.props;
    const {siteSettings}=this.props;
    return `https://www.titantvguide.com/?siteid=${siteSettings!.siteId}`;
    // let t: number=0;
    // switch(target) {
    //   default: case 'sbtnFace': t=1; break;
    //   case 'sbtnTwit': t=2; break;
    //   case 'sbtnLink': t=3; break;
    // }
    
    // const s=siteSettings!.siteId;
    // const l=lineupInfo!.currentLineup.LineupId;
    // const ci=this.props.channelIndex;
    // const e=this.props.eventId;
    // switch(siteSettings!.environment) {
    //  default: return `https://www.titantvguide.com/?sitrid=${s}`;
    //  case 'Live': return `${siteSettings!.hostAddress}share/${t}/${s}/${l}/${ci}/${e}`;
    // }
  }
  //***********************
  handleBtnClick=(e: React.FormEvent<HTMLElement>): boolean => {
    const lUrl=this.shareBtnUrl(e.currentTarget.id);
    let url='';
    switch(e.currentTarget.id) {
      case 'sbtnFace':
        url='https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(lUrl)+'&t='+encodeURIComponent(lUrl);
        break;
      case 'sbtnTwit':
        url='https://twitter.com/intent/tweet?url='+encodeURIComponent(lUrl);
        break;
        default: case 'sbtnLink':
        break;
    }
    window.open(url,'newwindow',PopupPosStr(640,480));
    return false;
  }
  //***********************
  @action handleMouseEnter=(e: React.MouseEvent<HTMLDivElement>): void => {this.hover=true; e.stopPropagation();};
  @action handleMouseLeave=(): void => {this.hover=false;};
  //***********************
  render() {
    const {masterStyles,proto,as}=this.props;
    const smCount=2;
    const smSize=32;

    const baseStyle={
      display: 'inline-block',
      zIndex: 3,
    };
    const itemStyle={
      display: 'inline-block',
      position: as!.absolute,
      overflow: as!.hidden,
      marginLeft: '-'+((this.hover? smSize*2:0)+6)+'px',
      width: ((smSize*smCount)+(2*smCount)+2)+'px',
      height: (this.hover? smSize+2+'px':0),
      marginTop: '18px',
      borderRadius: '4px',
      transition: 'all .2s ease .2s',
      zIndex: 999,
    };

    const shareStyle={
      ...masterStyles!.inlineMiddle,
      backgroundColor: 'white',
      border: '1px solid '+proto!.colorControl,
      marginLeft: '1px',
      height: smSize+'px',
      borderRadius: '4px',
      overflow: as!.hidden,
      zIndex: 999
    };

    return (
      <div
        style={baseStyle}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleMouseEnter}
      >
        <div style={{...masterStyles!.inlineMiddle}}>
          <ShareVector size={this.props.size} color={proto!.hoverableColor(this.hover)} />
        </div>
        <div style={itemStyle}>
          <div id="sbtnFace" style={shareStyle} onClick={this.handleBtnClick} title="Share on Facebook">
            <FacebookVector size={smSize} />
          </div>
          <div id="sbtnTwit" style={shareStyle} onClick={this.handleBtnClick} title="Share on Twitter">
            <TwitterVector size={smSize} />
          </div>
          {/* <div id="sbtnInsta" style={shareStyle} onClick={this.handleBtnClick} title="Share on Instagram">
            <InstagramVector size={smSize} />
          </div> */}
        </div>
      </div>
    );
  }
  //***********************
}
// ***********************************
