import {action,observable,computed} from 'mobx';
//import ReactGA from 'react-ga';
import TimeLine from '../Scripts/timeline';
import {MediaMatch} from '../Scripts/common';
import {ViewContext} from '../Types/enums';
import {IStores,IGenreColorSet,ILoadParams,ICallback, IProgramImage} from '../Types/interfaces';
//***********************
// tslint:disable-next-line
declare global {interface Document {documentMode?: any;}}
//***********************
export default class ViewState {
  //***********************
  private _parent: IStores;
  private _resizeTimer: number=-1;
  private _index: number=-1;
  private _mediaDefines: string[];
  private _debug: boolean;
  private _bannerCallbacks: ICallback[];
  private _pageScrollPosition: number;
  @observable private _border: boolean;
  @observable private _padBorder: boolean;
  private _stageList: IGenreColorSet[];
  private _bannersInitialized: boolean;
  @observable private _mediaWidth: number;
  @observable private _initialized: boolean;
  @observable private _viewMode: ViewContext;
  @observable private _viewDetails: boolean;
  @observable private _viewCast: boolean;
  @observable private _castId: string;
  @observable private _backMode: ViewContext[];
  @observable private _genreList: IGenreColorSet[];
  @observable private _mediaState: number;
  @observable private _colWidthChannel: number;
  @observable private _colWidthDate: number;
  @observable private _clickedEventId: number;
  @observable private _screenShot: IProgramImage|undefined;

  private _bannerHeight: number;
  private _bannerWidth: number;

  @observable displayTime: string[];
  @observable searchValue: string;
  @observable filterHd: boolean;
  @observable filterNew: boolean;
  @observable showSettings: boolean;

  public get uniqueIndexer(): number {return ++this._index;}
  public get debug(): boolean {return this.getDebug();}
  public get border(): boolean {return this._border;}
  public get padBorder(): boolean {return this._padBorder;}
  public get isMobile(): boolean {return this.getMobile();}

  @computed public get fourKDisplay(): boolean {return this._mediaWidth>1920;}
  @computed public get rawWidth(): number {return this._mediaWidth;}

  @computed public get initialized(): boolean {return this._initialized;}
  @computed public get viewMode(): ViewContext {return this._viewMode;}
  @computed public get viewDetails(): boolean {return this._viewDetails;}
  @computed public get viewCast(): boolean {return this._viewCast;}
  @computed public get castId(): string {return this._castId;}
  
  @computed public get boundHours(): boolean {return this._viewMode===ViewContext.Extended;}
  @computed public get isMicrosoftBrowser(): boolean {return this.getIsMicrosoftBrowser();}
  @computed public get genreList(): IGenreColorSet[] {return this._genreList;}
  @computed public get mediaState(): number {return this._mediaState;}
  @computed public get colWidthChannel(): number {return this._colWidthChannel;}
  @computed public get colWidthDate(): number {return this._colWidthDate;}
  @computed public get clickedEventId(): number {return this._clickedEventId;}
  @computed public get screenShot(): IProgramImage|undefined {return this._screenShot;}

  public set viewDetails(value:boolean) { this.setViewDetails(value);}
  public set viewCast(value: boolean) {this.setViewCast(value);}
  public set clickedEventId(value:number) { this._clickedEventId = value;}
  
  public get bannerHeight(): number {return this._bannerHeight;}
  public get bannerWidth(): number {return this._bannerWidth;}

  public get googleTrackingId(): string {return 'UA-2878319-24';}
  //public get googleTrackingId(): string {return 'UA-152046249-1'; }
  //***********************
  constructor(params: ILoadParams) {
    this._construct(params);
  }
  //***********************
  @action private _construct(params: ILoadParams) {
    this.filterHd=false;
    this.filterNew=false;
    this.showSettings=false;
    this._initialized=false;
    this._debug=params.debug;
    this._border=params.border;
    this._padBorder=false;
    this.setViewMode(ViewContext.Loading,false);
    this.searchValue='';
    this.displayTime=['',''];
    this.computeCurrentDateTime();
    this._mediaState=0;
    this._mediaDefines=['0','320','480','768','1024','1280','1440','1920','3840'];
    this.updateMedia();
    this._genreList=[];
    this._stageList=[];
    this._backMode=[];
    this._bannerCallbacks=[];
    this._bannersInitialized=false;
    this._screenShot=undefined;
  }
  //***********************
  @action public initialize(parent: IStores): void {
    this._parent=parent;
    this.processMediaResize();
    this._initialized=true;
    window.setInterval(() => this.computeCurrentDateTime(),5000);
    this.computeCurrentDateTime();
    this.resetViewMode();
  }
  //***********************
  private computeCurrentDateTime(): void {
    let currentTimeVal: number;
    if(this.initialized) {currentTimeVal=new TimeLine().valueOf()-this._parent.siteSettings.timeOffset;}
    else {currentTimeVal=new TimeLine().valueOf();}
    let cdt=new TimeLine(currentTimeVal);
    let timeStr: string[]=['',''];
    timeStr[0]=cdt.toShortFullDateString();
    timeStr[1]=cdt.toLongFullDateString();
    if(timeStr!==this.displayTime) {this.setDisplayTime(timeStr);}
  }
  //***********************
  @action public setScreenShot(screenShot:IProgramImage|undefined=undefined):void{
    this._screenShot=screenShot;
    if(screenShot){this.invalidateBanners();}
  }
  //***********************
  private resetViewMode() {
    this.setViewMode(this._parent.siteSettings.startingView,false);
  }
  //***********************
  @action public setViewMode(mode: ViewContext|undefined,loadFrame: boolean) {
    if(mode===undefined) {return;}
    this._viewMode=mode;
    if(this.initialized&&loadFrame) {
      this._parent.schedule.loadFrame();
    }
  }
  //***********************
  @action public setViewDetails(value: boolean) { 
    if(!this._parent.siteSettings.enableScrollingBody||this.viewMode!==ViewContext.Standard){
      if(!value){
        this._viewDetails=value;
        window.setTimeout(() => {window.scrollTo({top:this._pageScrollPosition});},100);
      }
      else{
        this._pageScrollPosition=this.getPageScrollPosition();
        this._viewDetails=value; 
      }
    }
    else{ this._viewDetails=value;}
  }
  //***********************
  @action public setViewCast(value: boolean) { 
    if(!this._parent.siteSettings.enableScrollingBody||this.viewMode!==ViewContext.Standard){
      if(!value){
        this._viewCast=value;
        window.setTimeout(() => {window.scrollTo({top:this._pageScrollPosition});},100);
      }
      else{
        this._pageScrollPosition=this.getPageScrollPosition();
        this._viewCast=value;
      }
    }
    else{ this._viewCast=value; }
  }
  //***********************
  getPageScrollPosition():number{
    var result = window.pageYOffset;
    return result;
  }
  //***********************
  @action public setSearch(value: string): void {
    if(value.length>100) {value=value.substr(0,100);}
    this.searchValue=value;
  }
  //***********************
  @action public togglePadBorder=(): void => {
    if(!this._parent.siteSettings.canDebug) {
      this._padBorder=false;
      return;
    }
    this._padBorder=!this._padBorder;
  }
  //***********************
  @action private _setDetails(eventId: number,programId: number,channelIndex: number,channel: string,time: string) {
    this.viewDetails=true;
    this._parent.details.getEvent(eventId,programId,channelIndex,channel,time);
  }
  //***********************
  public setDetails(eventId: number,programId: number,channelIndex: number,channel: string,time: string) {
    if(!this._parent.siteSettings.enableDetails) {return;}
    if(eventId<0) {
      const err: string='Invalid detailId';
      throw new Error(err);
    }
    else {
      this._setDetails(eventId,programId,channelIndex,channel,time);
      //ReactGA.event({ category: 'User Action', action: 'View Details Page' });
    }
  }
  @action private _setCast(id: string) {
    this._viewCast=true;
    this._castId=id;
  }
  //***********************
  public setCast(castId: string) {
    if(!castId) {
      const err: string='Invalid detailId';
      throw new Error(err);
    }
    else {
      this._setCast(castId);
      //ReactGA.event({ category: 'User Action', action: 'View Cast Page' });
    }
  }
  //***********************
  @action public search(): void {
    if(this.searchValue.length>0) {
      if(this._viewMode!==ViewContext.SearchResults) {this.navigateWithBack(ViewContext.SearchResults);}
      this._parent.search.runSearch();
    }
  }
  //***********************
  private navigateWithBack(newContext: ViewContext): void {
    this._backMode.push(this._viewMode);
    this._viewMode=newContext;
  }
  //***********************
  @action public viewBack(): void {
    if(this._backMode.length>0) {
      let newContext=this._backMode.pop();
      this.setViewMode(newContext,true);
    }
  }
  //***********************
  @action public advSearch() {
    //TODO #Advanced Search
  }
  //***********************
  @action public eiSearch() {
    //TODO #EI Search
  }
  //***********************
  private resetTimer(): void {
    window.clearTimeout(this._resizeTimer);
    this._resizeTimer=window.setTimeout(() => {this.processMediaResize();},800);
  }
  private getMobile(): boolean {
    if(!this._parent.proto.initialized||!this._parent.proto.protoTypeMode) {return false;}
    if(this.rawWidth>=568) {return false;}
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
  }
  //***********************
  @action public updateMedia(): void {
    this.resetTimer();
    let mediaState=0;
    while(MediaMatch(this._mediaDefines[mediaState])) {mediaState++;}
    if(mediaState===this._mediaState) {return;}
    this._mediaState=mediaState;
    this.updateConditionalUiValues();
  }
  //***********************
  @action private processMediaResize(): void {
    this._mediaWidth=window.innerWidth;
    let width=this.getBannerWidth(this._mediaWidth);
    let height=this.getBannerHeight(this._mediaWidth);
    if(this._bannerHeight!==height||this._bannerWidth!==width) {
      this._bannerHeight=height;
      this._bannerWidth=width;
      if(this._bannersInitialized) {this.invalidateBanners();}
      else {this._bannersInitialized=true;}
    }
  }
  //***********************
  private getBannerHeight(mediaWidth: number): number {
    let _default: number=50;
    if(mediaWidth>=728) {return 90;}
    //if(mediaWidth>=468) {return 60;}
    if(mediaWidth>=320) {return 50;}
    // if(mediaWidth>=234) {return 60;}
    // if (mediaWidth >= 120) { return 60; }
    // if (mediaWidth >= 88) { return 31; }
    return _default;
  }
  //***********************
  private getBannerWidth(mediaWidth: number): number {
    let _default: number=320;
    if(mediaWidth>=728) {return 728;}
    //if(mediaWidth>=468) {return 468;}
    if(mediaWidth>=320) {return 320;}
    // if(mediaWidth>=234) {return 234;}
    // if (mediaWidth >= 120) { return 120; }
    // if (mediaWidth >= 88) { return 88; }
    return _default;
  }
  //***********************
  @action public setHD(val: boolean) {
    this.filterHd=val;
  }
  //***********************
  @action public setNew(val: boolean) {
    //ReactGA.event({category: 'User Action',action: `${ val?'Enabled':'Disabled' } New Filter` });
    this.filterNew=val;
  }
  //***********************
  @action public setShowSettings(val: boolean): void {
    this.showSettings=val;
  }
  //***********************
  @action public finalizeGenreList(): void {
    this._genreList=[];
    this._stageList.forEach(element => {
      this._genreList.push(element);
    });
    this._stageList=[];
  }
  //***********************
  @action addGenreList(event: IGenreColorSet|null): void {
    if(!event) {return;}
    if(this._stageList.indexOf(event)>-1) {return;}
    this._stageList.push(event);
  }
  //***********************
  @action private setDisplayTime(timeStr: string[]) {
    this.displayTime=timeStr;
  }
  //***********************
  private updateConditionalUiValues(): void {
    switch(this._mediaState) {
      case 0: this.setState1(); break;
      case 1: this.setState1(); break;
      case 2: this.setState2(); break;
      case 3: this.setState3(); break;
      case 4: this.setState4(); break;
      case 5: this.setState5(); break;
      case 6: this.setState6(); break;
      case 7: this.setState7(); break;
      case 8: this.setState8(); break;
      default: this.setState5(); break;
    }
  }
  //***********************
  // public getIsMicrosoftBrowser(excludeEdge: boolean=false): boolean {
  //   var ua=window.navigator.userAgent;
  //   var msie=ua.indexOf('MSIE ');
  //   if(msie>0) { return true; }
  //   var trident=ua.indexOf('Trident/');
  //   if(trident>0) { return true; }
  //   var edge=ua.indexOf('Edge/');
  //   if(edge>0) { return excludeEdge?false:true; }
  //   // other browser
  //   return false;
  // }
  //***********************
  private getIsMicrosoftBrowser(): boolean {
    if(document.documentMode||/Edge/.test(navigator.userAgent)) {return true;}
    return false;
  }
  //***********************
  @action private setState1(): void {
    this._colWidthChannel=30;
    this._colWidthDate=75;
  }
  //***********************
  @action private setState2(): void {
    this._colWidthChannel=30;
    this._colWidthDate=75;
  }
  //***********************
  @action private setState3(): void {
    this._colWidthChannel=35;
    this._colWidthDate=85;
  }
  //***********************
  @action private setState4(): void {
    this._colWidthChannel=35;
    this._colWidthDate=85;
  }
  //***********************
  @action private setState5(): void {
    this._colWidthChannel=45;
    this._colWidthDate=95;
  }
  //***********************
  @action private setState6(): void {
    this._colWidthChannel=45;
    this._colWidthDate=95;
  }
  //***********************
  @action private setState7(): void {
    this._colWidthChannel=55;
    this._colWidthDate=105;
  }
  //***********************
  @action private setState8(): void {
    this._colWidthChannel=55;
    this._colWidthDate=105;
  }
  //***********************
  @action public invalidateBanners(): void {
    if(!this.initialized){return;}
    for(let i=0;i<this._bannerCallbacks.length;i++) {
      let banner=this._bannerCallbacks[i];
      banner.process();
    }
  }
  //***********************
  public safeMailTo(url:string):void{
    window.removeEventListener('beforeunload',this.onUnload);
    window.location.href=url;
    window.setTimeout(this.mountBeforeUnload.bind(this), 2000);
  }
  //***********************
  // This is the code that detects back button clicking
  public mountBeforeUnload(){
    //window.addEventListener('beforeunload',this.onUnload);
  }
  //***********************
  // This is the code that detects back button clicking
  public unMountBeforeUnload(){
    //window.removeEventListener('beforeunload',this.onUnload);
  }
  //***********************
  private onUnload = (e: BeforeUnloadEvent): string => {
    const confirmationMessage = '';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }
  //***********************
  private getDebug(): boolean {
    if(!this.initialized) {return true;}
    return this._parent.siteSettings.canDebug&&this._debug;
  }
  //***********************
  public subscribeBanner(callback: () => void): number {
    let cb={} as ICallback;
    cb.id=this.uniqueIndexer;
    cb.process=callback;
    this._bannerCallbacks.push(cb);
    return cb.id;
  }
  //***********************
  public unSubscribeBanner(bannerId: number): void {
    let index=this._bannerCallbacks.findIndex(banner => banner.id===bannerId);
    this._bannerCallbacks.splice(index);
  }
  //***********************
}
// **********************************