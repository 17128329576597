// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject,IEvent} from '../../../../Types/interfaces';
import MoreLeftVector from '../../../SVG/moreleft';
// ***********************************
interface IProps extends IStoresInject {
  item: IEvent|null;
  running: boolean;
  orientStyle: {};
}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class OrientLeft extends React.Component<IProps,{}> {
  //***********************
  @observable hover: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.hover=false;
  }
  //***********************
  @action handleMouseEnter=(): void => {this.hover=true;};
  @action handleMouseLeave=(): void => {this.hover=false;};
  //***********************
  navigateToStart=(e: React.FormEvent<HTMLElement>): boolean => {
    const {frame,item}=this.props;
    e.stopPropagation();
    frame!.setStart(item!.startTime.valueOf());
    return false;
  }
  //***********************
  render() {
    const {proto,running,item}=this.props;

    let leftColor=proto!.hoverableColor(this.hover,true);
    return (
      <div style={this.props.orientStyle}>
        {item!.EventId!==0&&running? <div style={{padding: '5px',overflow: 'hidden',minWidth: '15px'}} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.navigateToStart}>
          <MoreLeftVector color={leftColor} width={8.5} />
        </div>:''}
      </div>
    );
  }
  //***********************
}