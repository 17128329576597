// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../Types/interfaces';
import BodyDetails from './Body/body';
import {DetectIE} from '../../../Scripts/common';
import ScreenShot from './ScreenShot/screenshot';
// ***********************************
interface IProps extends IStoresInject {}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class ViewDetails extends React.Component<IProps,{}> {
  //***********************
  componentDidMount(){ 
    document.body.style.overflow='';     
  }
  //***********************
  componentWillUnmount(){ 
    let {siteSettings,viewState}=this.props;
    document.body.style.overflow=DetectIE()>10&&siteSettings!.enableScrollingBody?'hidden':'';
    viewState!.invalidateBanners();
  }
  //***********************
  render() {
    const {as,viewState,masterStyles,siteSettings,proto}=this.props;
    let baseStyle={
      backgroundColor:'#CCCCCC',
      borderTop:siteSettings!.enableScrollingBody?'':`solid 1px ${proto!.colorBorder}`
    };
    let detailStyle={
      ...masterStyles!.flexCenter,
      flexDirection:as!.column
    };
    return (
      <div style={baseStyle}>
        <div style={detailStyle}>
          {viewState!.screenShot?<ScreenShot />:''}
          <BodyDetails width={viewState!.rawWidth} />
        </div>
      </div>
    );
  }
  //***********************
}
// ***********************************
