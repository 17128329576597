// ***********************************
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IStores, IStoresInject } from '../../../../../Types/interfaces';
// ***********************************
interface IProps extends IStoresInject{}
// ***********************************
@inject((stores: IStores): IStoresInject => ({ ...stores }))
@observer export default class DailyBar extends React.Component<IProps,{}> {
  //***********************
  render(){
    const{lineupInfo, proto, masterStyles}=this.props;
    const style= { 
      ...masterStyles!.flexCenter,
      ...masterStyles!.noSelect,
      minHeight:'24px',
      padding:'0 5px 0 5px',
      borderColor:proto!.colorBorder
    };
    return(
      <div style={style}>{lineupInfo!.channels[lineupInfo!.channelIndex]?lineupInfo!.channels[lineupInfo!.channelIndex].Description:''}</div>
    );
  }
  //***********************
}
// ***********************************