import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../../Types/interfaces';
import CCVector from '../../../../../SVG/Ratings/cc';
// ***********************************
interface IProps extends IStoresInject {active:boolean; vectorSize: number;}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class CcIndicator extends React.Component<IProps,{}> {
  //***********************
  active: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    const {siteSettings,active}=this.props;
    this.active=siteSettings!.detailFields.CCIndicator&&active;
  }
  //***********************
  render() {
    if(!this.active) { return (''); }
    const {vectorSize}=this.props;

    const ratingDivStyle={
      display: 'inline-block', 
      borderRadius:'3px', 
      marginRight:'4px', 
      height: vectorSize+'px', 
      width: vectorSize+'px'
    };

    return (
      <div style={ratingDivStyle}><CCVector size={vectorSize} /></div>
    );
  }
  //***********************
}
// ***********************************