//***********************
//import {string} from 'prop-types';
import {IStores,IConfigSettings,IFetchPost} from '../Types/interfaces';
//***********************
export default class Fetcher {
  // ***********************************
  private _initialized: boolean;
  //@ts-ignore
  private _parent: IStores;
  // ***********************************
  public get initialized(): boolean {return this._initialized;}
  // ***********************************
  constructor() {
    this._initialized=false;
  }
  // ***********************************
  public fetch<T>(fetchUrl: string,callback: (n: T) => void,debug: boolean,config?: (n: IConfigSettings) => void): void {
    this.get<T>(fetchUrl).then(
      (data) => {
        if(data.Status==='SUCCESS') {
          const flags: string[]=data.FlagListCSV.split(',');
          flags.forEach(function(value) {
            let noPrivacy='false';
            if(value==='NO-PRIVACY') noPrivacy='true';
            localStorage.setItem('NO-PRIVACY',noPrivacy);
          });
          if(config) {config(data.Environment);}
          callback(data.Json);
        }
        else {
          this.promiseFail('failed retrieving data with message: '+data.Message,debug);
          callback(data.Json);
        }
      }, // succeed
      (data: string) => this.promiseFail(data,debug)); // fail
  }
  // ***********************************
  public fetchForId<T>(id: number,fetchUrl: string,callback: (n: T,id: number) => void,debug: boolean,config?: (n: IConfigSettings) => void): void {
    this.get<T>(fetchUrl).then(
      (data) => {
        if(data.Status==='SUCCESS') {
          if(config) {config(data.Environment);}
          callback(data.Json,id);
        }
        else {
          this.promiseFail('failed retrieving data with message: '+data.Message,debug);
          callback(data.Json,id);
        }
      }, // succeed
      (data: string) => this.promiseFail(data,debug)); // fail
  }
  // ***********************************
  public fetchConfirm<T>(fetchUrl: string,callback: (n: T,p: boolean) => void,debug: boolean): void {
    this.get<T>(fetchUrl).then(
      (data) => {
        if(data.Status==='SUCCESS') {callback(data.Json,true);}
        else {callback(data.Json,false);}
      }, // succeed
      (data: string) => this.promiseFail(data,debug)); // fail
  }
  // ***********************************
  public fetchPost<T>(post: IFetchPost,callback: (n: T,p: boolean) => void,debug: boolean=false): void {
    this.post<T>(post).then(
      (data) => {
        if(data.Status==='SUCCESS') {callback(data.Json,true);}
        else {callback(data.Json,false);}
      }, // succeed
      (data: string) => this.promiseFail(data,debug)); // fail
  }
  // ***********************************
  private get<T>(uri: string): Promise<IDataWrapper<T>> {
    const headers=new Headers();
    headers.append('accept','application/json');
    return fetch(uri,{method: 'get',headers: headers})
      .then((res) => {
        if(res.status!==200) {return Promise.reject(res.statusText);}
        return res.json();
      })
      .catch((ex: Error) => {return Promise.reject(ex.message);}
      );
  }
  // ***********************************
  private post<T>(post: IFetchPost): Promise<IDataWrapper<T>> {
    const headers=new Headers();
    headers.append('accept','application/json');
    headers.append('content-type',post.contentType);
    return fetch(post.url,{method: 'post',headers: headers,body: post.body})
      .then((res) => {
        if(res.status!==200) {return Promise.reject(res.statusText);}
        return res.json();
      })
      .catch((ex: Error) => {return Promise.reject(ex.message);}
      );
  }
  // ***********************************
  public promiseFail(data: string,debug: boolean) {
    if(!this._parent||!this._parent.viewState||!this._parent.viewState.initialized) {return;}
    //this._parent.viewState!.addNotice(JSON.stringify(data));
  }
  // ***********************************
  public initialize(parent: IStores): void {
    this._parent=parent;
    this._initialized=true;
  }
  // ***********************************
}
// ***********************************
interface IDataWrapper<T> {
  Status: string;
  Message: string;
  Json: T;
  Environment: IConfigSettings;
  FlagListCSV: string;
}
// ***********************************
