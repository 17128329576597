import * as React from 'react';
// ***********************************
interface IProps {
  height: number;
}
// ***********************************
export default class ADVector extends React.Component<IProps,{}> {
  //***********************
  render() {
    const {height}=this.props;
    let width: number=height*(658.459/235.744);
    let style={height: height,width: width};
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={height} width={width} viewBox="0 0 658.459 471.488">
        <path d="M-40.887 291.488h97.728l-.576-151.424zm586.272-56.576q0-49.728-28.864-80T432.81 124.64h-30.848v221.728h29.728q52 0 82.848-32.576t30.848-78.848zm-328-215.424l.576 432q0 8-5.44 13.728t-13.44 5.728H75.657q-8 0-13.44-5.728-5.44-5.728-5.44-13.728v-35.424h-166.272l-31.424 46.272q-5.728 8.576-16 8.576h-152.576q-12 0-17.44-10.272-5.44-10.272 2.016-20L-7.19 8.064q5.152-8 15.424-8h189.728q8 0 13.728 5.728t5.728 13.728zm472.576 215.424q0 110.272-71.712 173.152-71.712 62.88-185.44 62.848H278.537q-8 0-13.728-5.728t-5.728-13.728v-432q0-8 5.728-13.728T278.537 0H431.69q114.272 0 186.272 62.272 72 62.272 72 172.576zm89.152.576q0 6.272-.288 16.576t-4.576 40.864q-4.288 30.56-12.288 58.272t-25.44 61.728q-17.44 34.016-42.016 58.56h-29.152q21.728-25.728 38.016-59.712 16.288-33.984 23.712-64 7.424-30.016 12-56t5.152-41.44l.576-15.424q0-4.576-.288-12.864t-4.288-34.272q-4-25.984-11.424-52.288-7.424-26.304-23.424-63.712T667.657.64h24.576q23.424 26.848 41.152 61.152t26.016 63.712q8.288 29.408 13.152 54.848 4.864 25.44 6.016 40.288zm105.152 0q0 6.272-.288 16.576t-4.576 40.864q-4.288 30.56-12.288 58.272t-25.728 61.728q-17.728 34.016-42.272 58.56h-29.152q21.728-25.728 38.016-59.712 16.288-33.984 23.712-64 7.424-30.016 12-56t5.152-41.44l.576-15.424q0-4.576-.288-12.864t-4.288-34.272q-4-25.984-11.136-52.288-7.136-26.304-23.136-63.712T772.841.64h24.576q23.424 26.848 41.152 61.152t26.016 63.712q8.288 29.408 13.152 54.848 4.864 25.44 6.016 40.288zm103.424 0q0 6.272-.288 16.576t-4.576 40.864q-4.288 30.56-12.288 58.272t-25.44 61.728q-17.44 34.016-42.016 58.56H873.93q21.728-25.728 37.728-59.712t23.424-64q7.424-30.016 12-56t5.152-41.44l.576-15.424q0-4.576-.288-12.864t-4.288-34.272q-4-25.984-11.136-52.288-7.136-26.304-23.136-63.712T876.233.64h24.576q23.424 26.848 41.152 61.152t26.016 63.712q8.288 29.408 13.152 54.848 4.864 25.44 5.44 40.288z"/>
      </svg>
    );
  }
  //***********************
}
// ***********************************