// ***********************************
import * as React from 'react';
// ***********************************
interface IProps { }
// ***********************************
interface IState {
  tosCheck:boolean,
  cookiesCheck: boolean,
  tosLinkHover: boolean,
  privacyLinkHover: boolean,
  privacyLinkHover2: boolean,
  buttonHover:boolean,
  buttonHover2:boolean
}
// ***********************************
export default class Notices extends React.Component<IProps,IState> {
  //***********************
  private c_tosVersion='1.00';
  private c_tosVersionLabel='TERMS_OF_SERVICE_VERSION';
  private c_cookiesVersion='1.00';
  private c_cookiesVersionLabel='COOKIES_VERSION';
  private c_tos='TERMS_OF_SERVICE_CHECK';
  private c_cookies='COOKIE_CHECK';
  //***********************
  constructor(props: IProps) {
    super(props);
    this.state={ 
      tosCheck:false,
      cookiesCheck: false,
      tosLinkHover:false,
      privacyLinkHover:false,
      privacyLinkHover2:false,
      buttonHover: false,
      buttonHover2: false
     } 
  }
  //***********************
  componentDidMount(){
    if(this.c_tosVersion!==localStorage.getItem(this.c_tosVersionLabel)){ 
      localStorage.setItem(this.c_tos, 'true'); 
      localStorage.setItem(this.c_tosVersionLabel, this.c_tosVersion); 
    }
    if(this.c_cookiesVersion!==localStorage.getItem(this.c_cookiesVersionLabel)){ 
      localStorage.setItem(this.c_cookies, 'true'); 
      localStorage.setItem(this.c_cookiesVersionLabel, this.c_cookiesVersion); 
    }
    this.setState({
      tosCheck:localStorage.getItem(this.c_tos)==='true' && localStorage.getItem('NO-PRIVACY')!=='true',
      cookiesCheck: localStorage.getItem(this.c_cookies)==='true'
    });
  }
  //***********************
  tosLinkEnter=(): void => {this.setState({ tosLinkHover: true});}
  tosLinkLeave=(): void => {this.setState({ tosLinkHover: false});}
  //***********************
  privacyLinkEnter=(): void => {this.setState({ privacyLinkHover: true});}
  privacyLinkLeave=(): void => {this.setState({ privacyLinkHover: false});}
  //***********************
  privacyLinkEnter2=(): void => {this.setState({ privacyLinkHover2: true});}
  privacyLinkLeave2=(): void => {this.setState({ privacyLinkHover2: false});}
  //***********************
  buttonEnter=(): void => {this.setState({ buttonHover: true});}
  buttonLeave=(): void => {this.setState({ buttonHover: false});}
  //***********************
  buttonEnter2=(): void => {this.setState({ buttonHover2: true});}
  buttonLeave2=(): void => {this.setState({ buttonHover2: false});}
  //***********************
  private setTos = () => {
    localStorage.setItem(this.c_tos, 'false');
    this.setState({ tosCheck:false });
  }
  //***********************
  private setCookies = () => {
    localStorage.setItem(this.c_cookies, 'false');
    this.setState({ cookiesCheck: false });
  }
  //***********************
  render() {
    if(!this.state.tosCheck&&!this.state.cookiesCheck){ return ''; }
    const color: string = 'blue';

    const baseStyle={
      borderTop: '1px solid #dddddd',
      backgroundColor: 'transparent'
    }

    const noticeStyle={
      textAlign:'center' as 'center',
      margin: '10px 0',
      padding: '15px',
      fontSize:'16px',
      color:'black',
      backgroundColor: '#dddddd'
    };

    let tosLinkStyle={
      color: color,
      textDecoration: this.state.tosLinkHover? 'underline':'none',
      fontWeight: 200 
    };

    let privacyLinkStyle={
      color: color,
      textDecoration: this.state.privacyLinkHover? 'underline':'none',
      fontWeight: 200 
    };

    let privacyLinkStyle2={
      color: color,
      textDecoration: this.state.privacyLinkHover2? 'underline':'none',
      fontWeight: 200 
    };

    let buttonStyle={
      cursor: 'pointer',
      borderRadius: '3px',
      marginTop:'10px', 
      padding: '5px',
      display: 'inline-block',
      color: 'white', 
      fontSize:'12px',
      fontWeight: 200 
    };
    let buttonStyle1={ ...buttonStyle, backgroundColor:this.state.buttonHover? 'blue':'green' }
    let buttonStyle2={ ...buttonStyle, backgroundColor:this.state.buttonHover2? 'blue':'green' }

    return (
      <div style={baseStyle}>
        { this.state.tosCheck?
          <div style={noticeStyle} onClick={ this.setTos }>Our <a style={tosLinkStyle} onMouseEnter={this.tosLinkEnter} onMouseLeave={this.tosLinkLeave} href="https://www.titantv.com/terms.aspx">Terms of Service</a>
            &nbsp;and <a style={privacyLinkStyle} onMouseEnter={this.privacyLinkEnter} onMouseLeave={this.privacyLinkLeave} href="https://www.titantv.com/privacy.aspx">Privacy Policy</a>
            &nbsp;have changed. Please take a moment to review them.
            <br /><div onMouseEnter={this.buttonEnter} onMouseLeave={this.buttonLeave} style={ buttonStyle1 }onClick={ this.setTos }>OK</div>
          </div>:'' }
        { this.state.cookiesCheck?
          <div style={noticeStyle} onClick={ this.setCookies }>
            This site uses cookies. View our <a style={privacyLinkStyle2} onMouseEnter={this.privacyLinkEnter2} onMouseLeave={this.privacyLinkLeave2} href="https://www.titantv.com/privacy.aspx">Privacy Policy</a> to 
            learn more. Your use of this site constitutes an acceptance of that policy.
            <br /><div onMouseEnter={this.buttonEnter2} onMouseLeave={this.buttonLeave2} style={ buttonStyle2 } onClick={ this.setCookies }>OK</div>
          </div>:'' }
      </div>
    );
    //***********************
  }
  // ***********************************
}
