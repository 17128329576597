// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../../Types/interfaces';
import TvRatingVector from '../../../../../SVG/Ratings/tvrating';
// ***********************************
interface IProps extends IStoresInject {tvRating: string; vectorSize: number;}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class TvRating extends React.Component<IProps,{}> {
  //***********************
  active: boolean;
  //***********************
  constructor(props: IProps) {
    super(props);
    const {siteSettings,tvRating}=this.props;
    this.active=siteSettings!.detailFields.TVRatings&&tvRating.length>0;
  }
  //***********************
  render() {
    if(!this.active) {return ('');}
    const {tvRating,vectorSize}=this.props;

    const ratingDivStyle={
      display: 'inline-block', 
      borderRadius:'3px', 
      marginRight:'4px', 
      height: vectorSize+'px', 
      width: vectorSize+'px'
    };

    return (
      <div style={ratingDivStyle}><TvRatingVector size={vectorSize} rating={tvRating} /></div>
    );
  }
  //***********************
}
// ***********************************