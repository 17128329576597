import * as React from 'react';
// ***********************************
interface IProps {
  fill: string;
  size: number;
}
// ***********************************
export default class ImageNotFoundVector extends React.Component<IProps,{}> {
  //***********************
  render() {
    let {size}=this.props;
    let style={height: size,width: size};
    return (
      <svg fill={this.props.fill} xmlns="http://www.w3.org/2000/svg" style={style} height={size} width={size} viewBox="0 0 150 150">
        <path fill="#f2f2f2" d="M15 15h120v120H15z" />
        <path fill="#fff" d="M27.315475 26.398811h95.369049v97.202385H27.315475z" />
        <text x="109.8875" y="112.2751" fill="gray" strokeWidth=".26458332" fontFamily="Courier New" fontSize="16.93333244" fontWeight="700" letterSpacing="0" textAnchor="middle" transform="translate(-34.017857 -55.095238)" wordSpacing="0">
          <tspan x="109.8875" y="112.2751" >Image</tspan>
        </text>
        <text x="108.80554" y="136.32948" fill="gray" strokeWidth=".26458332" fontFamily="Courier New" fontSize="16.93333244" fontWeight="700" letterSpacing="0" textAnchor="middle" transform="translate(-34.017857 -55.095238)" wordSpacing="0">
          <tspan x="108.80554" y="136.32948" >Not</tspan>
        </text>
        <text x="108.13168" y="157.67189" fill="gray" strokeWidth=".26458332" fontFamily="Courier New" fontSize="16.93333244" fontWeight="700" letterSpacing="0" textAnchor="middle" transform="translate(-34.017857 -55.095238)" wordSpacing="0">
          <tspan x="108.13168" y="157.67189" >Found</tspan>
        </text>
      </svg>
    );
  }
  //***********************
}

