// ***********************************
import * as React from 'react';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../../../Types/interfaces';
import TvRating from '../VectorLabels/TvRating/tvrating';
import MovieRating from '../VectorLabels/MovieRating/movierating';
import HdIndicator from '../VectorLabels/HdIndicator/hdindicator';
import StereoIndicator from '../VectorLabels/StereoIndicator/stereoindicator';
import CcIndicator from '../VectorLabels/CcIndicator/ccindicator';
import EiIndicator from '../VectorLabels/EiIndicator/eiindicator';
import ADVector from '../../../../SVG/Ratings/ad';

// ***********************************
interface IProps extends IStoresInject {}
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class BodyRating extends React.Component<IProps,{}> {
  //***********************
  private getVectorSize(): number {
    switch(this.props.viewState!.mediaState) {
      case 1:case 2:case 3: return 22;
      case 4: case 5: return 24;
      case 6: case 7: return 26;
      default: return 28;
    }
  }
  //***********************
  render() {
    const {as,proto,details,siteSettings}=this.props;

    let prog=details!.program;
    console.log(JSON.stringify(prog.Rating,null,4));
    console.log(JSON.stringify(prog.Flags,null,4));
    console.log(JSON.stringify(prog.AudioStreams,null,4));

    let {TV,Movie}=prog.Rating;
    let {HD,CC,AD}=prog.Flags;
    
    let stereoActive=prog.AudioStreams.length>0&&prog.AudioStreams[0].SupportsStereo&&siteSettings!.detailFields.StereoIndicator;
    let ei=prog.Flags.EI;
    
    let renderThis=false;

    if(TV.length>0||Movie.length>0||HD||CC||AD||stereoActive){renderThis=true;}

    if(!renderThis){return ('');}

    const ownerStyle={fontSize:'1.1em', margin: '10px 0'};
    const headerDivStyle={fontWeight: as!.bold,fontFamily: 'Roboto Slab',
      fontSize: '1.1em',color: proto!.textColorDark,marginBottom: '2px'
    };
    const sectionStyle={marginLeft:'10px'};

    let vectorSize=this.getVectorSize();
    let adDetailStyle ={ display: 'inline-block', padding : '2px', position:as!.relative, top: '-1px', marginRight: '3px' };
    return (
      <div style={ownerStyle}>
          <div style={headerDivStyle}>Ratings & Accessibility</div>
          <div style={sectionStyle}>
            <TvRating tvRating={prog.Rating.TV} vectorSize={vectorSize} />
            <MovieRating movieRating={prog.Rating.Movie} vectorSize={vectorSize} />
            <HdIndicator active={prog.Flags.HD} vectorSize={vectorSize} />
            <CcIndicator active={prog.Flags.CC} vectorSize={vectorSize} />
            <StereoIndicator active={stereoActive} vectorSize={vectorSize} />
            {ei?<EiIndicator active={ei} vectorSize={vectorSize} min={prog.EIFloor} max={prog.EICeiling}/>:''}
            {AD?<div title="Audio descriptions for the visually impaired" style={adDetailStyle} ><ADVector height={26} /></div>:''}
          </div>
      </div>
    );
  }
  //***********************
}
// ***********************************

