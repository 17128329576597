// ***********************************
import * as React from 'react';
// ***********************************
interface IProps {
  color?: string;
  size: number;
}
// ***********************************
export default class ShareVector extends React.Component<IProps, {}> {
  //***********************
  render() {
    let style={
      height: this.props.size,
      width: this.props.size,
    };
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={style} height={this.props.size} width={this.props.size} viewBox="0 0 512 512">
        <g fill={this.props.color?this.props.color:'#000000'}>
          <path d="M508.271 194.568l-149.333-128c-3.146-2.708-7.583-3.323-11.396-1.594-3.781 1.74-6.208 5.521-6.208 9.688v74.813c-119.188 3.313-176.688 62.312-204.021 112.031-23.771 43.219-29.104 86.01-30.302 103.573-.229 2.688-.344 5.427-.344 8.219v.34l.01.031v.206s0 .021.01.031v.134c-.01.031.021.042.01.042v.093c0 .021.01.042.01.042v.103l.01.021v.082c.01.01.01.01.01.021v.062l.01.031v.072c.01.01.01.021.01.021v.031c.667 5.26 5.156 9.323 10.583 9.323 5.885 0 10.656-4.76 10.667-10.646v-.01c0-.542.01-1.865.104-3.885 4.656-88.698 168.521-108.823 213.229-112.667v73.875c0 4.167 2.427 7.948 6.208 9.688 3.802 1.729 8.229 1.125 11.396-1.594l149.333-128c2.365-2.021 3.729-4.979 3.729-8.094.004-3.104-1.361-6.062-3.725-8.083z"/>
          <path d="M441.792 304.027c-3.802-1.75-8.229-1.135-11.406 1.594l-21.333 18.292c-2.354 2.021-3.719 4.979-3.719 8.094v73.323H42.667V191.995h119.208c2.583 0 5.073-.938 7.01-2.635 8.479-7.396 17.646-14.25 27.24-20.375 4-2.542 5.833-7.427 4.51-11.979-1.323-4.552-5.5-7.677-10.24-7.677H42.667C19.135 149.329 0 168.464 0 191.995v213.333c0 23.531 19.135 42.667 42.667 42.667h362.667c23.531 0 42.667-19.135 42.667-42.667v-91.615c-.001-4.166-2.428-7.947-6.209-9.686z"/>
        </g>
      </svg>
    );
  }
  //***********************
}
// ***********************************