// ***********************************
import * as React from 'react';
import {inject,observer} from 'mobx-react';
import {IStores,IStoresInject} from '../../../Types/interfaces';
import {swipedetect} from '../../../Scripts/swipe';
import HourSegment from './HourSegment/hoursegment';
import {DstOffset} from '../../../Types/enums';
// ***********************************
interface IProps extends IStoresInject {listener: boolean;}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class HourNav extends React.Component<IProps,{}> {
  //***********************
  componentDidMount() {
    if(this.props.listener) {
      var el=document.getElementById('defaultView');
      const {frame}=this.props;
      swipedetect(el,(swipedir: string) => {
        switch(swipedir) {
          case 'left':
            frame!.incrementHours();
            break;
          case 'right':
            frame!.decrementHours();
            break;
          default: break;
        }
      });
    }
  }
  //***********************
  render() {
    const {frame}=this.props;

    let style=this.props.masterStyles;
    let windowSize=frame!.windowSize;
    let segments: {}[]=[];
    let flexBasis: string=(1/windowSize*2)*100+'%';
    let dstOffSet: DstOffset=DstOffset.None;
    let dstOffSetVal=0;
    for(let i: number=0;(i)<windowSize;i++) {
      dstOffSet=frame!.modHoursOffset(i+dstOffSetVal,dstOffSet);
      switch(dstOffSet) {
        default: case DstOffset.None: break;
        case DstOffset.Backward:
          dstOffSetVal=-1;
          break;
        case DstOffset.Forward:
          dstOffSetVal=1;
          break;
      }
      segments.push(<HourSegment key={'HourSegment1'+i} index={i+dstOffSetVal} flex={flexBasis} offsetVal={dstOffSetVal} offsetHalf={false} />);
      segments.push(<HourSegment key={'HourSegment2'+i} index={i+dstOffSetVal} flex={flexBasis} offsetVal={dstOffSetVal} offsetHalf={true} />);
    }

    let baseStyle={...style!.noSelect,...style!.flexCenter,flex: '1 0 auto',height: '24px'};

    return (
      <div style={baseStyle}>
        {segments}
      </div>
    );
  }
  //***********************
}
// ***********************************
